import { useLocation } from 'react-router-dom';
import RappelsCalendar from './RappelsCalendar';
import RappelsGlobalPosition from './RappelsGlobalPosition';
import RappelsHome from './RappelsHome';

export default function Rappels() {
  const location = useLocation();

  switch (location.pathname) {
  case '/home/rappel':
    return <RappelsHome />;
  case '/global-position/rappel':
    return <RappelsGlobalPosition />;
  case '/calendar/rappel':
    return <RappelsCalendar />;
  default:
    return null;
  }
}
