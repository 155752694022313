import { IIcon } from './Iicon';

export default function IconMessage({ color, height, width }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height={height} width={width} viewBox="0 0 20 21">
      <path d="M2 16.4595H4V20.5405L9.101 16.4595H14C15.103 16.4595 16 15.5625 16 14.4595V6.4595C16 5.3565 15.103 4.4595 14 4.4595H2C0.897 4.4595 0 5.3565 0 6.4595V14.4595C0 15.5625 0.897 16.4595 2 16.4595Z" fill={color} />
      <path d="M18 0.459503H6C4.897 0.459503 4 1.3565 4 2.4595H16C17.103 2.4595 18 3.3565 18 4.4595V12.4595C19.103 12.4595 20 11.5625 20 10.4595V2.4595C20 1.3565 19.103 0.459503 18 0.459503Z" fill={color} />
    </svg>
  );
}
