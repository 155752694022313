import { gql } from 'graphql-request';

const GET_MY_TEAM_GROUPED_RESULTS_GQL = gql`
query MyQuery($type: String, $grouping: String, $subgrouping: String, $year: Int, $nif: String, $firstName: String, $lastName: String, $dgt: String, $dt: String, $od: String, $category: String, $userId: ID) {
  myTeamPlansGroupedResults(type: $type, grouping: $grouping, subgrouping: $subgrouping, year: $year, nif: $nif, firstName: $firstName, lastName: $lastName, dgt: $dgt, dt: $dt, od: $od, category: $category, userId: $userId) {
    category
    dgt
    dt
    od
    nif
    firstName
    lastName
    updateDate
    territoryNameDgt
    territoryNameDt
    territoryNameOd
    valort1
    valort2
    valort3
    valort4
    valorAnual
    valorTotal
  }
}
`;

export default GET_MY_TEAM_GROUPED_RESULTS_GQL;
