import { useQueryClient } from 'react-query';

export default function useYear() {
  const queryClient = useQueryClient();
  const globalYear = queryClient.getQueryState(['year']);
  if (localStorage.getItem('year') === 'NaN') {
    localStorage.removeItem('year');
  }
  const year: number = (globalYear !== undefined)
    ? Number(globalYear.data)
    : (Number(localStorage.getItem('year')) || new Date().getFullYear());
  return { year };
}
