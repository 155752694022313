import { gql } from 'graphql-request';

const GET_MULTI_DATA = gql`
  query MyQuery($resultId: ID!, $myTeam: Boolean = false) {
    getMultiData(resultId: $resultId, myTeam: $myTeam) {
      groupedResult
    }
  }
`;

export default GET_MULTI_DATA;
