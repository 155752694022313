import { useState, useMemo } from 'react';
import ReactPlayer from 'react-player';
import {
  ButtonBack, ButtonNext, CarouselProvider, Slide, Slider, DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  HomeNewsExpandClose,
  ModalVideo, ModalVideoContainer, ReactPlayerContainer, ReactPlayerControl,
  StyledHomeWinners, StyledSlideList, SliderButtonWrapper,
} from './StyledHome';
import IconPlayVideo from '../../atoms/Icon/IconPlay';
import useYear from '../../../hooks/useYear';
import useVideo from '../../../hooks/useVideo';

export default function HomeVideos() {
  const { year } = useYear();
  const { data } = useVideo();

  const dataFiltered = useMemo(() => {
    if (data === undefined) return [];
    return data.filter((item: any) => item.year === year)[0]?.info || [];
  }, [data, year]);

  const [showModal, setShowModal] = useState({
    url: '',
    open: false,
  });

  return (
    <>
      <StyledHomeWinners>
        <StyledSlideList>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={dataFiltered?.length}
            visibleSlides={1}
            currentSlide={dataFiltered?.length > 0 ? Math.floor(dataFiltered.length / 2) : 0}
          >
            <Slider>
              {dataFiltered.map((item: any, index :any) => (
                <Slide index={index}>
                  <ReactPlayerContainer key={item.id}>
                    <ReactPlayer
                      url={item.urlVimeo}
                      width="100%"
                      height="100%"
                    />
                    <ReactPlayerControl
                      onClick={() => {
                        setShowModal({
                          url: item.urlVimeo,
                          open: true,
                        });
                      }}
                    >
                      {item.thumbnailLarge !== null && (
                        <img src={item.thumbnailLarge} alt={item.title} />
                      )}
                      <IconPlayVideo />
                      <span>
                        {item.title}
                      </span>
                    </ReactPlayerControl>
                  </ReactPlayerContainer>
                </Slide>
              ))}
            </Slider>
            <SliderButtonWrapper>
              <ButtonBack>
                ← ANTERIOR
              </ButtonBack>
              <DotGroup />
              <ButtonNext>
                SIGUIENTE →
              </ButtonNext>
            </SliderButtonWrapper>
          </CarouselProvider>
        </StyledSlideList>
      </StyledHomeWinners>

      {showModal?.url !== undefined && (
        <ModalVideo show={showModal?.open}>
          <ModalVideoContainer>
            <HomeNewsExpandClose onClick={() => {
              setShowModal({
                url: '',
                open: false,
              });
            }}
            />
            <ReactPlayer
              url={showModal?.url}
              width="100%"
              height="100%"
              playing={showModal?.open}
              config={{
                file: {
                  attributes: {
                    preload: true,
                  },
                },
              }}
            />
          </ModalVideoContainer>
        </ModalVideo>
      )}
    </>
  );
}
