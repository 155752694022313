import Styled, { css } from 'styled-components';
import Theme from '../../../styles/Theme';

export const Cell = Styled.td`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 .5rem;
  text-align: left;
  font-size: 13px;
  line-height: 1.2;

  b,
  span:not(.tooltip) {
    display: block;
    overflow-wrap: break-word;
    white-space: initial;
  }

  b {
    width: 100px;
    flex-shrink: 0;
  }

  span:not(.tooltip) {
    flex-grow: 1;
  }

  .tooltip {
    display: none;
  }

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: bold;
  }

  @media (min-width: 768px) {
    display: table-cell;
    width: auto;
    height: 52px;
    padding: 0 12px;
    border-bottom: none;
    text-align: left;
    font-size: 14px;
    white-space: nowrap;

    b,
    span,
    div,
    a {
      white-space: nowrap;
      z-index: 2;
      position: relative;
    }

    b {
      display: none;
    }

    span:not(.tooltip) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }

    .align-right {
      text-align: right;
      border: 1px solid transparent;
      padding-right: 1rem;
    }

    .align-center {
      text-align: center;
      border: 1px solid transparent;
    }

    &:nth-child(1) {
      border-radius: 8px 0 0 8px;
      text-align: left;

      .tooltip-{
        position: absolute;
        z-index: 2;
      }

      .tooltip-{
        background: rgba(0, 0, 0, 0.72);
        color: ${Theme.colorWhite};
        cursor: text;
        font-weight: 600;
        font-size: 11px;
        min-width: 80px;
        max-width: 250px;
        padding: 0.42rem 1rem;
        border-radius: 0.32rem;
        white-space: pre-line;
        line-break: anywhere;
        top: 30%;
        left: 10%;
        transform: translateY(-100%);
        box-shadow: ${Theme.shadow};

        &::after {
          content: '';
          width: 0px;
          height: 0px;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid rgba(0, 0, 0, 0.72);
          position: absolute;
          bottom: -8px;
          left: 20%;
          transform: translateY(-20%);
          z-index: 2;
        }
      }

      &:hover {
        .tooltip-{
          display: block;
        }
      }
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: calc(100% + 10px);
      height: 52px;
      background-color: ${Theme.colorSecondary2};
    }

    &:last-child {
      &::before {
        width: 100%;
      }
    }
  }

  &.first-cell {
    position: sticky;
    left: 0;
    z-index: 4;
  }

  &.index-subtable {
    position: sticky;
    left: 0;
    z-index: 3;
  }
`;

export const HeaderCell = Styled.th<{theme?: string}>`
  background-color: ${Theme.colorSecondary};
  border-radius: 8px;
  color: ${Theme.colorWhite};
  height: 52px;
  font-size: 16px;
  font-weight: 400;
  padding: 0 12px;
  text-align: left;
  position: relative;
  white-space: nowrap;

  &:nth-child(1) {
    background-color: ${(props) => props.theme};
  }

  &.first-cell {
    position: sticky;
    left: 0;
    z-index: 5;
  }

  &.index-subtable {
    position: sticky;
    left: 0;
    z-index: 3;
  }
`;

export const HeaderRow = Styled.tr`
  display: none;
  width: 100%;

  @media (min-width: 768px) {
    display: table-row;
  }
`;

export const Row = Styled.tr`
  background-color: ${Theme.colorSecondary2};
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  width: 100%;
  margin: 0 0 1rem;
  padding: 0.75rem;
  border: 1px solid ${Theme.colorSecondary3};

  &:hover {
    background: #fafafa;

    td {
      &::before {
        background-color: #fafafa;
      }
    }
  }

  @media (min-width: 768px) {
    display: table-row;

    td {
      &:before {
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
`;

export const CellInner = Styled.td`
  > * {
    padding: 0 1rem;
    line-height: 1.12;
    margin: 1em auto 0 auto;
    width: 94%;

    th {
      background-color: ${Theme.fontColorSecondary};
    }

    @media screen and (min-width: 768px) {
      overflow-x: auto;

      th {
        font-size: 14px;
        width: inherit;
        height: 48px;
      }
    }
  }
`;

const StyledArrows = css`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${Theme.colorWhite};
  position: absolute;
  top: 50%;
  right: 1rem;
  padding: 0;
  margin: 0;
  line-height: 1;
  transform: translateY(-50%);
`;

export const StyledSortButton = Styled.button`
  ${StyledArrows};
`;

export const TableHead = Styled.thead`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 5;

  @media (min-width: 768px) {
    display: table-row-group;
  }

  &.index-subtable {
    z-index: 4 !important;
  }
`;

export const TableBody = Styled.tbody`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  @media (min-width: 768px) {
    display: table-row-group;
  }
`;

export const StyledTableContainer = Styled.div<{numColumns?: number}>`
  margin-bottom: 3rem;
  width: ${(props) => (props.numColumns && props.numColumns <= 8 ? '80%' : '100%')};
  margin-left: auto;
  margin-right: auto;
  .auto-width {
    max-width: 980px;
    @media (min-width: 1080px) {
      max-width: 1280px;
    }
  }
  .w-full {
    max-width: 100%;
  }
  .table-container {
    max-width: 90vw;
    width: auto;
    margin-right: 0;
  }
  .subtable-container {
    max-width: 90vw;
    margin-right: 0;
  }
  .subtable-width {
    width: auto;
  }
  .fix-height {
    max-height: 480px;
    margin-right: 0;
  }
`;

export const StyledWrapTable = Styled.div`
  max-height: 800px;
  overflow-x: auto;
  margin: 0 auto;
`;

export const StyledTable = Styled.table`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0.35rem;

  @media (min-width: 768px) {
    display: table;
  }
`;

const StyledCtaCell = css<{underline?: boolean}>`
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${Theme.colorPrimary};
  cursor: pointer;
  display: flex;
  font-size: 13px;
  text-decoration: ${(props) => (props.underline !== false ? 'undeline' : 'none')};
  gap: 0.5rem;
  padding: 0;
`;

export const ViewMore = Styled.button<{underline?: boolean}>`
  ${StyledCtaCell}
`;

export const DownloadLink = Styled.a<{underline?: boolean}>`
  ${StyledCtaCell}
`;

const StyledTableLoading = css`
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  margin: 0 auto;
  height: 28px;
  width: 100%;
`;

export const TableMainSkeleton = Styled.div`
  margin: 0 0 2rem;

  @media (min-width: 768px) {
    margin: 0 0 10rem;
  }
`;

export const StyledTableWrapHeadSkeleton = Styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 0 0 0.5rem;
`;

export const TableWrapSkeleton = Styled.div`
  background: ${Theme.colorSecondary2};
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
  margin: 0 0 0.5rem;
`;

export const TableRowHeadSkeleton = Styled.div<{theme: string}>`
  ${StyledTableLoading};
  height: 52px;
  display: none;

  @media (min-width: 768px) {
    display: block;
    background: ${Theme.colorSecondary};
    &:first-child {
      background: ${(props) => props.theme};
    }
  }
`;

export const TableRowSkeleton = Styled.div`
  ${StyledTableLoading};
`;
