import { gql } from 'graphql-request';

const GET_POLICY_DETAIL_GQL = gql`
query getPolicyDetail($userId: ID!, $planCode: String!, $description: String) {
  policyDetail(planCode: $planCode, userId: $userId, description: $description) {
    id
    createdAt
    importId
    keyCode
    planId
    policy
    categoryCode
    insuredCode
    impPrimaNp
    updatedAt
    userId
    description
  }
}`;

export default GET_POLICY_DETAIL_GQL;
