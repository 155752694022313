import { gql } from 'graphql-request';

const GET_MY_TEAM_RESULTS_GQL = gql`
query MyQuery($type: String, $startDate: String, $endDate: String, $grouping: String, $subgrouping: String, $timeframe: String, $year: Int, $first: Int, $nif: String, $firstName: String, $lastName: String, $dgt: String, $dt: String, $od: String, $category: String) {
  myTeamPlansResults(type: $type, startDate: $startDate, endDate: $endDate, grouping: $grouping, subgrouping: $subgrouping, timeframe: $timeframe, year: $year, first: $first, nif: $nif, firstName: $firstName, lastName: $lastName, dgt: $dgt, dt: $dt, od: $od, category: $category) {
    nodes {
      amount
      category
      currency
      dgt
      dt
      od
      nif
      fulfills
      extraFields
      participants
      points
      ranking
      rankingGroup
      status
      firstName
      lastName
      updateDate
      updatedAt
      wins
      territoryNameDgt
      territoryNameDt
      territoryNameOd
      dataDate
    }
  }
}
`;

export default GET_MY_TEAM_RESULTS_GQL;
