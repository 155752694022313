import {
  useState, useMemo, memo, useCallback,
} from 'react';
import Theme from '../../../styles/Theme';
import {
  HomeNewsWidget,
  HomeNewsCta,
  HomeNewsExpand,
  HomeNewsExpandTitle,
  HomeNewsExpandClose,
  HomeNewsCtaIndicator,
  HomeNewsExpandContainer,
  HomeNewsExpandGroup,
  HomeNewsExpandItem,
  HomeNewsCtaContainer,
} from './StyledHome';
import IconIndicator from '../../atoms/Icon/IconIndicator';

function HomeNews(props: any) {
  const { data, year } = props;

  const [isCtaOpen, setCtaOpen] = useState<boolean>(true);
  const [isExpandOpen, setExpandOpen] = useState<boolean>(false);

  const dataFiltered = useMemo(() => {
    if (data === undefined) return [];
    return data.filter((item: any) => item.year === year)[0]?.info || [];
  }, [data, year]);

  const openExpand = useCallback(() => {
    setCtaOpen(false);
    setExpandOpen(true);
  }, [isCtaOpen, isExpandOpen]);

  const closeExpand = useCallback(() => {
    setCtaOpen(true);
    setExpandOpen(false);
  }, [isCtaOpen, isExpandOpen]);

  return (
    <HomeNewsWidget>
      <HomeNewsCta isOpen={isCtaOpen} onClick={openExpand}>
        <HomeNewsCtaContainer>
          <IconIndicator color={Theme.colorWhite} width="22px" height="18" />
          <HomeNewsCtaIndicator>
            {dataFiltered?.length}
          </HomeNewsCtaIndicator>
        </HomeNewsCtaContainer>
      </HomeNewsCta>
      <HomeNewsExpand isOpen={isExpandOpen}>
        <HomeNewsExpandTitle>
          <IconIndicator color={Theme.colorWhite} width="22px" height="18" />
          <span>Últimas alertas</span>
          <HomeNewsExpandClose type="button" onClick={closeExpand} />
        </HomeNewsExpandTitle>
        <HomeNewsExpandContainer>
          <HomeNewsExpandGroup>
            {dataFiltered?.map((item: any) => (
              <HomeNewsExpandItem key={Math.random()}>
                <span>
                  <div dangerouslySetInnerHTML={{ __html: item.body }} />
                </span>
              </HomeNewsExpandItem>
            ))}
          </HomeNewsExpandGroup>
        </HomeNewsExpandContainer>
      </HomeNewsExpand>
    </HomeNewsWidget>
  );
}

export default memo(HomeNews);
