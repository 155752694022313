import { gql } from 'graphql-request';

const GET_COMPARATIVE_RESULTS_BY_CODE_GQL = gql`
query MyQuery($code: String!, $nif: String, $firstName: String, $lastName: String, $dgt: String, $dt: String, $od: String, $category: String, $group: String, $status: String, $numberElements: Int, $numberPage: Int, $order: String, $orderType: String) {
  comparativeResultsByCode(code: $code, nif: $nif, firstName: $firstName, lastName: $lastName, dgt: $dgt, dt: $dt, od: $od, category: $category, group: $group, status: $status, numberElements: $numberElements, numberPage: $numberPage, order: $order, orderType: $orderType) {
    translations
    planLevels
    resultsCount
    myOwnResult {
      id
      amount
      category
      currency
      dgt
      dt
      od
      nif
      fulfills
      extraFields
      participants
      points
      ranking
      rankingGroup
      status
      updateDate
      updatedAt
      dataDate
      wins
      territoryNameDgt
      territoryNameDt
      territoryNameOd
      firstName
      lastName
      userId
    }
    otherResults {
      nodes {
        id
        amount
        category
        currency
        dgt
        dt
        od
        nif
        fulfills
        extraFields
        participants
        points
        dataDate
        ranking
        rankingGroup
        status
        updateDate
        updatedAt
        wins
        territoryNameDgt
        territoryNameDt
        territoryNameOd
        firstName
        lastName
        puestoId
      }
    }
  }
}
`;

export default GET_COMPARATIVE_RESULTS_BY_CODE_GQL;
