export default function useParseToCsvColumns(arrayColumns: any) {
  let columns: any = [];
  if (!arrayColumns) {
    return { columns };
  }

  columns = arrayColumns.map((item: any) => ({
    label: item.Header,
    key: item.accessor,
  }));

  return { columns };
}
