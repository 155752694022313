import { gql } from 'graphql-request';

const GET_CALCULATE_OBJECTIVES = gql`
  query MyQuery($userId: ID!, $type: String!, $grouping: String!, $subgrouping: String, $endDate: String!, $year: Int) {
    calculateObjective(userId: $userId, type: $type, grouping: $grouping, subgrouping: $subgrouping, endDate: $endDate, year: $year) {
      groupedResult
    }
  }
`;

export default GET_CALCULATE_OBJECTIVES;
