import { gql } from 'graphql-request';

const GET_MY_TEAM_PLANS_BY_PARAMS_GQL = gql`
query MyQuery($type: String, $startDate: String, $endDate: String, $grouping: String, $subgrouping: String, $timeframe: String, $year: Int, $first: Int, $nif: String, $firstName: String, $lastName: String, $category: String, $dgt: String, $dt: String, $od: String, $group: String, $search: String, $status: String, $numberElements: Int, $numberPage: Int, $order: String, $orderType: String) {
  myTeamPlans(type: $type, startDate: $startDate, endDate: $endDate, grouping: $grouping, subgrouping: $subgrouping, timeframe: $timeframe, year: $year, first: $first, nif: $nif, firstName: $firstName, lastName: $lastName, category: $category, dgt: $dgt, dt: $dt, od: $od, group: $group, search: $search, status: $status, numberElements: $numberElements, numberPage: $numberPage, order: $order, orderType: $orderType) {
    nodes {
      id
      planName
      planCode
      planDescription
      amount
      planStartDate
      planEndDate
      planUpdatedAt
      planGrouping
      planType
      planTimeframe
      planStatus
      planStatusName
      fulfillsNumber
      participantsNumber
      winsNumber
      planBasesUrl
      resultsAmount
      planResultsCount
      planDataDate
      planSubscribed
      globalDgt
    }
  }
}
`;

export default GET_MY_TEAM_PLANS_BY_PARAMS_GQL;
