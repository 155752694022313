import { useEffect, useState, Fragment } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import Container, { FullContainer } from '../../atoms/Container/Container';
import { Table, LinkTable } from '../../atoms/Table/Table';
import Typography from '../../atoms/Typography/Typography';
import GET_MULTI_DATA from '../../../graphql/queries/getMultiDataGql';
import useNumberFormat from '../../../hooks/useNumberFormat';
import { Card } from '../../atoms/Card/Card';
import GET_CURRENT_USER_DATA_INFO_GQL from '../../../graphql/queries/getCurrentUserDataInfoGql';
import GET_EXCLUDE_POLICY_GQL from '../../../graphql/queries/getExcludePolicyGql';
import GET_POLICY_DETAIL_GQL from '../../../graphql/queries/getPolicyDetailGql';
import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';
import IconArrow from '../../atoms/Icon/IconArrow';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import useControlledColumns from '../../../hooks/useControlledColumns';
import useDate from '../../../hooks/useDate';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import useCsvNumber from '../../../hooks/useCsvNumber';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export default function ConventionDetail() {
  const {
    id,
    denomination,
  } = useParams();

  const graphQLClient = createGraphQLClient();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const grouping = params.get('grouping');
  const code = params.get('code');
  const participantId = params.get('participantId');
  const [showExcludedPolice, setShowExcludedPolice] = useState(false);

  const navigate = useNavigate();

  const {
    data: conventionMultiData,
    isFetching: isFetchingConventionMultiData,
  } = useQuery(
    ['ConventionMultidata', { resultId: id, myTeam: true }],
    async () => {
      if (!graphQLClient) {
        throw new Error('No se puede realizar la consulta, falta el token de autenticación.');
      }
      return graphQLClient.request(GET_MULTI_DATA, { resultId: id, myTeam: true });
    },
  );

  const { data: currentUser } = useQuery(
    ['CurrentUser'],
    async () => (graphQLClient && graphQLClient.request(GET_CURRENT_USER_DATA_INFO_GQL)),
  );

  const {
    data: excludePolicyData,
    refetch: refetchData,
  } = useQuery(
    ['ExcludePolicy', { planCode: code, userId: Number(participantId) }],
    async () => (graphQLClient && graphQLClient.request(
      GET_EXCLUDE_POLICY_GQL,
      { planCode: code, userId: Number(participantId) },
    )),
  );

  const {
    data: policyDetail,
    refetch: refetchPolicyData,
  } = useQuery(
    ['PolicyDetail', { planCode: code, userId: Number(participantId) }],
    async () => (graphQLClient && graphQLClient.request(
      GET_POLICY_DETAIL_GQL,
      { planCode: code, userId: Number(participantId) },
    )),
  );

  const headers = [
    { Header: 'Póliza', accessor: 'policy' },
    { Header: 'Línea de negocio', accessor: 'desLinNegocio' },
    { Header: 'Importe', accessor: 'amount' },
    { Header: 'Razón', accessor: 'reason' },
  ];

  const subHeaders = [
    { Header: 'Descripción', accessor: 'description' },
    { Header: 'Póliza', accessor: 'policy' },
    { Header: 'Clave', accessor: 'keyCode' },
    { Header: 'Asegurado', accessor: 'insuredCode' },
    { Header: 'Imp. Prima NP', accessor: 'impPrimaNp' },
    { Header: 'Categoría', accessor: 'categoryCode' },
  ];

  useEffect(() => {
    refetchData();
    refetchPolicyData();
  }, [currentUser?.me?.id]);

  const processData = excludePolicyData?.excludedPolicy?.map((e:any) => ({
    ...e,
    desLinNegocio: e?.desLinNegocio,
    firstName: currentUser?.me.firstName,
    lastName: currentUser?.me.lastName,
  }));

  const processDataWithExtras = processData?.map((d: any) => ({ ...d }));

  processDataWithExtras?.forEach((item: any, index: number) => {
    Object.entries(item)?.forEach(([key, value]: any) => {
      processDataWithExtras[index][key] = useCsvNumber(value);
    });
  });

  const conventionMultiDataOrdered = conventionMultiData
    ?.getMultiData
    ?.groupedResult?.sort((a: any, b: any) => a.level - b.level);

  const renderRowSubComponent = (results: any) => {
    const currentDescription = results?.row?.original?.groupedItem;
    if (currentDescription && policyDetail) {
      const currentData = policyDetail?.policyDetail
        .filter((column: any) => column.description === currentDescription);
      const processHeadersFirst = {};
      const processHeadersObjects:any = {};
      const processHeadersList:any = [];
      subHeaders.forEach((e: any) => {
        processHeadersList[e.accessor] = e.Header;
      });
      Object.assign(processHeadersObjects, processHeadersList);
      const newProcessHeader = { ...processHeadersFirst, ...processHeadersObjects };
      const processHedersCSV: any[] = [];
      const processHedersCSVKeys: any[] = ['IMPORTE'];
      Object.entries(newProcessHeader).forEach(([key, value]: any) => {
        if (!processHedersCSVKeys.includes(key)) {
          processHedersCSVKeys.push(key);
          processHedersCSV.push({ label: useMappingColumnNames(value, 'campaign'), key: useControlledColumns(key) });
        }
      });
      currentData.forEach((item: any, index: number) => {
        Object.entries(item).forEach(([key, value]: any) => {
          if (key === 'FECHA ACT') {
            currentData[index][key] = useDate(value);
          }
        });
      });
      return (
        <Container>
          <CardDataExportXLSX
            headers={processHedersCSV}
            data={currentData}
            name="convention_detail_sub_table"
          />
          <Table
            columns={subHeaders}
            data={currentData}
            className="fix-height"
          />
        </Container>
      );
    }
    return null;
  };

  const arrayColumnsConvention = conventionMultiDataOrdered?.map((dataLevel: any) => {
    let addDetail = false;
    const description = dataLevel?.plan_description;

    if (dataLevel?.level !== 1) {
      if (dataLevel?.has_grouping === true) {
        const arrayGroupedCols = Object.keys(dataLevel?.result)?.map((res: any) => {
          if (dataLevel?.result[res].length > 0) {
            if (Object.keys(dataLevel?.result[res][0]).map((level :any) => level.toLowerCase()).includes('tipo conv')) {
              addDetail = true;
            }
            return Object.keys(dataLevel?.result[res][0]);
          }
          return undefined;
        });

        const formattedCols = arrayGroupedCols.flat()?.map((column: any) => ({
          Header: column.replaceAll('(porcentaje)', '').replaceAll('(decimales)', ''),
          accessor: column.split('.').join(''),
        }));
        const uniqueColumns = formattedCols.reduce((acc: any, current: any) => {
          const x = acc.find((item: any) => item.accessor.split('.').join('') === current.accessor.split('.').join(''));

          if (!x) {
            return acc.concat([current]);
          }
          return acc;
        }, []);

        let totalColsFiltered: any = [];
        const colsFilterDetail = uniqueColumns.filter((column: any) => column.Header !== 'groupedItem');

        const cartaConventions = [
          'CONVENCION MAPFRE 2022 PROMOTOR DIGITAL',
          'PROMOTOR PREVISIÓN SOCIAL EMPRESARIAL Y SALUD RE',
          'PROMOTOR VIDA Y SALUD',
          'DIRECTOR COMERCIAL PARTICULARES',
          'PROMOTOR EMPRESA Y SUBDIRECTOR COMERCIAL EMPRESAS',
          'PROMOTOR CLIENTES',
          'PROMOTOR CANALES',
          'PROMOTOR AGROPECUARIA',
          'DIRECTOR COMERCIAL EMPRESAS',
          'GESTOR SAC',
          'PROMOTOR PARTICULARES',
        ];

        if (['AGENTES', 'DELEGADOS'].includes(description)) {
          if (dataLevel?.level === 4) {
            totalColsFiltered = [{ Header: 'Agrupaciones', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else if (dataLevel?.level === 5) {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else if (dataLevel?.level === 6) {
            totalColsFiltered = [{ Header: 'Incentivos Millas Extra', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          }
        } else if (['APS', 'GESTORÍAS DELEGADAS'].includes(description)) {
          if (dataLevel?.level === 4) {
            totalColsFiltered = [{ Header: 'Incentivos Millas Extras', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          }
        } else if (description === 'MEDIADOR RE VIDA') {
          if (dataLevel?.level === 2) {
            totalColsFiltered = [{ Header: 'Resumen general', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else if (dataLevel?.level === 3) {
            totalColsFiltered = [{ Header: 'Agrupaciones', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else if (dataLevel?.level === 4) {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else if (dataLevel?.level === 5) {
            totalColsFiltered = [{ Header: 'Incentivos Millas Extras', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          }
        } else if (description === 'MEDIADOR RE SALUD') {
          totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
        } else if (description === 'AFIS') {
          if (dataLevel?.level === 4) {
            totalColsFiltered = [{ Header: 'Agrupaciones', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          }
        } else if (cartaConventions.includes(description)) {
          if (dataLevel?.level === 2) {
            totalColsFiltered = [{ Header: 'Resumen General', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else if (dataLevel?.level === 3) {
            totalColsFiltered = [{ Header: 'Agrupaciones', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (dataLevel?.level === 4) {
            totalColsFiltered = [{ Header: 'Agrupaciones', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else if (dataLevel?.level === 6) {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else if (dataLevel?.level === 8) {
            totalColsFiltered = [{ Header: 'Incentivos Millas Extra', accessor: 'groupedItem' }, ...colsFilterDetail];
          } else {
            totalColsFiltered = [{ Header: 'Líneas de negocio', accessor: 'groupedItem' }, ...colsFilterDetail];
          }
        }

        if (addDetail) {
          totalColsFiltered = [...totalColsFiltered, {
            Header: 'Ver detalle póliza',
            accessor: 'detail',
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: ({ row }:any) => (
              <label {...row.getToggleRowExpandedProps()}>
                <LinkTable title="Detalle" onClick={() => renderRowSubComponent(row)}>
                  {row.isExpanded
                    ? <IconArrow color={`${Theme.colorPrimary}`} rotate />
                    : <IconArrow color={`${Theme.colorPrimary}`} /> }
                </LinkTable>
              </label>
            ),
          }];
        }

        return totalColsFiltered;
      }
      return Object?.keys(dataLevel?.result)?.map((column: any) => ({
        Header: column.replaceAll('(porcentaje)', '').replaceAll('(decimales)', ''),
        accessor: column.split('.').join(''),
      })).filter((column: any) => (column.Header !== 'Cumple Rentabilidad'));
    }
    return [];
  }).slice(1);

  const arrayDataConvention = conventionMultiDataOrdered?.map((dataLevel: any) => {
    if (dataLevel?.level !== 1) {
      if (dataLevel?.has_grouping === true) {
        const arrayGroupedItems = Object.keys(dataLevel?.result)?.map((res: any) => {
          if (dataLevel?.result[res].length > 0) {
            return dataLevel?.result[res];
          }
          return undefined;
        });

        const arrayGroupedFormattedItems = Object.keys(arrayGroupedItems.flat()).map((key: any) => {
          const formattedGroupedData:any = {};
          Object.keys(arrayGroupedItems.flat()[key])?.map((key2: any) => {
            const currentValue = arrayGroupedItems.flat()[key][key2];
            const decimals = key2?.includes('(porcentaje)') || key2?.includes('(decimales)');
            // eslint-disable-next-line no-restricted-globals, no-nested-ternary
            formattedGroupedData[key2.split('.').join('')] = !isNaN(currentValue)
              ? key2?.includes('(porcentaje)') ? `${useNumberFormat(currentValue * 100, decimals)} %` : useNumberFormat(currentValue, decimals)
              : arrayGroupedItems.flat()[key][key2];
            return formattedGroupedData;
          });
          return formattedGroupedData;
        });
        return arrayGroupedFormattedItems.flat();
      }
      const dataLevelFormatted:any = {};
      // eslint-disable-next-line no-return-assign
      Object.keys(dataLevel?.result)?.map((key: any) => {
        const currentValue = dataLevel?.result[key];
        const decimals = key?.includes('(porcentaje)') || key?.includes('(decimales)');
        // eslint-disable-next-line no-restricted-globals, no-nested-ternary
        dataLevelFormatted[key.split('.').join('')] = !isNaN(currentValue)
          ? key?.includes('(porcentaje)') ? `${useNumberFormat((currentValue || 0) * 100, decimals)} %` : useNumberFormat(currentValue, decimals)
          : currentValue;
        return dataLevelFormatted;
      });
      return [dataLevelFormatted];
    }
    return [];
  }).slice(1);

  let widths = arrayColumnsConvention && arrayDataConvention?.map((_e: any, i: number) => {
    let response;
    if (i === 0) {
      response = arrayColumnsConvention[i]?.length > 3 ? 100 : 55;
    } else if (!arrayColumnsConvention[i + 1]) {
      response = arrayColumnsConvention[i].length > 4 ? 100 : 55;
    } else if (i % 2 !== 0) {
      const current = arrayColumnsConvention[i]?.length;
      const next = !arrayColumnsConvention[i + 1] ? 0 : arrayColumnsConvention[i + 1]?.length;
      const sum = 100 / (current + next);
      response = [Math.round(current * sum) - 3, Math.round(next * sum) - 3];
    }
    return response;
  });
  widths = widths?.flat().filter((element: number|undefined) => element !== undefined);

  const processArrayColumnsConvention = arrayColumnsConvention?.map((a:any) => a.map((i:any) => (
    i.accessor === 'detail' ? i : {
      Header: i.Header.replaceAll('(porcentaje)', '').replaceAll('(decimales)', ''),
      accessor: i.accessor,
    }
  )));

  const arrayColumnsToProcess = [...arrayColumnsConvention || []];
  const processArrayColumns = arrayColumnsToProcess?.map((a) => a.map((h:any) => ({
    label: h.Header,
    key: h.accessor,
  })));

  const arrayConventionToProcess:any = arrayDataConvention?.map(
    (a:any) => a.map((h:any) => ({ ...h })),
  );
  arrayConventionToProcess?.forEach((item: any, index: number) => {
    item?.forEach((element: any, index1: number) => {
      Object.entries(element).forEach(([key, value]: any) => {
        arrayConventionToProcess[index][index1][key] = useCsvNumber(`${value}`);
      });
    });
  });

  const processHeaders = headers?.map((h) => ({ label: h.Header, key: h.accessor }));

  return (
    <Container>
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
      <Typography align="center" color={Theme.colorConvention} display="block" size="28px" margin="2rem auto" weight="500">
        {`${denomination?.split('-').join(' ')} - DETALLE`}
      </Typography>

      {!isFetchingConventionMultiData ? (
        <Card display="flex" justifyContent="space-between" flexWrap="wrap" background="transparent">
          {arrayDataConvention?.map((conventionTable: any, index: number) => (
            <Fragment key={`${index * 2}`}>
              <Card
                display="flex"
                width={`${widths[index]}%`}
                background="transparent"
                flexGrow={!widths[index + 1] || index === 0 ? '0' : '1'}
                overflow="auto"
                flexWrap="wrap"
                justifyContent={(index === 1 || index === 4 ? 'flex-end' : '')}
              >
                <FullContainer>
                  <CardDataExportXLSX
                    headers={processArrayColumns[index]}
                    data={arrayConventionToProcess[index]}
                    name="convention_table_detail"
                  />
                  <Table
                    columns={processArrayColumnsConvention[index]}
                    data={conventionTable}
                    theme={Theme.colorConvention}
                    defaultPageSize={1000}
                    renderRowSubComponent={renderRowSubComponent}
                  />
                  {(index === 1 && grouping === 'CONVENCION_MILLAS') && (
                    <Button
                      type="button"
                      onClick={() => setShowExcludedPolice(true)}
                      color={Theme.colorConvention}
                      className="btn__excluded_police"
                    >
                      Ver Pólizas excluidas
                    </Button>
                  )}
                </FullContainer>
              </Card>
              {(index === 1 && grouping === 'CONVENCION_MILLAS') && (
                <Modal show={showExcludedPolice} doClosed={() => setShowExcludedPolice(false)}>
                  <Card
                    display="flex"
                    width="100%"
                    background="transparent"
                    flexGrow="1"
                    overflow="auto"
                    flexWrap="wrap"
                    justifyContent="center"
                    height="750px"
                  >
                    {(headers && processData) && (
                      <Container>
                        <CardDataExportXLSX
                          headers={processHeaders}
                          data={processDataWithExtras}
                          name="convention_exclude_polices"
                        />
                        <Table
                          columns={headers}
                          data={processData}
                          theme={Theme.colorConvention}
                          defaultPageSize={10}
                          renderRowSubComponent={renderRowSubComponent}
                        />
                      </Container>
                    )}
                  </Card>
                </Modal>
              )}
            </Fragment>
          ))}
        </Card>
      ) : (<TableSkeleton countCell={6} theme={Theme.colorConvention} />)}
    </Container>
  );
}
