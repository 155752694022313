import { gql } from 'graphql-request';

const GET_CALCULATE_PROVISIONAL_AMOUNT = gql`
  query MyQuery($userId: ID!, $type: String!, $timeframe: String, $startDate: String, $endDate: String, $year: Int, $status: String) {
    calculateProvisionalAmount(userId: $userId, type: $type, timeframe: $timeframe, startDate: $startDate, endDate: $endDate, year: $year, status: $status) {
      provisionalAmount
    }
  }
`;

export default GET_CALCULATE_PROVISIONAL_AMOUNT;
