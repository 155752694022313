import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

type LinkNavigateProps = {
  customLink: string;
  customLinkName: string;
  secondaryCustomLink: string;
  secondaryCustomLinkName: string;
};

export default function LinkNavigate({
  customLink,
  customLinkName,
  secondaryCustomLink,
  secondaryCustomLinkName,
}: LinkNavigateProps) {
  const isCompleteURL = (url: string) => url.startsWith('http://') || url.startsWith('https://');
  const currentUrl = window.location.href;
  const segments = currentUrl.split('/');
  const baseUrl = `${segments[0]}//${segments[2]}/`;

  let secondaryCustomLinkModify = secondaryCustomLink;
  if (secondaryCustomLinkModify.startsWith('/') && secondaryCustomLinkModify.length > 1) {
    secondaryCustomLinkModify = secondaryCustomLinkModify.substring(1);
  }
  if (!isCompleteURL(secondaryCustomLinkModify) && secondaryCustomLinkModify.includes('.')) {
    secondaryCustomLinkModify = `https://${secondaryCustomLinkModify}`;
  }

  let customLinkModify = customLink;
  if (customLinkModify.startsWith('/') && customLinkModify.length > 1) {
    customLinkModify = customLinkModify.substring(1);
  }
  if (!isCompleteURL(customLinkModify) && customLinkModify.includes('.')) {
    customLinkModify = `https://${customLinkModify}`;
  }

  const handleCustomLinkClick = (event: MouseEvent) => {
    event.preventDefault();
    const customLinkClick = event.currentTarget.getAttribute('href');
    if (customLinkClick) {
      setTimeout(() => {
        window.location.href = customLinkClick;
      }, 300);
    }
  };

  const handleSecondaryCustomLinkClick = (event: MouseEvent) => {
    event.preventDefault();
    const secondaryCustomLinkClick = event.currentTarget.getAttribute('href');

    if (secondaryCustomLinkClick) {
      setTimeout(() => {
        window.location.href = secondaryCustomLinkClick;
      }, 300);
    }
  };

  const renderCustomLink = () => {
    if (isCompleteURL(customLinkModify)) {
      if (customLinkName !== '' || customLinkName !== undefined || customLinkName !== null) {
        return (
          <a href={customLinkModify} target="_blank" rel="noopener noreferrer" className="card__link">
            {customLinkName}
          </a>
        );
      }
    }
    return (
      <Link to={customLinkModify} className="card__link" onClick={handleCustomLinkClick}>
        {customLinkName}
      </Link>
    );
  };

  const renderSecondaryCustomLink = () => {
    if (isCompleteURL(secondaryCustomLinkModify)) {
      return (
        <a href={secondaryCustomLinkModify} className="card__button" target="_blank" rel="noopener noreferrer">
          {secondaryCustomLinkName}
        </a>
      );
    }

    return (
      <a href={baseUrl + secondaryCustomLinkModify} className="card__button" onClick={handleSecondaryCustomLinkClick}>
        {secondaryCustomLinkName}
      </a>
    );
  };

  return (
    <div>
      {customLink !== '' && customLinkName !== '' && (
        renderCustomLink()
      )}
      {secondaryCustomLink !== '' && secondaryCustomLinkName !== '' && (
        renderSecondaryCustomLink()
      )}
    </div>
  );
}
