import { useRef } from 'react';

export default function useRemoteInfoUser() {
  const ipAddressRef = useRef<string | null>(null);
  const userAgentRef = useRef<string | null>(null);

  const getIPAddress = async () => {
    if (!ipAddressRef.current) {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
      ipAddressRef.current = data.ip;
    }
    return ipAddressRef.current;
  };

  const getUserAgent = () => {
    if (!userAgentRef.current) {
      userAgentRef.current = navigator.userAgent;
    }
    return userAgentRef.current;
  };

  return { getIPAddress, getUserAgent };
}
