import { useLocation } from 'react-router-dom';
import CampaignCalendar from './CampaignCalendar';
import CampaignGlobalPosition from './CampaignGlobalPosition';
import CampaignHome from './CampaignHome';

export default function Campaign() {
  const location = useLocation();

  switch (location.pathname) {
  case '/home/campaign':
    return <CampaignHome />;
  case '/global-position/campaign':
    return <CampaignGlobalPosition />;
  case '/calendar/campaign':
    return <CampaignCalendar />;
  default:
    return null;
  }
}
