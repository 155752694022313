import styled from 'styled-components';
import Theme from '../../../styles/Theme';

const StyledSwitch = styled.label<{ checked: boolean }>`
  cursor: pointer;
  text-indent: -9999px;
  width: 2.7rem;
  height: 1.7rem;
  background: ${({ checked }) => (checked ? Theme.colorGreen : Theme.colorGray)};
  display: block;
  border-radius: 100px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: ${({ checked }) => (checked ? '.5rem' : 'calc(65% - 10px)')};
    top: .25rem;
    width: 1.2rem;
    height: 1.2rem;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
`;

export const StyledSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default StyledSwitch;
