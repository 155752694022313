import { useQuery } from 'react-query';
import { getGraphQLClient } from '../graphql/graphqlClient';
import GET_BANNERS from '../graphql/queries/getBannersGql';
import useYear from './useYear';
import { Banner, BannerData } from '../interfaces/Banners';

export default function useBanners() {
  const graphQLClient = getGraphQLClient();
  const { year } = useYear();

  const { data: banners } = useQuery(
    ['Banners', year],
    async () => (graphQLClient && graphQLClient.request(GET_BANNERS, { year })),
    {
      enabled: !Number.isNaN(year),
      staleTime: Infinity,
    },
  );

  const data: BannerData[] = banners?.banners?.nodes
    ?.reduce((acc: BannerData[], banner: Banner) => {
      const yearIndex = acc.findIndex((item) => item.year === banner.year);

      if (yearIndex === -1) {
        acc.push({
          year: banner.year,
          info: [banner],
        });
      } else {
        acc[yearIndex].info.push(banner);
      }

      return acc;
    }, []);

  return { data };
}
