import FilterModel from '../../molecules/Filter/FilterModel';
import {
  CustomSelectContainer,
  CustomSelect,
} from './StyledDropdown';

export interface CustomDropdown {
  label?: string;
  options: CustomOptions[];
  isDisabled?: boolean;
  isLoading?: boolean;
  isClearable?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  name: string;
  defaultValue?: string | number;
  filter: FilterModel;
  setFilter: (filter :FilterModel) => void;
}
export interface CustomOptions {
  label: string;
  value: string | number | undefined;
}

export default function Dropdown({
  label,
  options,
  isDisabled,
  isLoading,
  isClearable,
  isRtl,
  isSearchable,
  name,
  defaultValue,
  filter,
  setFilter,
} : CustomDropdown) {
  const positionTime = options ? options.find((item) => item.value === defaultValue) : '';
  const positionOptions = positionTime ? options.indexOf(positionTime) : 0;
  return (
    <CustomSelectContainer>
      {label && <label htmlFor="state">{label}</label>}
      <CustomSelect
        className="basic-single"
        classNamePrefix="select"
        defaultValue={options ? options[positionOptions] : ''}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name={label}
        options={options}
        onChange={(event: any) => setFilter(
          {
            ...filter,
            [name]: event.sub ? '' : event.value,
            type: event.type,
            sub: event.sub,
            subgrouping: event.sub ? event.value : '',
          },
        )}
      />
    </CustomSelectContainer>
  );
}

Dropdown.defaultProps = {
  label: '',
  isDisabled: false,
  isLoading: false,
  isClearable: true,
  isRtl: false,
  isSearchable: true,
  defaultValue: '',
};
