import { gql } from 'graphql-request';

const GET_USER_SEARCH_COUNTER_GQL = gql`
  query MyQuery($query: String, $year: Int) {
    userSearchCounter(query: $query, year: $year) {
      counter
    }
  }
`;

export default GET_USER_SEARCH_COUNTER_GQL;
