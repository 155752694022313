import { gql } from 'graphql-request';

const GET_TOTAL_PLAN_MET_COUNT_GP_CHART_BY_TIMEFRAME = gql`
query MyQuery($grouping: String, $subgrouping: String, $myTeam: Boolean, $type: String!, $year: Int, $status: String) {
  totalPlanMetCountGlobalPositionChartByTimeframe(type: $type, myTeam: $myTeam, grouping: $grouping, subgrouping: $subgrouping, year: $year, status: $status) {
    totalPlanMetCount
  }
}
`;

export default GET_TOTAL_PLAN_MET_COUNT_GP_CHART_BY_TIMEFRAME;
