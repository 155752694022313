import {
  Card, CardBody, CardHeaderGraphics, HeaderAmount,
} from '../../atoms/Card/Card';
import Graphic from '../../atoms/Graphic/Graphic';
import Typography from '../../atoms/Typography/Typography';
import { LinkTo } from '../Carousel/StyledCarousel';
import useFormatDate from '../../../hooks/useDate';
import Theme from '../../../styles/Theme';

export default function CardSummaryGlobalPosition({ data, team = false, year }: any) {
  return (
    <Card
      key={data.id}
      padding="1.5rem"
      display="flex"
      theme={`${data.theme}`}
    >
      <CardBody>
        <CardHeaderGraphics>
          <Typography
            color={`${data.theme}`}
            size="24px"
            margin="0 0 1rem"
            weight="500"
            type="h3"
          >
            {data?.title}
          </Typography>
          <HeaderAmount
            amount={data?.amount}
            gain={data?.gain}
            theme={data?.theme}
            year={year}
            type={data?.slug}
          />
        </CardHeaderGraphics>
        <div
          className={`${data?.type === 'half-circle'
            ? 'group__graphic group__graphic--half-circle'
            : 'group__graphic'}`}
        >
          {data?.stadistics?.map((stadistic: any) => (
            <Graphic
              key={stadistic.id}
              label={stadistic.label}
              score={stadistic.score}
              total={stadistic.total}
              theme={data.theme}
              type={data.type}
            />
          ))}
        </div>
        <div className="graphic--footer">
          <LinkTo
            to={{ pathname: team ? `/team/${data?.slug}/resumen` : `/global-position/${data.slug}` }}
            className="link link--graphic"
          >
            <span>Ver resumen</span>
          </LinkTo>
          {!data?.date ? '' : (
            <Typography
              color={`${Theme.fontColor}`}
              size="16px"
              margin="0"
              weight="400"
              type="h2"
            >
              {`Datos a fecha ${data?.date ? useFormatDate(data?.date) : data?.date}`}
            </Typography>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
