import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import usePlan from '../../../hooks/usePlan';
import useSummaryGlobalPosition from '../../../hooks/useSummaryGlobalPosition';
import Container from '../../atoms/Container/Container';
import Grid from '../../atoms/Grid/Grid';
import FilterModel from '../../molecules/Filter/FilterModel';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import CardSummaryGlobalPosition from '../../organisms/CardSummaryGlobalPosition/CardSummaryGlobalPosition';
import CardSummaryGlobalPositionRanking from '../../organisms/CardSummaryGlobalPosition/CardSummaryGlobalPositionRanking';
import useYear from '../../../hooks/useYear';
import isMediator from '../../../hooks/useUserType';

export interface IFilter {
  temporalidad?: string[];
  campaignType?: string[];
  rappelType?: string[];
  conventionType?: string[];
  categories?: string[];
  year?: number[];
  status?: string[];
}

export default function SummaryGlobalPosition() {
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const selectedFilters: IFilter = { temporalidad: [], year: [], status: [] };
  const { year } = useYear();
  const queryClient = useQueryClient();

  let dataUser: any = {};
  dataUser = queryClient.getQueryState(['Convention']);

  const getVariables = (type: string) => {
    const variables = {
      type,
      grouping: globalFilter.grouping,
      timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
      startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
      endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
      level: globalFilter.level,
      myTeam: false,
      userId: dataUser?.data?.me?.id,
      year,
      status: globalFilter.status,
    };

    return variables;
  };

  const {
    SummaryCampaignGlobalPosition: campaign,
    refetchCampaign,
  } = useSummaryGlobalPosition(getVariables('Campaign'));

  const {
    SummaryRappelGlobalPosition: rappel,
    refetchRappel,
  } = useSummaryGlobalPosition(getVariables('Rappel'));

  const {
    SummaryConventionGlobalPosition: convention,
    refetchConvention,
  } = useSummaryGlobalPosition(getVariables('Convention'));

  const { data: planCampaign, refetch: refetchPlanCampaign } = usePlan(getVariables('Campaign'));
  const { data: planRappel, refetch: refetchPlanRappel } = usePlan(getVariables('Rappel'));
  const {
    data: planConvention,
    refetch: refetchPlanConvention,
  } = usePlan(getVariables('Convention'));

  const campaignType = planCampaign?.me?.plans?.nodes?.map((plan: any) => plan?.type);
  const rappelType = planRappel?.me?.plans?.nodes?.map((plan: any) => plan?.type);
  const conventionType = planConvention?.me?.plans?.nodes?.map((plan: any) => plan?.type);

  useEffect(() => {
    refetchCampaign();
    refetchRappel();
    refetchConvention();
    refetchPlanConvention();
    refetchPlanCampaign();
    refetchPlanRappel();
  }, [globalFilter.dateRange, globalFilter.grouping, globalFilter.status]);

  return (
    <Container>
      <GroupFilterGeneral
        selectedFilters={selectedFilters}
        filter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Grid
        regular
        columnsLg={2}
        spaceBetween={2}
        rowHeight={['14rem', '440px']}
        margin="0 0 4rem"
      >
        {(campaignType?.includes('Campaign') || campaignType?.includes('CAMPAIGN')) && (
          <CardSummaryGlobalPosition data={campaign} year={year} />
        )}
        {(rappelType?.includes('Rappel') || rappelType?.includes('RAPPEL')) && (
          <CardSummaryGlobalPosition data={rappel} year={year} />
        )}
        {(conventionType?.includes('Convention') || conventionType?.includes('CONVENTION')) && (
          <CardSummaryGlobalPosition data={convention} year={year} />
        )}
        {dataUser?.data
        && !isMediator(dataUser?.data?.me?.calculatedCategory) && (
          <CardSummaryGlobalPositionRanking />
        )}
      </Grid>
    </Container>
  );
}
