import { useQuery } from 'react-query';
import GET_HOME_NOTIFICATION_GQL from '../graphql/queries/getHomeNotificationGql';
import { createGraphQLClient } from '../graphql/graphqlClient';
import useYear from './useYear';

interface Notification {
  activeUntil?: string;
  body?: string;
  id?: string;
  promote: boolean;
  title?: string;
  updatedAt?: string;
  year: number;
}

interface TransformedData {
  year: number;
  info: Notification[];
}

export default function useNotification(promote: boolean, activeUntil?: string) {
  const graphQLClient = createGraphQLClient();
  const { year: yearSelected } = useYear();

  const params = {
    year: yearSelected,
    promote,
    activeUntil,
  };

  const { data: notifications } = useQuery(
    ['HomeNotification', params],
    async () => (graphQLClient && graphQLClient.request(GET_HOME_NOTIFICATION_GQL, params)),
    {
      enabled: !Number.isNaN(yearSelected),
      staleTime: Infinity,
    },
  );

  const data: TransformedData[] | undefined = notifications?.homeNotifications?.nodes
    ?.reduce((acc: TransformedData[], notification: Notification) => {
      const yearIndex = acc.findIndex((item) => item.year === notification.year);

      if (yearIndex !== -1) {
        acc[yearIndex].info.push(notification);
      } else {
        acc.push({ year: notification.year, info: [notification] });
      }

      return acc;
    }, []);

  return { data };
}
