import { useQuery } from 'react-query';
import { useState, useTransition, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../atoms/Container/Container';
import GET_USER_SEARCH_GQL from '../../../graphql/queries/getUserSearchGql';
import GET_USER_SEARCH_COUNTER_GQL from '../../../graphql/queries/getUserSearchCounterGql';
import { LinkTable, Table } from '../../atoms/Table/Table';
import Theme from '../../../styles/Theme';
import userColumns from './data';
import { IUserDataProps } from './types';
import ParamsModel from '../../models/PaginationModel';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export default function AdminUsers() {
  const graphQLClient = createGraphQLClient();
  const [inputValue, setInputValue] = useState<string>('');
  const [globalParams, setGlobalParams] = useState<ParamsModel>(new ParamsModel());
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();

  const userParams = {
    query: inputValue || '',
    year: Number(localStorage.getItem('year')),
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
    order: globalParams?.order || 'nif',
    orderType: globalParams?.orderType || 'desc',
  };

  const updateGlobalParams = (params: ParamsModel) => setGlobalParams(params);

  const setImport = (user: IUserDataProps) => {
    localStorage.removeItem('importId');
    localStorage.setItem('impersonateUser', JSON.stringify(user));
    navigate('/global-position/summary', { replace: true });
    window.location.reload();
  };

  const {
    data: usersSearched,
    isLoading: loading,
    isFetching,
    refetch,
  } = useQuery(
    ['userSearch', userParams],
    async () => (graphQLClient && graphQLClient.request(GET_USER_SEARCH_GQL, userParams)),
    {
      staleTime: Infinity,
    },
  );

  const { data: usersCounter, isLoading: loadingCounter, refetch: refetchCounter } = useQuery(
    ['userSearchCounter'],
    async () => (graphQLClient && graphQLClient.request(GET_USER_SEARCH_COUNTER_GQL, {
      query: inputValue || '',
      year: Number(localStorage.getItem('year')),
    })),
  );

  const handleChange = (e: any) => {
    updateGlobalParams({ ...globalParams, numberPage: 1 });
    setInputValue(e.currentTarget.value);
    startTransition(() => {
      if (loading === false || loadingCounter === false || isPending === true) {
        setTimeout(refetch, 1200);
        setTimeout(refetchCounter, 1200);
      }
    });
  };

  const getUsers = (u: any) => u?.map((user: IUserDataProps) => ({
    id: user?.id,
    email: user?.email,
    nuuma: user?.nuuma,
    firstName: user?.firstName,
    lastName: user?.lastName,
    nif: user?.nif,
    counts: user?.counts,
    category: user?.calculatedCategory,
    impersonate: <LinkTable onClick={() => setImport(user)} title="Previsualizar" />,
  }));

  const usersToSearch = usersSearched !== undefined && getUsers(usersSearched?.userSearch)
    ?.filter((user:any) => (user?.category !== undefined && user?.category !== '' && user?.category !== null));

  useEffect(() => {
    if (globalParams.numberPage !== undefined
      || globalParams.order !== undefined
      || globalParams.orderType !== undefined) {
      refetch();
      refetchCounter();
    }
  }, [globalParams.numberPage, globalParams.order, globalParams.orderType]);

  return (
    <Container>
      <input
        type="text"
        placeholder="Buscar por NIF, email o nombre"
        value={inputValue}
        onChange={handleChange}
        onPaste={handleChange}
        className="search"
      />
      {(!loading
        && !isFetching
        && userColumns
        && usersToSearch) ? (
          <Table
            columns={userColumns}
            data={usersToSearch}
            theme={Theme.colorCampaign}
            defaultPageSize={10}
            countsRecords={usersCounter?.userSearchCounter?.counter}
            countPerPage={10}
            globalParams={globalParams}
            setGlobalParams={updateGlobalParams}
          />
        ) : <TableSkeleton countCell={5} theme={Theme.colorCampaign} /> }
    </Container>
  );
}
