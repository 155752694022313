import { gql } from 'graphql-request';

const GET_COMMERCIAL_CAMPAIGN_POINTS_AND_POSITION = gql`
query MyQuery($userId: ID!, $type: String!, $grouping: String!, $subgrouping: String, $timeframe: String, $startDate: String, $endDate: String, $year: Int) {
  commercialPointsAndPositions(userId: $userId, type: $type, grouping: $grouping, subgrouping: $subgrouping, timeframe: $timeframe, startDate: $startDate, endDate: $endDate, year: $year) {
    points
    position
  }
}
`;

export default GET_COMMERCIAL_CAMPAIGN_POINTS_AND_POSITION;
