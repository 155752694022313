import { gql } from 'graphql-request';

const GET_PLANS_BY_TYPE_GQL = gql`
query MyQuery($type: String!, $first: Int, $startDate: String, $endDate: String, $grouping: String, $subgrouping: String, $timeframe: String, $year: Int) {
  planByType(type: $type, first: $first, startDate: $startDate, endDate: $endDate, grouping: $grouping, subgrouping: $subgrouping, timeframe: $timeframe, year: $year) {
    nodes {
      id
      description
      code
      startDate
      endDate
      basesUrl
      type
      grouping {
        name
      }
    }
  }
}
`;

export default GET_PLANS_BY_TYPE_GQL;
