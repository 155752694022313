type ObjectWithLowercaseValues = {
  [key: string]: string | ObjectWithLowercaseValues;
}

function convertValuesToLowercase(object: ObjectWithLowercaseValues): ObjectWithLowercaseValues {
  const result: ObjectWithLowercaseValues = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object)) {
    if (key === 'accessor' || key === 'key') {
      if (typeof value === 'string') {
        result[key] = value.toLowerCase();
      } else if (typeof value === 'object') {
        result[key] = convertValuesToLowercase(value);
      }
    } else {
      result[key] = value;
    }
  }
  return result;
}

export default function useLowerCaseValues(
  arr: ObjectWithLowercaseValues[],
): ObjectWithLowercaseValues[] {
  const result: ObjectWithLowercaseValues[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of arr) {
    const lowercaseObj = convertValuesToLowercase(obj);
    result.push(lowercaseObj);
  }
  return result;
}
