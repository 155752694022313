export default class CategoryModel {
  public id?: string;

  public name?: string;

  public scope?: string;

  public dgt?: string;

  public globalPosition?: string;

  public myTeam?: string;

  public myTeamDownloads?: string;

  constructor(other?: CategoryModel) {
    if (other) {
      this.id = other.id;
      this.name = other.name;
      this.scope = other.scope;
      this.dgt = other.dgt;
      this.globalPosition = other.globalPosition;
      this.dgt = other.dgt;
      this.myTeam = other.myTeam;
      this.myTeamDownloads = other.myTeamDownloads;
    }
  }
}
