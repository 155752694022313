import { IButton } from './IButton';
import StyledButton from './StyledButton';

export default function Button({
  type,
  color,
  children,
  size,
  marginBottom,
  isType,
  padding,
  ...otherProps
}: IButton) {
  return (
    <StyledButton
      type={type}
      color={color}
      size={size}
      mb={marginBottom}
      isType={isType}
      padding={padding}
      {...otherProps}
    >
      <span>{children}</span>
    </StyledButton>
  );
}
