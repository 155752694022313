import {
  SkeletonCardBar,
  SkeletonCardBarCenter,
  SkeletonCardBarContent,
  SkeletonCardBarHeader,
  SkeletonCardBarHeaderLeft,
  SkeletonCardBarHeaderRight,
  SkeletonCardBarWrapper,
  SkeletonCardBody,
  SkeletonCardFooter,
  SkeletonCardHeader,
  SkeletonCardIcon,
  SkeletonCardWord,
  SkeletonCardWrapper,
} from './StyledCard';

export default function SkeletonCard(props: {
  cols?: number,
  rows?: number,
  maxWidth: string,
  type?: string,
}) {
  const {
    cols, rows, maxWidth, type,
  } = props;

  return (
    type?.includes('progressbar') ? (
      <SkeletonCardBarWrapper rows={rows} maxWidth={maxWidth}>
        <SkeletonCardBarHeader>
          <SkeletonCardBarHeaderLeft />
          <SkeletonCardBarHeaderRight />
        </SkeletonCardBarHeader>
        <SkeletonCardBarContent>
          <SkeletonCardBarCenter>
            {Array(rows)
              .fill(0)
              .map((_, i: number) => (
                <SkeletonCardBar key={`${Math.random() * i}`} />
              ))}
          </SkeletonCardBarCenter>
        </SkeletonCardBarContent>
      </SkeletonCardBarWrapper>
    ) : (
      <SkeletonCardWrapper cols={cols} maxWidth={maxWidth}>
        {Array(cols)
          .fill(0)
          .map((_, i: number) => (
            <SkeletonCardBody key={`${Math.random() * i}`}>
              <SkeletonCardHeader>
                <SkeletonCardIcon />
                <SkeletonCardWord />
              </SkeletonCardHeader>
              <SkeletonCardFooter />
            </SkeletonCardBody>
          ))}
      </SkeletonCardWrapper>
    )
  );
}

SkeletonCard.defaultProps = {
  type: '',
  cols: 0,
  rows: 0,
};
