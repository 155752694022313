import { useNavigate } from 'react-router-dom';
import useImportPreview from '../../../hooks/useImportPreview';
import {
  PreviewerContainer,
  ClosePreviewButton,
} from './StyledPreviewer';

export default function Previewer() {
  const { previewing, importId } = useImportPreview();
  const navigate = useNavigate();

  const stopPreviewing = () => {
    localStorage.removeItem('importId');
    navigate('/admin/imports', { replace: true });
  };

  return (
    previewing()
      ? (
        <PreviewerContainer>
          <>
            Estás previsualizando
            <br />
            la importación&nbsp;
            { importId }
          </>
          <ClosePreviewButton onClick={() => stopPreviewing()}>
            Dejar de previsualizar
          </ClosePreviewButton>
        </PreviewerContainer>
      )
      : null
  );
}
