import { gql } from 'graphql-request';

const SUBSCRIBED_OR_UNSUBSCRIBED_TO_PLAN = gql`
  mutation subscribeOrUnsubscribeToPlan($userId: ID!, $planCode: String!, $subscribe: Boolean!, $from: String!) {
    subscribeOrUnsubscribeToPlan(userId: $userId, planCode: $planCode, subscribe: $subscribe, from: $from) {
      id
    }
  }
`;

export default SUBSCRIBED_OR_UNSUBSCRIBED_TO_PLAN;
