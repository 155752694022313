import { useQuery } from 'react-query';
import GET_SUMMARY_BY_TIMEFRAME from '../graphql/queries/getSummaryByTimeframe';
import Theme from '../styles/Theme';
import useNumberFormat from './useNumberFormat';
import { createGraphQLClient } from '../graphql/graphqlClient';

type Params = {
  grouping?: string;
  timeframe: string;
  startDate?: string;
  endDate?: string;
  subgrouping?: string;
  status?: string;
  level?: number;
  myTeam: boolean;
  year?: number;
}

export default function useSummaryTeam(props: Params) {
  const graphQLClient = createGraphQLClient();

  const {
    grouping, subgrouping, timeframe, startDate, endDate, level, myTeam, year, status,
  } = props;

  const params = {
    endDate, grouping, subgrouping, level, myTeam, startDate, timeframe, year, status,
  };

  const {
    data: summaryCampaign,
    refetch: refetchSummaryCampaign,
    isFetching: isFetchingSummaryCampaign,
  } = useQuery(
    ['SummaryCampaign', {
      ...params,
      type: 'Campaign',
    }],
    async () => (graphQLClient && graphQLClient.request(GET_SUMMARY_BY_TIMEFRAME, {
      ...params,
      type: 'Campaign',
    })),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: summaryRappel,
    refetch: refetchSummaryRappel,
    isFetching: isFetchingSummaryRappel,
  } = useQuery(
    ['SummaryRappel', {
      ...params,
      type: 'Rappel',
    }],
    async () => (graphQLClient && graphQLClient.request(GET_SUMMARY_BY_TIMEFRAME, {
      ...params,
      type: 'Rappel',
    })),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: summaryConvention,
    refetch: refetchSummaryConvention,
    isFetching: isFetchingSummaryConvention,
  } = useQuery(
    ['SummaryConvention', {
      ...params,
      type: 'Convention',
    }],
    async () => (graphQLClient && graphQLClient.request(GET_SUMMARY_BY_TIMEFRAME, {
      ...params,
      type: 'Convention',
    })),
    {
      staleTime: Infinity,
    },
  );

  const campaign = {
    id: 0,
    title: 'Campañas',
    amount: useNumberFormat(summaryCampaign?.summaryByTimeframe?.totalAmount, true),
    type: 'progressbar',
    theme: `${Theme.colorCampaign}`,
    slug: 'campaign',
    stadistics: [
      {
        id: 0,
        label: 'Gana',
        score: summaryCampaign?.summaryByTimeframe?.totalUserWinCount,
        total: summaryCampaign?.summaryByTimeframe?.participantsCount,
      },
      {
        id: 1,
        label: 'Participa',
        score: summaryCampaign?.summaryByTimeframe?.participantsCount,
        total: undefined,
      },
      {
        id: 2,
        label: 'Tréboles',
        score: summaryCampaign?.summaryByTimeframe?.totalTreboles,
        total: undefined,
      },
      {
        id: 3,
        label: 'Puntos',
        score: useNumberFormat(summaryCampaign?.summaryByTimeframe?.points, true),
        total: undefined,
      },
    ],
  };

  const rappel = {
    id: 1,
    title: 'Rappeles',
    amount: useNumberFormat(summaryRappel?.summaryByTimeframe?.totalAmount, true),
    type: 'progressbar',
    theme: `${Theme.colorRappel}`,
    slug: 'rappel',
    stadistics: [
      {
        id: 0,
        label: 'Cumple',
        score: summaryRappel?.summaryByTimeframe?.totalUserMetCount,
        total: summaryRappel?.summaryByTimeframe?.participantsCount,
      },
      {
        id: 1,
        label: 'Participa',
        score: summaryRappel?.summaryByTimeframe?.participantsCount,
        total: undefined,
      },
    ],
  };

  const convention = {
    id: 2,
    title: 'Convención',
    amount: useNumberFormat(summaryConvention?.summaryByTimeframe?.totalAmount, true),
    type: 'progressbar',
    theme: `${Theme.colorConvention}`,
    slug: 'convention',
    stadistics: [
      {
        id: 0,
        label: 'Gana',
        score: summaryConvention?.summaryByTimeframe?.totalUserWinCount,
        total: summaryConvention?.summaryByTimeframe?.participantsCount,
      },
      {
        id: 1,
        label: 'Participa',
        score: summaryConvention?.summaryByTimeframe?.participantsCount,
        total: undefined,
      },
    ],
  };

  return {
    campaign,
    refetchSummaryCampaign,
    isFetchingSummaryCampaign,
    rappel,
    refetchSummaryRappel,
    isFetchingSummaryRappel,
    convention,
    refetchSummaryConvention,
    isFetchingSummaryConvention,
  };
}
