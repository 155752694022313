import { useLocation } from 'react-router-dom';
import ConventionCalendar from './ConventionCalendar';
import ConventionGlobalPosition from './ConventionGlobalPosition';
import ConventionHome from './ConventionHome';

export default function Convention() {
  const location = useLocation();

  switch (location.pathname) {
  case '/home/convention':
    return <ConventionHome />;
  case '/global-position/convention':
    return <ConventionGlobalPosition />;
  case '/calendar/convention':
    return <ConventionCalendar />;
  default:
    return null;
  }
}
