import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const StyledChartPie = Styled.div<{label?: string, theme?: string, percentage?: number}>`
  --p: ${(props) => props.percentage};
  --b: 14px;
  --c: ${(props) => props.theme};
  --w: 114px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
  }
  &::before {
    inset:0;
    background: radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
    -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    background-size: 0 0, auto;
    z-index: 1;
  }
  &::after {
    --c: ${Theme.colorSecondary3};
    --p: 100;
    inset: 0;
    background: radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
    mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
  }

  b,
  span {
    font-size: 12px;
    line-height: 1.25;
    display: block;
  }

  b {
    color: ${(props) => props.theme};
    display: block;
    font-size: 14px;
  }
`;

export const StyledCharBar = Styled.div<{label?: string, theme?: string, score?: number, total?: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

export const StyledNumberLine = Styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

export const StyledData = Styled.div`
  align-self: center;
  margin-left: 20px;
  b,
  span {
    font-size: 18px;
    line-height: 1.25;
    display: block;
    color: ${Theme.colorSecondary3}
  }

  b {
    color: ${(props) => props.theme};
    display: block;
    font-size: 20px;
  }
`;
