import { useQuery } from 'react-query';
import GET_TEAM_RESULTS_GQL from '../graphql/queries/getTeamResultsGql';
import { createGraphQLClient } from '../graphql/graphqlClient';

interface IData {
  type: string,
  grouping: string | undefined,
  timeframe: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
  myTeam: boolean | undefined,
  year: number | undefined,
  status: string | undefined,
  level: number | undefined,
}

export default function useGlobalPositionCharts(options: IData) {
  const graphQLClient = createGraphQLClient();
  return useQuery(
    [options.type, 'globalPositionCharts'],
    async () => graphQLClient && graphQLClient.request(
      GET_TEAM_RESULTS_GQL,
      options,
    ),
  );
}
