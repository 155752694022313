const processCsvNumber = (value: any, decimals: boolean) => {
  let stringValue:any = `${value}`;

  if (typeof stringValue === 'string') {
    stringValue = stringValue.replaceAll('"', '');
  }

  if (stringValue.includes('undefined')) {
    return stringValue.replaceAll('undefined', '');
  }

  if (stringValue === null || stringValue === 'null') {
    return '';
  }

  if (stringValue === undefined || stringValue === 'undefined') {
    return 0;
  }

  if (stringValue === false || stringValue === 'false') {
    return 0;
  }

  if (stringValue === true || stringValue === 'true') {
    return 1;
  }

  if (!Number.isNaN(stringValue) && stringValue.includes('%')) {
    stringValue = stringValue.replaceAll('.', '');
    stringValue = stringValue.replaceAll(',', '.');
    // stringValue = stringValue.replace(/\s/g, '');
    // stringValue = `${+Number.parseFloat(stringValue).toFixed(2) / 100}%`;
    stringValue = +Number.parseFloat(stringValue).toFixed(2) / 100;
    return {
      v: stringValue,
      t: 'n',
      z: '0.00%',
    };
  }

  if (/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(stringValue)) {
    return stringValue;
  }

  if (/^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/.test(stringValue)) {
    return stringValue;
  }

  if (/[a-zA-Z]/g.test(stringValue)) {
    return stringValue;
  }

  if (Number.isInteger(+stringValue)) {
    stringValue = stringValue.replaceAll('.', '');
    return +stringValue;
  }

  if (typeof value === 'number' && !Number.isInteger(parseFloat(stringValue).toFixed(2))) {
    stringValue = Number.parseFloat(stringValue).toFixed(2);
    stringValue = stringValue.replaceAll('.', ',');
    return stringValue;
  }

  if (typeof value === 'number' && Number.isInteger(parseFloat(stringValue).toFixed(2))) {
    return stringValue;
  }

  if (typeof stringValue === 'string') {
    stringValue = stringValue.replaceAll('.', '');
  }

  if (typeof stringValue === 'string') {
    stringValue = stringValue.replaceAll(',', '.');
  }

  if (!Number.isInteger(parseFloat(stringValue).toFixed(2)) || decimals) {
    stringValue = Number.parseFloat(stringValue).toFixed(2);
    return +stringValue;
  }

  if (Number.isInteger(parseFloat(stringValue).toFixed(2)) || !decimals) {
    stringValue = Number.parseInt(stringValue, 10);
    return +stringValue;
  }

  return stringValue;
};

export default function useCsvNumber(value: any, decimals: boolean = false) {
  if (typeof value === 'object') {
    let array: any[] = [];
    if (Array.isArray(value)) {
      array = value.map((v) => {
        let item: any = null;
        if (typeof v !== 'object') {
          item = processCsvNumber(v, true);
        } else {
          item = { ...v };
          Object.entries(v).forEach(([key, k]) => {
            item[key] = processCsvNumber(k, true);
          });
        }
        return item;
      });
      return array;
    }
    if (value === null || value === 'null') {
      return 0;
    }
    return '';
  }
  return processCsvNumber(value, decimals);
}
