export default class FilterModel {
  public dateRange?: string;

  public category?: string;

  public campaignTypeId?: string;

  public rappelTypeId?: string;

  public conventionTypeId?: string;

  public grouping?: string;

  public subgrouping?: string;

  public sub?: boolean;

  public type?: string;

  public dgt?: string;

  public dt?: string;

  public od?: string;

  public nif?: string;

  public firstName?: string;

  public lastName?: string;

  public level?: number;

  public selectedScope?: string;

  public group?: string;

  public search?: string;

  public status?: string = 'in_progress';

  public id?: string;

  public planType?: string;

  public year?: number;

  constructor(other?: FilterModel) {
    if (other) {
      this.dateRange = other.dateRange;
      this.category = other.category;
      this.campaignTypeId = other.campaignTypeId;
      this.rappelTypeId = other.rappelTypeId;
      this.conventionTypeId = other.conventionTypeId;
      this.dgt = other.dgt;
      this.dt = other.dt;
      this.od = other.od;
      this.nif = other.nif;
      this.firstName = other.firstName;
      this.lastName = other.lastName;
      this.grouping = other.grouping;
      this.subgrouping = other.subgrouping;
      this.sub = other.sub;
      this.type = other.type;
      this.level = other.level;
      this.selectedScope = other.selectedScope;
      this.group = other.group;
      this.search = other.search;
      this.status = other.status;
      this.id = other.id;
      this.planType = other.planType;
      this.year = other.year;
    }
  }
}
