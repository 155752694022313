import Styled from 'styled-components';

const StyledGrid = Styled.div<{columnsMs?: number, columnsLg?: number, rowsMs?: number, rowsLg?: number, childDimensionsMs?: any, childDimensionsLg?: any, rowHeight?: any, spaceBetween?: number, regular?: boolean, margin?: string, maxWidth?: string, minWidth?: string, alignItems?: string, minChildWidth?: string, bg?: string, width?: string}>`
  ${(props) => props.bg && `background-color: ${props.bg};`}
  display: grid;
  gap: ${(props) => props.spaceBetween}rem;
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.width};
  align-items: ${(props) => props.alignItems};
  margin: ${(props) => props.margin};
  ${(props) => props.minChildWidth
    && `grid-template-columns: repeat(auto-fill, minmax(${props.minChildWidth}, 1fr));`}

  @media screen and (min-width: 768px) {
    ${(props) => (!props.minChildWidth && props.regular)
      && `grid-template-columns: repeat(${props.columnsLg}, 1fr);`}
  }

  > * {
    min-height: ${(props) => props.rowHeight[0]};
  }
`;

export default StyledGrid;
