import LinkTo from '../../atoms/Link/LinkTo';

type Params = {
  plan: string | undefined,
  groupings: {
    Campaign: Array<Grouping>,
    Rappel: Array<Grouping>,
    Convention: Array<Grouping>,
  },
}

type Grouping = {
  id: string,
  label: string,
}

export default function SummaryTeamCta(props: Params) {
  const { plan, groupings } = props;

  return (
    <div className="list-cta">
      {(plan === 'campaign' && groupings?.Campaign
        ?.slice(1)
        ?.map((grouping: {id: string, label: string}) => (
          <LinkTo key={grouping.id} to={{ pathname: `/team/${plan}/${grouping.id}` }} className="btn-outline">
            <span>{grouping.label}</span>
          </LinkTo>
        )))}
      {(plan === 'rappel' && groupings?.Rappel
        ?.slice(1)
        ?.map((grouping: {id: string, label: string}) => (
          <LinkTo key={grouping.id} to={{ pathname: `/team/${plan}/${grouping.id}` }} className="btn-outline">
            <span>{grouping.label}</span>
          </LinkTo>
        )))}
      {(plan === 'convention' && groupings?.Convention
        ?.slice(1)
        ?.map((grouping: {id: string, label: string}) => (
          <LinkTo key={grouping.id} to={{ pathname: `/team/${plan}/${grouping.id}` }} className="btn-outline">
            <span>{grouping.label}</span>
          </LinkTo>
        )))}
    </div>
  );
}
