import { IIcon } from './Iicon';
import IconCup from './IconCup';
import IconClover from './IconClover';
import IconStart from './IconStart';
import IconEuro from './IconEuro';
import IconQuarterly from './IconQuarterly';
import IconQuarterlyTotal from './IconQuarterlyTotal';
import IconParticipant from './IconParticipant';
import IconComply from './IconComply';
import IconObjectives from './IconObjectives';
import IconRanking from './IconRanking';

function IconScore({
  color, height, type, width,
}: IIcon):any {
  switch (type) {
  case 'cup':
    return <IconCup color={color} height={height} width={width} />;
  case 'clover':
    return <IconClover color={color} height={height} width={width} />;
  case 'start':
    return <IconStart color={color} height={height} width={width} />;
  case 'euro':
    return <IconEuro color={color} height={height} width={width} />;
  case 'quarterly':
    return <IconQuarterly color={color} height={height} width={width} />;
  case 'quarterlytotal':
    return <IconQuarterlyTotal color={color} height={height} width={width} />;
  case 'participant':
    return <IconParticipant color={color} height={height} width={width} />;
  case 'comply':
    return <IconComply color={color} height={height} width={width} />;
  case 'win':
    return <IconCup color={color} height={height} width={width} />;
  case 'ranking':
    return <IconRanking color={color} height={height} width={width} />;
  case 'objectives':
    return <IconObjectives color={color} height={height} width={width} />;
  default:
    return null;
  }
}

export default IconScore;
