import { IModal } from './IModal';
import StyledModal, {
  StyledClosedButton,
  StyledContainerModal,
  StyledModalBackground,
  StyledModalContent,
  StyledModalHeader,
  StyledModalHeading,
} from './StyledModal';

export default function Modal(props:IModal) {
  const { children, show, doClosed } = props;
  return (
    <StyledModalBackground show={show}>
      <StyledModal>
        <StyledContainerModal>
          <StyledModalHeader>
            <StyledModalHeading>
              Pólizas Excluidas
            </StyledModalHeading>
            <StyledClosedButton onClick={() => doClosed()}>X</StyledClosedButton>
          </StyledModalHeader>
          <StyledModalContent>
            {children}
          </StyledModalContent>
        </StyledContainerModal>
      </StyledModal>
    </StyledModalBackground>
  );
}
