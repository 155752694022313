import Styled from 'styled-components';

const StyledLogoIncentive = Styled.img`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100px;
  @media screen and (min-width: 768px) {
  top: 0.5rem;
    max-width: 150px;
  }
`;

export default StyledLogoIncentive;
