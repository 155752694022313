import Styled from 'styled-components';
import { Link } from 'react-router-dom';
import Theme from '../../../styles/Theme';

export const CarouselWrapper = Styled.div`
  overflow: hidden;
  margin-bottom: 2rem;
`;

export const CarouselList = Styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
`;

export const CarouselItem = Styled.li`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 320px;
  min-width: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    height: 430px;
  }
`;

export const CarouselItemContainer = Styled.article`
  max-width: 900px;
  width: 90%;
  margin: auto;
  position: relative;
  padding: 1.5rem;
  text-align: center;
  display: grid;
  align-items: center;
  grid-gap: 0.72rem;

  > * {
    z-index: 2;
    position: relative;
  }

  &::before,
  &::after {
    content: '';
    background-color: ${Theme.colorWhite};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    z-index: 1;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 4rem;
    height: 100%;
    max-width: 380px;
    padding: 0 0 0 3rem;

    &:before {
      transform: skew(-25deg);
      left: 5rem;
    }

    &::after {
      transform: skew(-5deg);
    }
  }
}
`;

export const ImageWrapper = Styled.figure`
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
`;

export const Title = Styled.h2`
  font-weight: 500;
  font-size: 20px;
  margin: 0 auto 0.5rem auto;

  span {
    font-weight: 400;
    display: block;
    font-size: 16px;
  }
  &::after {
    content: '';
    background-color: ${Theme.colorWhite};
    display: block;
    z-index: -1;
    width: 80%;
  }

  @media screen and (min-width: 768px) {
    font-size: 42px;
    line-height: 1.12;
    text-align: left;
    margin: 0px -3rem 2rem 0;

    span {
      margin-bottom: 0.32rem;
      font-size: 26px;
    }
  }
`;

export const Image = Styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%,-50%);
  object-fit: cover;
`;

export const LinkTo = Styled(Link)<{color?: string}>`
  color: ${(props) => props.color ?? Theme.colorPrimary};
  text-decoration: underline;
  font-size: 16px;
  font-weight: 500;

  @media screen and (min-width: 768px) {
    margin: 0 0 1rem;
  }
`;

export const Button = Styled(Link)<{size?:number}>`
  background-color: ${Theme.colorPrimary};
  border-radius: ${Theme.radius};
  color: ${Theme.colorWhite};
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 24px;
  line-height: 1.25;
  min-width: 100%;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  @media screen and (min-width: 768px) {
    min-width: ${(props) => props.size}px;
    max-width: ${(props) => props.size}px;
    margin: 0 auto 0 0;

    &:hover {
      box-shadow: ${Theme.shadow};
      transform: translateY(-2px);
    }
  }
`;

export const BallsContainer = Styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  top: -27px;
  position: relative;

`;

export const Ball = Styled.div`
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: ${Theme.colorSecondary2};
  cursor: pointer;
  &.active{
    background: ${Theme.colorSecondary3};
  }
`;
