import { gql } from 'graphql-request';

const GET_PLAN_RESULTS_CONCRETE_BY_CODE_GQL = gql`
query MyQuery($code: String!, $myTeam: Boolean = false, $numberElements: Int, $numberPage: Int, $order: String, $orderType: String) {
  planByCode(code: $code, myTeam: $myTeam, numberElements: $numberElements, numberPage: $numberPage, order: $order, orderType: $orderType) {
    id
    code
    description
    grouping {
      name
    }
    planLevels {
      id
      field
      level
      order
    }
    resultsCount
    translations
  }
}
`;
export default GET_PLAN_RESULTS_CONCRETE_BY_CODE_GQL;
