import { useNavigate } from 'react-router-dom';
import useCapitalizeFirstLetter from '../../../hooks/useCapitalizeFirstLetter';
import useYear from '../../../hooks/useYear';
import sessionStore from '../../../store/sessionStore';
import Theme from '../../../styles/Theme';
import Button from '../Button/Button';
import IconLogout from '../Icon/IconLogout';
import Typography from '../Typography/Typography';
import Info from './StyledAccountInfo';
import sessionIdentifierStore from '../../../store/sessionIdentifierStore';

export interface IUserDataProps {
  firstName?: string;
  lastName?: string;
  nif?: string;
}

export default function AccountInfo({
  firstName,
  lastName,
  nif,
}: IUserDataProps) {
  const { year } = useYear();
  const navigate = useNavigate();
  const { clearSession } = sessionStore();
  const { clearIdSession } = sessionIdentifierStore();

  return (
    <Info>
      <Typography color={Theme.colorPrimary} weight="800" size="16px" margin="0.25rem 0">
        Año &nbsp;
        {year}
      </Typography>
      <Typography color={Theme.fontColorSecondary} size="14px" margin="0.25rem 0">
        {useCapitalizeFirstLetter(`${firstName} ${lastName}`)}
      </Typography>
      {nif !== '.' && (
        <Typography color={Theme.fontColorSecondary} size="14px" margin="0.25rem 0">
          {nif}
        </Typography>
      )}
      <Button
        type="button"
        onClick={() => {
          clearSession();
          clearIdSession();
          localStorage.removeItem('impersonateUser');
          localStorage.removeItem('year');
          navigate('/home/summary', { replace: true });
        }}
        color={Theme.colorSecondary3}
        className="btn__logout"
      >
        <IconLogout width="20px" color={Theme.colorSecondary3} />
        Cerrar sesión
      </Button>
    </Info>
  );
}

AccountInfo.defaultProps = {
  firstName: '',
  lastName: '',
  nif: '',
};
