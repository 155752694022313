import { useMemo, useState } from 'react';
import {
  WinnerItemAlert,
  WinnerTextAlert,
  WinnerListAlert,
  WinnerTitle,
  WinnerToggle,
  WinnerToggleContainer,
  NotificationSeparator,
} from './StyledHome';

export default function HomeWinnersPlans(props: any) {
  const { data, year } = props;
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const dataFiltered = useMemo(() => {
    if (data === undefined) return [];
    return data?.filter((item: any) => item.year === year)[0]?.info || [];
  }, [data, year]);

  const replaceAttachments = (html: string) => {
    const regexIgnore = /<action-text-attachment.*?url="(.*?)".*?>☒<\/action-text-attachment>/g;
    const regexExtract = /<action-text-attachment.*?url="(.*?)".*?>(?:<figure.*?>.*?<\/figure>)?.*?<figcaption.*?>(.*?)<\/figcaption>.*?<\/action-text-attachment>/gs;

    const htmlWithUpdatedUrls = html.replace(regexIgnore, (_, url) => ` <a href="${url}" target="_blank">pincha aquí</a> `);
    return htmlWithUpdatedUrls.replace(regexExtract, (_, url) => ` <a href="${url}" target="_blank">pincha aquí</a> `);
  };

  return (
    <WinnerListAlert>
      {!(dataFiltered.length === 0) && (
        <WinnerItemAlert key={Math.random()}>
          <div className="banner__notifications" style={{ borderRadius: '20px' }}>
            <img src="https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/home/banner_home.png" alt="Alert" />
            <WinnerTitle>
              TUS AVISOS
              (
              {showAll ? dataFiltered.length : 1 }
              &nbsp;de&nbsp;
              {dataFiltered.length}
              )
            </WinnerTitle>
            { dataFiltered?.slice(0, showAll ? dataFiltered.length : 1)
              .map((item: any, index: number) => (
                (item.customLinkName && item.customLink)
                  || (item.notificationFileName && item.notificationFileUrl) ? (
                    <WinnerTextAlert index={index}>
                      <div dangerouslySetInnerHTML={{ __html: replaceAttachments(item.body) }} />
                      {item.customLink && (
                        <span>
                          <a href={item.customLink} target="_blank" rel="noreferrer">{item.customLinkName}</a>
                        </span>
                      )}
                      {item.notificationFileUrl && item.notificationFileUrl !== '#' && (
                        <span>
                          <a href={item.notificationFileUrl} target="_blank" rel="noreferrer">{item.notificationFileName}</a>
                        </span>
                      )}
                      {showAll && index !== dataFiltered.length - 1 && <NotificationSeparator />}
                    </WinnerTextAlert>
                  ) : (
                    <div>
                      <WinnerTextAlert>
                        <div dangerouslySetInnerHTML={{ __html: replaceAttachments(item.body) }} />
                        {showAll && index !== dataFiltered.length - 1 && <NotificationSeparator />}
                      </WinnerTextAlert>
                    </div>
                  )
              ))}
            {dataFiltered.length > 1 && (
              <WinnerToggleContainer>
                <WinnerToggle type="button" onClick={toggleShowAll}>
                  {showAll ? 'Ver menos' : 'Ver más'}
                </WinnerToggle>
              </WinnerToggleContainer>
            )}
          </div>
        </WinnerItemAlert>
      )}
    </WinnerListAlert>
  );
}
