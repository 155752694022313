import { gql } from 'graphql-request';

const GET_DELEGATE_PLAN_RESUME = gql`
  query MyQuery($year: Int, $grouping: String, $subgrouping: String, $timeframe: String, $startD: String, $endD: String, $status: String) {
    delegatePlanResume(year: $year, grouping: $grouping, subgrouping: $subgrouping, timeframe: $timeframe, startD: $startD, endD: $endD, status: $status) {
      winsCountRappel
      winsCountCampaign
      winConvention
      trebolesCampaign
      totalMillesConvention
      provisionalAmountRappel
      pointsCampaign
      participantsCountRappel
      participantsCountCampaign
      minMillesConvention
      extraMillesConvention
      dataAmountCampaign
    }
  }
`;
export default GET_DELEGATE_PLAN_RESUME;
