import { gql } from 'graphql-request';

const IMPORTS_GQL = gql`
  query imports($numberElements: Int, $numberPage: Int, $order: String, $orderType: String) {
    imports(numberElements: $numberElements, numberPage: $numberPage, order: $order, orderType: $orderType) {
      id
      filename
      current
      approved
      planId
      createdAt
      updatedAt
      counts
      plan {
        description
        dataDate
      }
    }
  }
`;

export default IMPORTS_GQL;
