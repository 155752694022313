import Container from '../../atoms/Container/Container';
import {
  MonthList,
  MonthItem,
  StyledButton,
  MonthsWrapper,
} from './StyledMonthsLine';

interface ICalendarFilterProps {
  dateRange?: string;
}

export default function MonthsLine({
  dateRange = '',
}: ICalendarFilterProps) {
  const months = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
  const startRange = (dateRange && dateRange.split(',')[1]) ? dateRange.split(',')[1].toString() : undefined;
  const endRange = (dateRange && dateRange.split(',')[2]) ? dateRange.split(',')[2].toString() : undefined;
  const startMonth = Number(startRange?.split('-')[1]);
  const endMonth = Number(endRange?.split('-')[1]);

  return (
    <Container>
      <MonthsWrapper>
        <MonthList>
          {months.map((month, index) => {
            const activeClass = startMonth > 0 && endMonth > 0 && endMonth > (index + 1) && (index + 1) > startMonth ? 'active' : '';
            const selectedClass = (index + 1) === startMonth || (index + 1) === endMonth ? 'selected' : '';
            return (
              <MonthItem key={month} className={`${selectedClass}`}>
                <StyledButton
                  key={`button_${index + 1}`}
                  type="button"
                  className={`${selectedClass} ${activeClass}`}
                  value={index + 1}
                >
                  { month }
                </StyledButton>
              </MonthItem>
            );
          })}
        </MonthList>
      </MonthsWrapper>
    </Container>
  );
}

MonthsLine.defaultProps = {
  dateRange: '',
};
