import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import useRemoteInfoUser from './useRemoteInfoUser';
import sessionIdentifierStore, { getSessionFromStore } from '../store/sessionIdentifierStore';
import { getTokenFromStore } from '../store/sessionStore';
import SEND_USER_ACCESS_RECORD from '../graphql/mutations/sendUserAccessRecordGql';

export default function useNewSession(user: any, currentURL: string) {
  const token = getTokenFromStore();
  const { getIPAddress, getUserAgent } = useRemoteInfoUser();
  const { setIdSession } = sessionIdentifierStore();
  const getSession = getSessionFromStore();
  const userId = user?.me?.id;
  const sessionId = getTokenFromStore()?.split('.')[2] || '';
  const [currentURLSet, setCurrentURLSet] = useState('');

  const auth = `Bearer ${token}`;
  const approveImport = (datum: any) => axios.post(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}`, {
    query: SEND_USER_ACCESS_RECORD,
    variables: {
      id: datum.id,
      valid: datum.valid,
      userId: datum.userId,
      sessionId: datum.sessionId,
      userIp: datum.userIp,
      userAgent: datum.userAgent,
      visitedSection: currentURL,
    },
  }, {
    headers: {
      authorization: auth,
    },
  }).then();

  const { mutate } = useMutation(approveImport);

  useEffect(() => {
    (async () => {
      const userIp = await getIPAddress() || '';
      const userAgent = getUserAgent();
      if (user !== undefined && currentURLSet !== currentURL) {
        setTimeout(() => {
          setCurrentURLSet(currentURL);
          setIdSession(userId, sessionId, userIp, userAgent);
          mutate(getSession);
        }, 1000);
      }
    })();
  }, [user, getIPAddress, getUserAgent, currentURLSet, setIdSession]);

  return currentURLSet;
}
