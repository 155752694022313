import ITypography from './ITypography';
import {
  StyledParagraph, StyledTitleH1, StyledTitleH2, StyledTitleH3, StyledTitleH4, StyledTitleSpan,
} from './StyledTypography';

export default function Typography({
  children,
  align,
  color,
  size,
  weight,
  margin,
  display,
  className,
  type,
}: ITypography) {
  if (type === 'h1') {
    return (
      <StyledTitleH1
        align={align}
        color={color}
        size={size}
        weight={weight}
        margin={margin}
        display={display}
        className={className}
      >
        {children}
      </StyledTitleH1>
    );
  }
  if (type === 'h2') {
    return (
      <StyledTitleH2
        align={align}
        color={color}
        size={size}
        weight={weight}
        margin={margin}
        display={display}
        className={className}
      >
        {children}
      </StyledTitleH2>
    );
  }
  if (type === 'h3') {
    return (
      <StyledTitleH3
        align={align}
        color={color}
        size={size}
        weight={weight}
        margin={margin}
        display={display}
        className={className}
      >
        {children}
      </StyledTitleH3>
    );
  }
  if (type === 'h4') {
    return (
      <StyledTitleH4
        align={align}
        color={color}
        size={size}
        weight={weight}
        margin={margin}
        display={display}
        className={className}
      >
        {children}
      </StyledTitleH4>
    );
  }

  if (type === 'span') {
    return (
      <StyledTitleSpan
        align={align}
        color={color}
        size={size}
        weight={weight}
        margin={margin}
        display={display}
        className={className}
      >
        {children}
      </StyledTitleSpan>
    );
  }

  return (
    <StyledParagraph
      align={align}
      color={color}
      size={size}
      weight={weight}
      margin={margin}
      display={display}
      className={className}
    >
      {children}
    </StyledParagraph>
  );
}
