import { gql } from 'graphql-request';

const GET_COMMERCIAL_CAMPAIGN_DATA_GQL = gql`
query MyQuery($code: String!, $selectedScope: String, $dgt: String, $dt: String, $od: String, $category: String, $myTeamData: Boolean) {
  myTeamCommercialGroupedResultsByCode(code: $code, selectedScope: $selectedScope, dgt: $dgt, dt: $dt, od: $od, category: $category, myTeamData: $myTeamData) {
    groupedDataScope
    groupedResultByObjs
  }
}
`;

export default GET_COMMERCIAL_CAMPAIGN_DATA_GQL;
