import axios from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import SUBSCRIBED_OR_UNSUBSCRIBED_TO_PLAN from '../../../graphql/mutations/subscribedOrUnsubscribedToPlanGql';
import { StyledSwitchContainer } from '../../atoms/Switch/StyledSwitch';
import Switch from '../../atoms/Switch/Switch';
import { getTokenFromStore } from '../../../store/sessionStore';

function SubscriptionButton(props: any) {
  const { subscribed, planCode, userId } = props;
  const token = getTokenFromStore();
  const [switchState, setSwitchState] = useState(subscribed);
  const newSubscribe = !switchState;

  const auth = `Bearer ${token}`;
  const approveImport = (values: any) => axios.post(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}`, {
    query: SUBSCRIBED_OR_UNSUBSCRIBED_TO_PLAN,
    variables: {
      planCode: values.planCode,
      userId: values.userId,
      subscribe: values.subscribe,
      from: values.from,
    },
  }, {
    headers: {
      authorization: auth,
    },
  }).then(() => {
    setSwitchState(values.subscribe);
  });

  const { mutate } = useMutation(approveImport);

  const handleSubscription = () => {
    const location = window.location.pathname;
    let from = null;
    if (location.includes('global-position')) {
      from = 'MPG';
    }
    if (location.includes('team')) {
      from = 'ME';
    }
    mutate({
      userId,
      planCode,
      subscribe: newSubscribe,
      from,
    } as any);
  };
  return (
    <StyledSwitchContainer>
      <Switch
        key={planCode}
        name={`${planCode}-checkbox`}
        checked={subscribed}
        onChange={() => handleSubscription()}
      />
    </StyledSwitchContainer>
  );
}

export default SubscriptionButton;
