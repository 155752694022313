import { useEffect, useState, MouseEvent } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import Container from '../../atoms/Container/Container';
import { Table, LinkTable } from '../../atoms/Table/Table';
import Typography from '../../atoms/Typography/Typography';
import FilterModel from '../../molecules/Filter/FilterModel';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import {
  colsComercial, colsComercialNational, colsNationalDetail,
  headersCampaignByCode, renderRowSubComponent,
} from './dataCampaignsTeam';
import Button from '../../atoms/Button/Button';
import { Card } from '../../atoms/Card/Card';
import CategoryModel from '../../models/CategoryModel';
import IconArrow from '../../atoms/Icon/IconArrow';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import LinkTo from '../../atoms/Link/LinkTo';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { Results } from '../../../interfaces/Results.d';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import formatValue from '../../../hooks/useFormatValue';
import formatColumn from '../../../hooks/useFormatColumn';
import useProcessData from '../../../hooks/useProcessData';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useRemoveAccents from '../../../hooks/useRemoveAccents';
import useFilterCsvColumns from '../../../hooks/useFilterCsvColumns';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getResultsByCodeGql';
import GET_PLAN_LEVELS_BY_CODE_GQL from '../../../graphql/queries/getPlanLevelsByCodeGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_PLAN_RESULTS_BY_CODE_GQL from '../../../graphql/queries/getPlanResultsByCodeGql';
import GET_COMMERCIAL_CAMPAIGN_DATA_GQL from '../../../graphql/queries/getCommercialCampaignDataGql';

type ICategory = {
  id?: string;
  name?: string;
  scope?: string;
  dgt?: string;
  globalPosition?: string;
  myTeam?: string;
  myTeamDownloads?: string;
}

export const columnsResults = [
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }:any) => (
      <label {...row.getToggleRowExpandedProps()}>
        <LinkTable title="Detalle" onClick={() => '#'}>
          {row.isExpanded
            ? <IconArrow color={`${Theme.colorPrimary}`} rotate />
            : <IconArrow color={`${Theme.colorPrimary}`} /> }
        </LinkTable>
      </label>
    ),
  },
  {
    Header: 'Detalle',
    accessor: 'detail',
  },
];

export default function CampaignDetailResults() {
  const { id } = useParams();
  const { year } = useYear();
  const graphQLClient = createGraphQLClient();
  const navigate = useNavigate();
  const puestoDgt = '00168';
  const [clicked, setClicked] = useState<string>('');
  const [inputsToShow, setInputsToShow] = useState<string[] | []>([]);
  const [category, setCategory] = useState<ICategory>(new CategoryModel());
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const queryClient = useQueryClient();
  let categoryResponse: any = {};
  categoryResponse = queryClient.getQueryState(['userCategory']);

  const planResultsByCodeParams = {
    code: id,
    numberElements: 10,
    numberPage: 1,
    myTeam: true,
  };

  const {
    data: dataPlan,
    error: errorPlan,
  } = useQuery(
    ['CampaignPlanResultsByCode', planResultsByCodeParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLAN_RESULTS_BY_CODE_GQL,
      planResultsByCodeParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const grouping = dataPlan?.planByCode?.grouping?.name;
  const subgrouping = dataPlan?.planByCode?.subgrouping?.name;

  const variablesFilters = {
    grouping,
    subgrouping,
    type: 'Campaign',
    code: id,
    year,
  };

  const variables:any = {
    code: id,
    selectedScope: globalFilter.selectedScope || undefined,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
    nif: globalFilter.nif || undefined,
    myTeamData: true,
  };
  Object.keys(variables).forEach(
    (key:any) => (variables[key] === undefined || variables[key] === '') && delete variables[key],
  );

  const {
    data, error, refetch, isFetching,
  } = useQuery(
    ['CampaignResultsByCode', variables],
    async () => (graphQLClient && graphQLClient.request(GET_RESULTS_BY_CODE_GQL, variables)),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataComercial,
    error: errorComercial,
    refetch: refetchComercial,
    isFetching: isFetchingCommercial,
  } = useQuery(
    ['CampaignCommercialGrouping', variables],
    async () => (graphQLClient && graphQLClient.request(
      GET_COMMERCIAL_CAMPAIGN_DATA_GQL,
      variables,
    )),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    const groupedDataScope = dataComercial?.myTeamCommercialGroupedResultsByCode?.groupedDataScope;
    if (groupedDataScope !== undefined && groupedDataScope.length > 0) {
      setInputsToShow(['dgt', 'dt', 'od', 'category']);
    }
  }, [dataComercial]);

  const {
    data: dataFilters, error: errorData, isFetching: isFetchingFilters,
  } = useQuery(
    ['Campaign_Filters', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      variablesFilters,
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: planLevels,
  } = useQuery(
    ['PlanLevelsByCode', { code: id }],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLAN_LEVELS_BY_CODE_GQL,
      { code: id },
    )),
    {
      staleTime: Infinity,
    },
  );

  const showNewPage = planLevels?.planLevelResults?.map(
    (e:any) => e.level,
  ).sort((a:any, b:any) => b - a)[0] > 2;

  useEffect(() => {
    if ((grouping === 'COMERCIAL' || subgrouping === 'TRIMESTRALES') && dataPlan?.planByCode?.groupedData === true) {
      refetchComercial();
    } else {
      refetch();
    }
  }, [globalFilter]);

  useEffect(() => {
    if (categoryResponse?.data?.userCategory !== undefined) {
      setCategory(categoryResponse?.data?.userCategory);
    }
  }, []);

  useEffect(() => {
    if (category !== undefined) {
      setGlobalFilter({ ...globalFilter, selectedScope: category?.scope });
    }
  }, [category]);

  const levelsCount = dataPlan?.planByCode?.planLevels.map(
    (e:any) => e.level,
  ).sort((a:any, b:any) => b - a)[0];

  if (error || errorData || errorPlan || errorComercial) {
    return <Container>Error</Container>;
  }

  const translations = dataPlan ? JSON.parse(dataPlan?.planByCode?.translations) : {};

  const dataResults = data?.myTeamResultsByCode?.nodes?.map((r: Results) => ({
    dgt: r.territoryNameDgt ? `${r.dgt} - ${r?.territoryNameDgt}` : r.dgt,
    dt: r.territoryNameDt ? `${r.dt} - ${r.territoryNameDt}` : r.dt,
    od: r.territoryNameOd ? `${r.od} - ${r.territoryNameOd}` : r.od,
    denomination: `${r.firstName} ${r.lastName}`,
    nif: r?.nif,
    extraFields: r?.extraFields,
    dataDate: useFormatDate(r?.dataDate),
    resultId: r?.id,
    puestoId: r?.puestoId,
    userId: r?.userId,
  }));

  let dataResultsExtraFields:any = {};
  const dataWithExtras:any = [];
  dataResults?.map((res:any) => {
    dataResultsExtraFields = {};
    Object.keys(res?.extraFields).filter((c: any) => !['DGT', 'DT', 'OD'].includes(c)).map((k:string) => {
      if (k === 'FECHA ACT') {
        if (res?.dataDate) {
          dataResultsExtraFields['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.dataDate);
        } else {
          dataResultsExtraFields['FECHA DATO'.replace(/ /g, '')] = useFormatDate(res?.extraFields[k]?.split('T')[0]);
        }
      } else {
        dataResultsExtraFields[k.replace(/ /g, '').split('.').join('')] = formatValue(res?.extraFields[k], k, translations);
      }
      return dataResultsExtraFields;
    });
    if (dataPlan?.planByCode?.grouping?.name !== 'COMERCIAL' && dataPlan?.planByCode?.subgrouping?.name !== 'TRIMESTRALES' && levelsCount > 2) {
      dataResultsExtraFields.detail = <LinkTo to={`/team/campaign/resumen/${res.resultId}/detail/${res.denomination}?grouping=${dataPlan?.planByCode?.grouping?.name}&code=${id}&participantId=${res.userId}`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver detalle</LinkTo>;
    }

    const rowWithExtras = { ...res, ...dataResultsExtraFields };
    dataWithExtras.push(rowWithExtras);

    return res;
  });

  const orderedExtraFields:any = [];
  const orderedExtraFieldsCsv:any = [];
  let totalCols:any = [];
  let totalColsCsv:any = [];
  let dataTableCom: any = [];

  if (dataPlan !== undefined) {
    dataPlan?.planByCode?.planLevels?.sort((a: any, b: any) => a.order - b.order)
      ?.filter((f: any) => f.level === 1).map((pl:any) => {
        if (Object.keys(dataResultsExtraFields).map((e:any) => e.replace(/ /g, '')).includes(pl.field.replace(/ /g, '').split('.').join(''))) {
          orderedExtraFields.push({ Header: formatColumn(pl.field, translations), accessor: pl.field.replace(/ /g, '').split('.').join('') });
        }
        return orderedExtraFields;
      });
    if (dataResults && dataResults?.length > 0) {
      delete dataResults[0].extraFields;
      Object.keys(dataResults[0]).map((col:any) => {
        if (col !== 'dataDate' && col !== 'userId') {
          totalCols.push({ Header: useMappingColumnNames(col, 'campaign'), accessor: col.replace(/ /g, '') });
        }
        return totalCols;
      });
      totalCols = [totalCols, orderedExtraFields].flat();
    }
    dataPlan?.planByCode?.planLevels
      ?.sort((a: any, b: any) => a.order - b.order)
      ?.filter((f: any) => f.level === 1)
      ?.map((pl:any) => {
        if (Object.keys(dataResultsExtraFields).map((e:any) => e.replace(/ /g, '')).includes(pl.field.replace(/ /g, '').split('.').join(''))) {
          orderedExtraFieldsCsv.push({ label: formatColumn(pl.field.split('.').join(''), translations), key: pl.field.replace(/ /g, '').split('.').join('') });
        }
        return orderedExtraFieldsCsv;
      });

    if (dataResults && dataResults?.length > 0) {
      delete dataResults[0].extraFields;

      Object.keys(dataResults[0]).map((col:any) => {
        if (col !== 'userId') {
          totalColsCsv.push({ label: useMappingColumnNames(col, 'campaign'), key: col.replace(/ /g, '') });
        }
        return totalColsCsv;
      });
      totalColsCsv = [totalColsCsv, orderedExtraFieldsCsv].flat();
    }
  }

  // Filtering csv cols
  totalColsCsv = useFilterCsvColumns(
    totalColsCsv,
    dataWithExtras,
  );
  // Fin filtering csv cols

  if ((grouping === 'COMERCIAL' || subgrouping === 'TRIMESTRALES') && dataPlan?.planByCode?.groupedData === true) {
    const dataQueryComer = dataComercial?.myTeamCommercialGroupedResultsByCode?.groupedDataScope;
    const datQueryComSub = dataComercial?.myTeamCommercialGroupedResultsByCode?.groupedResultByObjs;

    let processDataQuery:any = {};

    if (dataQueryComer !== undefined && dataQueryComer !== '' && dataQueryComer.length > 0) {
      dataQueryComer?.forEach((d :any) => {
        processDataQuery = { ...processDataQuery, ...d };
      });
    }

    Object.entries(processDataQuery).forEach(([key, value]:any) => {
      value.forEach((d: any, i:number) => {
        processDataQuery[key] = { ...processDataQuery[key], ...d };
        delete processDataQuery[key][i];
      });
    });

    const processDataQueryList: any[] = [];
    const planLevels1 = dataPlan?.planByCode?.planLevels?.filter(
      (a: any) => a.level === 1,
    )?.sort((a: any, b: any) => a.order - b.order)?.map((e: any) => e?.field?.replace(/ /g, '').split('.').join('').toLowerCase());
    const planLevelsObject1 = dataPlan?.planByCode?.planLevels?.filter(
      (a: any) => a.level === 1,
    )?.sort((a: any, b: any) => a.order - b.order);

    const formattedDatQueryComSub:any = {};
    let hashDataFormatted:string = '';

    if (datQueryComSub !== undefined) {
      Object.entries(datQueryComSub).forEach(([key, value]:any) => {
        const personalData:any = dataResults?.find(
          (r:any) => parseInt(r?.resultId, 10) === processDataQuery[key].result_id,
        );
        const obj = {
          nif: personalData?.nif,
          denomination: personalData?.denomination,
          ...processDataQuery[key],
          dgt: key,
          totalPoints: useNumberFormat(processDataQuery[key]['Total Puntos'], true),
          objs: processDataQuery[key]['Objetivos Cumplidos'],
          ranking: processDataQuery[key]['Posición Ranking'],
          win: processDataQuery[key]?.GANA,
          dataDate: useFormatDate(processDataQuery[key].data_date),
          subLevelData: datQueryComSub,
          group: value,
          translations: dataPlan?.planByCode?.translations,
        };
        processDataQueryList.push(obj);
      });

      Object.keys(datQueryComSub).map((k:string) => {
        formattedDatQueryComSub[k] = [];
        if (datQueryComSub[k].length > 0) {
          datQueryComSub[k]?.map((hashData:any) => {
            const hashObjective:any = {};
            Object.keys(hashData)?.map((c: string) => {
              const formattedTransValues = Object.values(translations)
                ?.map((e:any) => useRemoveAccents(e?.replace('(decimales)', '')?.replace('(porcentaje)', '')?.trim()));

              hashDataFormatted = useRemoveAccents(hashData?.groupedItem);

              const isInValues = formattedTransValues?.includes(`${c} ${hashDataFormatted}`);

              const colFromTranslations = isInValues ? Object.keys(translations).map((e:any) => {
                if (useRemoveAccents(translations[e]?.replace('(decimales)', '')?.replace('(porcentaje)', '')?.trim()) === `${c} ${hashDataFormatted}`) {
                  return e;
                }
                return undefined;
              }).filter((element: any) => element !== undefined)[0] : c;

              hashObjective[c] = formatValue(hashData[c], colFromTranslations, translations);
              return hashObjective;
            });
            formattedDatQueryComSub[k].push(hashObjective);
            return hashData;
          });
        }
        return formattedDatQueryComSub;
      });
    }

    if (dataQueryComer !== undefined && dataQueryComer !== '' && dataQueryComer.length > 0) {
      dataTableCom = dataQueryComer?.map(
        (k:any) => {
          const arrayTableComercial = Object.values(k)?.map((e: any, index:number) => {
            if (e.length > 0 && k !== undefined) {
              const hashDynamicData: any = {};
              const personalData:any = dataResults?.find(
                // eslint-disable-next-line max-len
                (r:any) => parseInt(r?.resultId, 10) === processDataQuery[Object.keys(k)[0]]?.result_id,
              );
              Object.keys(e[index]).map((a: any) => {
                hashDynamicData[a?.replace(/ /g, '')] = e[index][a];
                return hashDynamicData;
              });
              return ({
                denomination: personalData?.denomination,
                ...hashDynamicData,
                territory: Object.keys(k)[0],
                totalPoints: useNumberFormat(e[index]['Total Puntos'], true),
                objs: e[index]['Objetivos Cumplidos'],
                ranking: e[index]['Posición Ranking'],
                win: e[index]?.GANA,
                nif: personalData?.nif,
                puestoId: e[index]?.puesto_id,
                dataDate: useFormatDate(e[index].data_date),
                subLevelData: formattedDatQueryComSub,
                group:
                formattedDatQueryComSub[Object.keys(k)[0]] !== undefined
                  ? formattedDatQueryComSub[Object.keys(k)[0]] : [],
                translations: dataPlan?.planByCode?.translations,
              });
            }
            return undefined;
          });
          return arrayTableComercial;
        },
      ).flat().filter((element:any) => element !== undefined);
    }

    const colsSubLevel:any = [];
    const uniqueCols:any = [];

    if (dataTableCom !== undefined) {
      const getGroup = dataTableCom?.map((co: any) => co.group);
      getGroup.map((e:any) => (
        Object.keys(e).map((c:any) => (!colsSubLevel.includes(c)
        && colsSubLevel.push(c)))
      ));

      uniqueCols.unshift(...headersCampaignByCode);

      const mergeWith = dataTableCom.map((item: any, i:number) => ({ ...item, ...getGroup[i] }));

      const groupedItems = mergeWith[0]?.group.map((x: { groupedItem: any; }) => x.groupedItem);
      const columnsGroupedItems = groupedItems !== undefined
        ? Object.keys(mergeWith[0]?.group[0]) : [];
      columnsGroupedItems?.pop(); // Delete groupedItem element
      const colNames: any[] = [];
      groupedItems?.map((gI: any) => columnsGroupedItems?.map((colGI) => colNames.push({ label: `${gI} ${colGI}`, key: `${colGI} ${gI}` })));
    }

    const processFormatCols = processDataQueryList.map((f: any) => ({ ...f }));

    processFormatCols.forEach((item: any, index: number) => {
      Object.entries(item).forEach(([key, value]: any) => {
        processFormatCols[index][key] = useCsvNumber(value, true);
      });
    });

    const colsComercialDynamic: any = [];

    if (grouping === 'NACIONALES' && showNewPage) {
      colsComercialDynamic.push(colsComercialNational[0]);
    } else {
      colsComercialDynamic.push(colsComercial[0]);
    }

    const processFormatColsWithValues = processFormatCols.filter(
      (e:any) => Object.keys(e)?.length > 0 && (Object.keys(e)?.includes('result_id') || Object.keys(e)?.includes('puestoId') || Object.keys(e)?.includes('id')),
    );

    if (processFormatColsWithValues[0] !== undefined) {
      Object.keys(processFormatColsWithValues[0]).map((pf: any) => {
        if (planLevels1.includes(pf?.replace(/ /g, '')?.split('.')?.join('')?.toLowerCase()) && !pf.includes('dgt', 'dt', 'od')) {
          colsComercialDynamic.push({ Header: pf, accessor: pf?.replace(/ /g, '') });
        }
        return colsComercialDynamic;
      });
    }
    if (globalFilter.selectedScope && ['DT', 'OD', 'ODC'].includes(globalFilter.selectedScope)) {
      planLevelsObject1.unshift({
        id: '0',
        field: 'denomination',
        level: 1,
        order: 0,
      });
      colsComercialDynamic.unshift({
        Header: 'Denominación',
        accessor: 'denomination',
      });
    }
    const orderedColsComercialDynamic: any = [];
    if (grouping === 'NACIONALES' && showNewPage) {
      orderedColsComercialDynamic.push(colsComercialNational[0]);
    } else {
      orderedColsComercialDynamic.push(colsComercial[0]);
    }
    const accessors = colsComercialDynamic.map((e:any) => e.accessor);
    planLevelsObject1?.map((pl:any) => {
      if (accessors?.map((e:any) => e.replace(/ /g, '').toLowerCase()).includes(pl.field.replace(/ /g, '')?.split('.')?.join('').toLowerCase())) {
        if (pl.field.toLowerCase() === 'denomination') {
          orderedColsComercialDynamic.push({ Header: 'Denominación', accessor: 'denomination' });
        } else {
          orderedColsComercialDynamic.push({ Header: formatColumn(pl.field.split('.').join(''), translations), accessor: accessors.find((e: any) => e.toLowerCase() === pl.field?.replace(/ /g, '')?.split('.')?.join('').toLowerCase()) });
        }
      }
      return orderedColsComercialDynamic;
    });

    if (grouping === 'NACIONALES' && showNewPage) {
      orderedColsComercialDynamic.push(colsNationalDetail[0]);
    }

    const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      setGlobalFilter({ ...globalFilter, selectedScope: e.currentTarget.value });
      setClicked(e.currentTarget.value);
    };

    let orderedColsComercialDynamicCsv:any = [];
    orderedColsComercialDynamic.map((k:any) => {
      orderedColsComercialDynamicCsv.push({ label: k.Header, key: k.accessor });
      return orderedColsComercialDynamic;
    });

    let processDataWithExtras:any = [];
    let processDataCsv:any = [];
    if (dataTableCom !== undefined) {
      const puestosComercial = dataTableCom?.map(
        (e:any) => e.puestoId,
      ).filter((item:any, i:any, ar:any) => ar.indexOf(item) === i);

      if (puestosComercial.length === 1 && puestosComercial.includes(puestoDgt)) {
        orderedColsComercialDynamicCsv = orderedColsComercialDynamicCsv.filter((e:any) => e?.key !== 'denomination' && e?.key !== 'NIF');
      }

      processDataWithExtras = useProcessData(dataTableCom);

      processDataCsv = dataTableCom?.map((d: any) => ({ ...d }));

      processDataCsv.forEach((item: any, index: number) => {
        Object.entries(item).forEach(([key, value]: any) => {
          const decimals = true;
          processDataCsv[index][key] = useCsvNumber(value, decimals);
        });
      });
    }

    return (
      <Container>
        <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
        <Typography
          align="center"
          color={Theme.colorCampaign}
          display="block"
          size="28px"
          margin="2rem auto"
          weight="500"
        >
          {dataPlan?.planByCode?.description}
        </Typography>
        {!isFetchingFilters ? (
          <FilterForm
            inputsToShow={inputsToShow}
            dataFilters={dataFilters}
            filter={globalFilter}
            setFilter={setGlobalFilter}
            type="Campaign"
            grouping={grouping}
          />
        ) : <FilterFormSkeleton countFilters={10} />}
        <Card
          background="transparent"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <div style={{ display: 'flex' }}>
            {category?.scope !== 'DT' && category?.scope !== 'OD' && (
              <Button
                type="button"
                name="DGT"
                size={100}
                isType="secondary"
                value="DGT"
                className={clicked === 'DGT' || globalFilter.selectedScope === 'DGT' ? 'active' : ''}
                onClick={handleClick}
              >
                Datos DGTs
              </Button>
            )}
            {category?.scope !== 'OD' && (
              <Button
                type="button"
                name="DT"
                size={100}
                isType="secondary"
                value="DT"
                className={clicked === 'DT' || globalFilter.selectedScope === 'DT' ? 'active' : ''}
                onClick={handleClick}
              >
                Datos DTs
              </Button>
            )}
            <Button
              type="button"
              name="OD"
              size={100}
              isType="secondary"
              value="OD"
              className={clicked === 'OD' ? 'active' : ''}
              onClick={handleClick}
            >
              Datos ODs
            </Button>
            <Button
              type="button"
              name="ODC"
              size={100}
              isType="secondary"
              value="ODC"
              className={clicked === 'ODC' ? 'active' : ''}
              onClick={handleClick}
            >
              Datos ODs Corredores
            </Button>
          </div>
          {processDataCsv && orderedColsComercialDynamicCsv && (
            <CardDataExportXLSX
              headers={orderedColsComercialDynamicCsv}
              data={processDataCsv}
              margin="0 0 0.5rem auto"
              name={`campaign_${id}_results`}
            />
          )}
        </Card>

        {(!isFetchingCommercial && orderedColsComercialDynamic.length >= 2) ? (data && (
          <Table
            columns={orderedColsComercialDynamic}
            data={processDataWithExtras}
            theme={Theme.colorCampaign}
            renderRowSubComponent={renderRowSubComponent}
          />
        )) : (<TableSkeleton countCell={6} theme={Theme.colorCampaign} />)}
      </Container>
    );
  }

  const filteredTotalCols = Array.from(
    new Set(totalCols.reverse().map(JSON.stringify)),
  ).reverse().map(
    (e:any) => JSON.parse(e),
  ).filter((c:any) => c.accessor !== 'id');

  const processDataWithExtrasFields = useProcessData(dataWithExtras);

  const processDataCsv = dataWithExtras.map((d: any) => ({ ...d }));
  processDataCsv.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      const decimals = true;
      processDataCsv[index][key] = useCsvNumber(value, decimals);
    });
  });

  if (levelsCount > 1 && grouping !== 'COMERCIAL' && subgrouping !== 'TRIMESTRALES') {
    if (levelsCount > 2) {
      filteredTotalCols.push(columnsResults[1]);
    } else {
      filteredTotalCols.push(columnsResults[0]);
    }
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
      <Typography
        align="center"
        color={Theme.colorCampaign}
        display="block"
        size="28px"
        margin="2rem auto"
        weight="500"
      >
        {dataPlan?.planByCode?.description}
      </Typography>

      {!isFetchingFilters ? (
        <FilterForm
          dataFilters={dataFilters}
          filter={globalFilter}
          setFilter={setGlobalFilter}
          type="Campaign"
          grouping={grouping}
        />
      ) : <FilterFormSkeleton countFilters={10} />}

      {totalColsCsv && dataWithExtras && (
        <CardDataExportXLSX
          headers={totalColsCsv}
          data={processDataCsv}
          margin="0 0 0.5rem auto"
          name={`campaign_${id}_results`}
        />
      )}

      {(!isFetching && filteredTotalCols.length >= 2) ? (data && (
        <Table
          columns={filteredTotalCols}
          data={processDataWithExtrasFields}
          theme={Theme.colorCampaign}
          renderRowSubComponent={renderRowSubComponent}
        />
      )) : (<TableSkeleton countCell={6} theme={Theme.colorCampaign} />)}
    </Container>
  );
}
