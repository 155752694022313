import { useQuery } from 'react-query';
import Theme from '../../../styles/Theme';
import { Card } from '../Card/Card';
import useRanking from '../../../hooks/useRanking';
import useYear from '../../../hooks/useYear';
import {
  PositionDate,
  PositionItem, PositionItemContent, PositionList, PositionName, PositionWrapper,
} from './StyledCardBox';
import Container from '../Container/Container';
import GET_TERRITORY_STATISTICS_GQL from '../../../graphql/queries/getTerritoryStatisticsGql';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export default function CardBoxList() {
  const graphQLClient = createGraphQLClient();
  const { year } = useYear();

  const {
    data: dataRanking,
    error: errorRanking,
  } = useQuery(
    ['RankingTerritoryStatistics', { year }],
    async () => (graphQLClient && graphQLClient.request(
      GET_TERRITORY_STATISTICS_GQL,
      { year },
    )),
  );

  const { rankingResult } = useRanking(dataRanking?.territoryStatistics?.nodes, year);

  if (errorRanking) {
    return <Container>Error</Container>;
  }

  return (
    <Card
      background={Theme.colorSecondary4}
      padding={`${rankingResult?.length < -1 ? '0' : '4rem'} 0 2rem 0`}
      margin={`${rankingResult?.length < -1 ? '10rem' : '6rem'} 0 0.5rem`}
    >
      <Container>
        <PositionWrapper>
          <PositionDate>
            {`${dataRanking?.territoryStatistics?.nodes[0]?.month || ''} ${year}`}
          </PositionDate>
          {(rankingResult) && (
            <PositionList>
              {rankingResult?.map((item:any, index:any) => (
                <PositionItem
                  key={item?.position}
                  position={index}
                  total={rankingResult?.length}
                >
                  <PositionItemContent>
                    <div>
                      {index === 0 && (<small>1</small>)}
                      {index === 1 && (<small>2</small>)}
                      {index === 2 && (<small>3</small>)}
                      {item?.icon}
                      {item?.value}
                    </div>
                  </PositionItemContent>
                  <PositionName>
                    {item?.name}
                  </PositionName>
                </PositionItem>
              ))}
            </PositionList>
          )}
        </PositionWrapper>
      </Container>
    </Card>
  );
}
