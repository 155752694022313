const socialLink = [
  {
    type: 'phone',
    href: 'https://www.mapfre.es/movil/',
    title: 'Contáctanos',
    height: '24px',
    width: '24px',
    color: '#9CB0BC',
  },
  {
    type: 'facebook',
    href: 'https://es-es.facebook.com/MAPFRE.ES/',
    title: 'Facebook',
    height: '24px',
    width: '24px',
    color: '#9CB0BC',
  },
  {
    type: 'twitter',
    href: 'https://twitter.com/MAPFRE_ES',
    title: 'Twitter',
    height: '24px',
    width: '24px',
    color: '#9CB0BC',
  },
  {
    type: 'instagram',
    href: 'https://www.instagram.com/mapfre_es/',
    title: 'Instagram',
    height: '24px',
    width: '24px',
    color: '#9CB0BC',
  },
  {
    type: 'linkedin',
    href: 'https://www.linkedin.com/company/mapfre/',
    title: 'Linkedin',
    height: '24px',
    width: '24px',
    color: '#9CB0BC',
  },
  {
    type: 'youtube',
    href: 'https://www.youtube.com/user/mapfretecuidamos',
    title: 'Youtube',
    height: '24px',
    width: '24px',
    color: '#9CB0BC',
  },
];

export default socialLink;
