import { Column } from '../interfaces/Column.d';
import useReassignColumn from './useReassignColumn';

export default function useDelegatePlanDetailColumns() {
  const campaignColumns:Column[] = [
    { Header: 'Campaña', accessor: 'name' },
    { Header: 'Gana', accessor: 'win' },
    { Header: 'Premio', accessor: 'prize' },
  ];

  const campaignColumnsExcel = useReassignColumn(campaignColumns);

  const rappelColumns:Column[] = [
    { Header: 'Rappeles', accessor: 'name' },
    { Header: 'Gana', accessor: 'win' },
    { Header: 'Situación', accessor: 'state' },
    { Header: 'Importe', accessor: 'amount' },
  ];

  const rappelColumnsExcel = useReassignColumn(rappelColumns);

  const conventionColumns:Column[] = [
    { Header: 'Convención', accessor: 'name' },
    { Header: 'Cumple min. agrup.', accessor: 'met' },
    { Header: 'Emisión seguros', accessor: 'insurance' },
    { Header: 'Ranking general', accessor: 'rankingGeneral' },
    { Header: 'Ranking DGT', accessor: 'rankingDgt' },
  ];

  const conventionColumnsExcel = useReassignColumn(conventionColumns);

  return {
    campaignColumns,
    campaignColumnsExcel,
    rappelColumns,
    rappelColumnsExcel,
    conventionColumns,
    conventionColumnsExcel,
  };
}
