import Logo from '../../atoms/Logo/Logo';
import LinkTo from '../../atoms/Link/LinkTo';
import {
  HeaderMain, HeaderTop, MenuLogged,
} from './StyledHeader';
import Container from '../../atoms/Container/Container';
import LogoIncentive from '../../atoms/Logo/LogoIncentive';
import AccountInfo from '../../atoms/Account/AccountInfo';
import Previewer from '../../molecules/Previewer/Previewer';
import Impersonate from '../../molecules/Impersonate/Impersonate';
import Navigation from '../../molecules/Navigation/Navigation';
import useLogoutInactivity from '../../../hooks/useLogoutInactivity';

export default function Header(props: any) {
  const { user, category } = props;

  useLogoutInactivity(45);

  return (
    <HeaderMain>
      <Previewer />
      <Impersonate />
      <Container>
        <MenuLogged>
          <HeaderTop>
            <LinkTo to="/home/summary">
              <Logo />
            </LinkTo>
            <LogoIncentive />
          </HeaderTop>
          <Navigation
            isAdmin={user?.me?.role?.name === 'Admin'}
            isCentral={user?.me?.role?.name === 'Central'}
            categoryId={category?.userCategory?.id}
          />
          <AccountInfo
            firstName={user?.me?.firstName}
            lastName={user?.me?.lastName}
            nif={user?.me?.nif}
          />
        </MenuLogged>
      </Container>
    </HeaderMain>
  );
}
