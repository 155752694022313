import { gql } from 'graphql-request';

const GET_RANKINGS_GQL = gql`
query MyQuery($year: Int) {
  rankings(year: $year) {
    nodes {
      id
      name
      year
      procedureFileUrl
    }
  }
}
`;

export default GET_RANKINGS_GQL;
