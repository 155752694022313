import { Link } from 'react-router-dom';
import Container from '../atoms/Container/Container';

export default function Error() {
  return (
    <Container>
      <>
        <p>Página no encontrada</p>
        <Link to="/">Ir a Home</Link>
      </>
    </Container>
  );
}
