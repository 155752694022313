import Styled from 'styled-components';

export const ImageFull = Styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%,-50%);
  object-fit: cover;
`;

export const ImageRegular = Styled.img`
  display: inline-block;
`;

export const StyledWrapImage = Styled.figure<{cover?: boolean, minHeight?: any}>`
  margin: 0;
  overflow: hidden;
  ${(props) => (props.cover
    ? `
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    `
    : `
      min-height: ${props.minHeight ? props.minHeight[0] : ''};
      position: relative;
    `
  )};

  @media screen and (min-width: 640px) {
    min-height: ${(props) => (props.minHeight ? props.minHeight[1] : '')};
  }

`;
