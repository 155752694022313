import { IIcon } from './Iicon';

export default function IconArrow({
  color, rotate, height, width,
}: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height={height ?? `${'7px'}`} width={width ?? `${'10px'}`} viewBox="0 0 10 7">
      {rotate ? <path d="M4.63792 0.380205C4.83497 0.173294 5.16503 0.173295 5.36208 0.380205L9.67151 4.90537C9.85545 5.09852 9.85539 5.40205 9.67138 5.59513L8.6947 6.61999C8.4976 6.82681 8.16759 6.82672 7.9706 6.6198L5 3.49938L2.0294 6.6198C1.83241 6.82672 1.5024 6.8268 1.3053 6.61999L0.328616 5.59513C0.144606 5.40205 0.144552 5.09852 0.328494 4.90537L4.63792 0.380205Z" fill={color} /> : <path d="M5.36208 6.61979C5.16503 6.82671 4.83497 6.8267 4.63792 6.61979L0.328494 2.09463C0.144552 1.90148 0.144606 1.59795 0.328616 1.40487L1.3053 0.38001C1.5024 0.173194 1.83241 0.173282 2.0294 0.380202L5 3.50062L7.9706 0.380202C8.16759 0.173282 8.4976 0.173194 8.6947 0.38001L9.67138 1.40487C9.85539 1.59795 9.85545 1.90148 9.67151 2.09463L5.36208 6.61979Z" fill={color} />}
    </svg>
  );
}
