import { gql } from 'graphql-request';

const GET_TOP_3_CAMPAIGN_USERS = gql`
query MyQuery($type: String!, $startDate: String, $endDate: String, $grouping: String, $subgrouping: String, $timeframe: String, $num: Int, $year: Int) {
  topPlans(type: $type, startDate: $startDate, endDate: $endDate, grouping: $grouping, subgrouping: $subgrouping, timeframe: $timeframe, num: $num, year: $year) {
    firstName
    lastName
    dgt
    dt
    od
    points
    extraFields
    territoryNameDgt
    territoryNameDt
    territoryNameOd
  }
}
`;

export default GET_TOP_3_CAMPAIGN_USERS;
