import useCsvNumber from './useCsvNumber';

export default function useProcessDataCSV(data: any) {
  const processDataCsv = data.map((d: any) => ({ ...d }));
  processDataCsv.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      const decimals = true;
      processDataCsv[index][key] = useCsvNumber(value, decimals);
    });
  });

  return processDataCsv;
}
