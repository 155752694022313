import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const PreviewerContainer = Styled.div`
  position: fixed;
  top: 2rem;
  right: 2rem;
  padding: 2rem;
  z-index: 1000;
  background: ${Theme.colorWhite};
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(220, 60, 60, 0.72);
  text-align: center;
  font-size: 14px;
`;

export const ClosePreviewButton = Styled.div`
  background: ${Theme.colorPrimary};
  color: ${Theme.colorSecondary2};
  padding: 0.5rem 1.5rem;
  margin: 0 auto;
  border-radius: 0.32rem;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
`;
