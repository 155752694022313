import { gql } from 'graphql-request';

const GET_PLAN_RESULTS_BY_CODE_GQL = gql`
query MyQuery($code: String!, $myTeam: Boolean = false, $numberElements: Int, $numberPage: Int) {
  planByCode(code: $code, myTeam: $myTeam, numberElements: $numberElements, numberPage: $numberPage) {
    id
    name
    description
    code
    startDate
    endDate
    grouping {
      name
    }
    subgrouping {
      name
    }
    timeframe
    type
    fulfillsNumber
    translations
    participantsNumber
    winsNumber
    updatedAt
    groupedData
    groupedItems
    planLevels {
      id
      field
      level
      order
    }
    results(numberPage: $numberPage, numberElements: $numberElements) {
      nodes {
        id
        amount
        category
        dgt
        dt
        od
        fulfills
        extraFields
        nif
        ranking
        rankingGroup
        status
        updateDate
        wins
        firstName
        lastName
      }
    }
  }
}
`;

export default GET_PLAN_RESULTS_BY_CODE_GQL;
