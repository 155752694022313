export default function useImportPreview() {
  const previewing = () => {
    if (localStorage.getItem('importId')) {
      return true;
    }

    return false;
  };

  const importId = localStorage.getItem('importId');

  return {
    previewing,
    importId,
  };
}
