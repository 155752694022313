import React from 'react';
import {
  TabPanel,
} from './StyledTabs';

export interface TabPanelParams {
    isActivePanel?: boolean;
    children: React.ReactNode;
    id: string;
  }

export default function Tabs({ isActivePanel, children, id }:TabPanelParams) {
  return (
    <TabPanel data-content className={`${isActivePanel ? 'is-active-panel' : ''}`} id={id}>
      {children}
    </TabPanel>
  );
}

Tabs.defaultProps = {
  isActivePanel: false,
};
