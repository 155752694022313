import { Translation } from '../interfaces/Translation.d';
import useNumberFormat from './useNumberFormat';

export default function formatValue(fieldValue: any, col: string, translations: Translation) {
  if (typeof (fieldValue) === 'number') {
    const colInTranslations = Object.values(translations).includes(col);
    const trValues = Object.values(translations);
    const translationsWithoutSpaces: any = {};
    if (typeof translations !== 'undefined') {
      Object.entries(translations)?.forEach(([keyT, valueT]) => {
        translationsWithoutSpaces[keyT.replace(/ /g, '')] = `${valueT}`.replace(/ /g, '');
      });
    }

    if (typeof fieldValue === 'number') {
      if (translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(decimales)')
        || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)')
      ) {
        if (translationsWithoutSpaces[col.replace(/ /g, '')].includes('(porcentaje)')) {
          return `${useNumberFormat(fieldValue * 100, true)} %`;
        }
        return `${useNumberFormat(fieldValue, true)}`;
      }
    }

    if (colInTranslations
      && (`${trValues.find((e:any) => e === col)}`?.includes('(decimales)')
      || translationsWithoutSpaces[col.replace(/ /g, '')]?.includes('(porcentaje)'))) {
      if (`${trValues.find((e:any) => e === col)}`?.includes('(porcentaje)')) {
        return `${useNumberFormat(fieldValue * 100, true)} %`;
      }
      return `${useNumberFormat(fieldValue, true)}`;
    }
    return useNumberFormat(fieldValue, false);
  }
  return fieldValue;
}
