import { GraphQLClient } from 'graphql-request';
import { getTokenFromStore } from '../store/sessionStore';

const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;

export const createGraphQLClient = () => {
  const token = getTokenFromStore();

  if (!token) {
    return null;
  }

  const importId = localStorage.getItem('importId') || '';
  const impersonateId = localStorage.getItem('impersonateUser') ? JSON.parse(localStorage.getItem('impersonateUser') || '').id : null;

  const headers: Record<string, string> = {
    authorization: `Bearer ${token}`,
  };

  if (importId) {
    headers['import-id'] = importId;
  }

  if (impersonateId) {
    headers['impersonate-id'] = impersonateId;
  }

  return new GraphQLClient(endpoint as string, { headers });
};

export const getGraphQLClient = () => {
  try {
    return createGraphQLClient();
  } catch (error) {
    return null;
  }
};
