import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { Card } from '../../atoms/Card/Card';
import IconDownload from '../../atoms/Icon/IconDownload';

function CardDataExportXLSX({
  data, name, cols, headers,
}: any) {
  const headersList: any[] = [];
  const headersListString: any[] = [];
  headers.forEach((h:any) => {
    if (!headersListString.includes(h.label)) {
      headersList[h.key] = h.label;
      headersListString.push(h.label);
    }
  });

  const headersObjects = {};

  Object.assign(headersObjects, headersList);

  const processInfo: any[] = [];

  if (data !== undefined) {
    Object.entries(data)?.forEach((value: any) => {
      const processData: any[] = [];
      const processDataObject = {};
      Object.entries(value[1]).forEach(([keyV, valueV]: any) => {
        if (headersList[keyV] !== undefined) {
          processData[headersList[keyV]] = valueV;
        }
      });
      Object.assign(processDataObject, processData);
      processInfo.push(processDataObject);
    });
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData: any, fileName: any, wscols: any) => {
    const ws = XLSX.utils.json_to_sheet(headersList, {
      header: headersListString.filter((h) => h.length > 1),
      skipHeader: true,
    });
    ws['!cols'] = wscols;
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: headersListString.filter((h) => h.length >= 2),
      skipHeader: false,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', numbers: 'true' });
    const dataExcel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataExcel, fileName + fileExtension);
  };

  return (
    <Card
      background="transparent"
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gap="1rem"
      margin="0 0 1rem"
    >
      {headers && data?.length > 0 && (
        <button
          type="button"
          className="btn-outline-secondary"
          onClick={() => exportToCSV(processInfo, name, cols)}
        >
          <span>
            <IconDownload color="#666" width="16px" height="16px" />
            Descargar XLSX
          </span>
        </button>
      )}
    </Card>
  );
}

export default CardDataExportXLSX;
