import Styled from 'styled-components';
import ReactSelect from 'react-select';
import Theme from '../../../styles/Theme';

export const DropDownContainer = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  color: ${Theme.fontColorSecondary};
  font-weight: 500;

  @media screen and (min-width: 768px) {
    max-width: 238px;
  }
`;

export const CustomSelectContainer = Styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  color: ${Theme.fontColorSecondary};
  font-weight: 500;

  @media screen and (min-width: 768px) {
    max-width: 238px;
  }
`;

export const CustomSelect = Styled(ReactSelect)`
  background-color: ${Theme.colorWhite};
  font-size: 14px;
  color: ${Theme.colorSecondary3};

  &.select__control {
    border-radius: 5px;
    border-color: ${Theme.colorSecondary3};
    border-width: 0.5px;
  }

  .select__control--is-focused,
  .select__control--is-focused:hover,
  input {
    border-color: rgba(156, 176, 188, 1);
    box-shadow: none;
  }

  &.select__menu {
    z-index: 100;
  }

  .select__indicator-separator,
  .select__clear-indicator {
    display: none;
  }

  .select__indicator {
    cursor: pointer;
  }

  .select__menu {
    z-index: 4;
  }
`;
