import Styled, { css } from 'styled-components';
import Theme from '../../../styles/Theme';
import { Card } from '../Card/Card';
import Typography from '../Typography/Typography';

const LoadingFullScreen = css<{background?: string}>`
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.background || 'rgba(0, 0, 0, 0.42)'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const loading = css<{background?: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
`;

const LoadingMask = Styled.div<{background?: string}>`
  ${(props) => (props.background ? loading : LoadingFullScreen)}
`;

const LoadingContent = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingSpinner = Styled.div<{loadingColor?: string, theme?: string, width?: string}>`
  border: 5px solid ${(props) => props.loadingColor || Theme.colorWhite};
  width: ${(props) => (props.width ? props.width : '2rem')};
  height: ${(props) => (props.width ? props.width : '2rem')};
  border-radius: 50%;
  border-left-color: ${Theme.colorPrimary};
  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface ILoading {
  background?: string;
  loadingColor?: string
  message?: string;
  tooltip?: boolean;
}

export default function Loading({
  message, background, loadingColor, tooltip,
}: ILoading) {
  return (
    <LoadingMask background={background}>
      <LoadingContent>
        {tooltip === false ? (
          <>
            <LoadingSpinner loadingColor={loadingColor} />
            {message && <p>{message}</p>}
          </>
        ) : (
          <Card
            background="#FFFFFF"
            width="300px"
            radius="20px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="auto"
            padding="1rem"
          >
            <Card
              background="transparent"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <LoadingSpinner loadingColor={loadingColor} />
              {message && (
                <Typography align="center" display="block" size="16px" margin="1rem auto" weight="400">
                  {message}
                </Typography>
              ) }
            </Card>
          </Card>
        )}
      </LoadingContent>
    </LoadingMask>
  );
}
Loading.defaultProps = {
  background: '',
  loadingColor: '',
  message: '',
  tooltip: false,
};
