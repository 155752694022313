import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';

export default function useExportXLSX(data: any, columns: any, fileName: any) {
  const columnsList: any[] = [];
  const columnsListString: any[] = [];

  columns.forEach((h:any) => {
    if (!columnsListString.includes(h.label)) {
      columnsList[h.key] = h.label;
      columnsListString.push(h.label);
    }
  });

  const columnsObjects = {};

  Object.assign(columnsObjects, columnsList);

  const processInfo: any[] = [];

  if (data !== undefined) {
    Object.entries(data)?.forEach((value: any) => {
      const processData: any[] = [];
      const processDataObject = {};
      Object.entries(value[1]).forEach(([keyV, valueV]: any) => {
        if (columnsList[keyV] !== undefined) {
          processData[columnsList[keyV]] = valueV;
        }
      });
      Object.assign(processDataObject, processData);
      processInfo.push(processDataObject);
    });
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(columnsList, {
    header: columnsListString.filter((h) => h.length > 1),
    skipHeader: true,
  });

  const wscols = undefined;
  ws['!cols'] = wscols;

  XLSX.utils.sheet_add_json(ws, processInfo, {
    header: columnsListString.filter((h) => h.length >= 2),
    skipHeader: false,
  });

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', numbers: 'true' });
  const dataExcel = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(dataExcel, fileName + fileExtension);
}
