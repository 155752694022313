import { useLocation } from 'react-router-dom';
import SummaryCalendar from './SummaryCalendar';
import SummaryGlobalPosition from './SummaryGlobalPosition';
import SummaryHome from './SummaryHome';
import SummaryTeam from './SummaryTeam';
import SummaryTeamGraphics from './SummaryTeamGraphics';
import SummaryDelegates from './SummaryDelegates';
import useCategoryInfo from '../../../hooks/useCategoryInfo';
import useYear from '../../../hooks/useYear';

export default function Summary() {
  const location = useLocation();
  const { year } = useYear();
  const { userCategory } = useCategoryInfo(year);

  switch (location.pathname) {
  case '/home/summary':
    return <SummaryHome />;
  case '/global-position/summary':
    return (userCategory?.userCategory?.id === 'DELEGADOS' ? <SummaryDelegates /> : <SummaryGlobalPosition />);
  case '/team/summary':
    return <SummaryTeam />;
  case '/team/summary-graphics':
    return <SummaryTeamGraphics />;
  case '/calendar/summary':
    return <SummaryCalendar />;
  case '/delegates/summary':
    return <SummaryDelegates />;
  default:
    return null;
  }
}
