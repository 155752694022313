import Theme from '../../../styles/Theme';
import {
  Card,
  CardBody,
  CardContent,
  CardFooter,
} from '../../atoms/Card/Card';
import IconScore from '../../atoms/Icon/IconScore';
import Typography from '../../atoms/Typography/Typography';

export default function Score({
  score,
  cols,
  maxWidth = '612px',
  rowHeight = '162px',
  margin = '0 auto',
}:any) {
  return (
    <Card
      background="transparent"
      display="flex"
      childs={cols}
      margin={margin}
      width={maxWidth}
      height={rowHeight}
      alignItems="stretch"
    >
      {score.map((data: any) => (
        <Card key={data.id} padding="0" radius="5px" display="flex">
          <CardBody>
            <CardContent display="flex" alignItems="center" flexDirection="column" padding="1.5rem 0 0">
              <IconScore type={data?.icon} color={Theme.fontColorSecondary} height="30px" width="30px" />
              <Typography color={Theme.colorSecondary} size="9px" margin="0.32rem auto -0.5rem auto" display="block">
                {data?.maintitle}
              </Typography>
              <Typography align="center" color={Theme.colorSecondary} size="16px" margin="0.5rem 0 1rem">
                {data?.title}
                <Typography color={Theme.colorSecondary} size="12px" margin="0 auto" display="block" type="span">
                  {data?.subtitle}
                </Typography>
              </Typography>
            </CardContent>
            <CardFooter background={Theme.colorSecondary3} radius="0 0 5px 5px" padding="0.75rem" display="flex" justifyContent="center" alignItems="center">
              <Typography color={Theme.colorWhite} size="20px" margin="0" weight="500" align="center">
                {data.score}
              </Typography>
            </CardFooter>
          </CardBody>
        </Card>
      ))}
    </Card>
  );
}
