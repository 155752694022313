import Styled, { css } from 'styled-components';
import Theme from '../../../styles/Theme';

const tab = css<{theme?: string}>`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${Theme.colorSecondary3};
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  line-height: 1.25;
  text-decoration: underline;
  flex: 1 1 0px;
  &.is-active,
  &.active {
    color: ${Theme.colorWhite};
    background-color: ${(props) => props.theme};
    border-radius: 10px;
    text-decoration: none;
  }
  @media screen and (min-width: 768px) {
    flex: 1 1 auto;
  }

  &.are-both-active {
    color: ${Theme.colorWhite};
    background-color: ${(props) => props.theme};
    border-radius: 10px 10px 0 0;
    text-decoration: none;
  }
  &.is-active-subtab {
    color: ${Theme.fontColorSecondary};
  }
`;

export const StyledTab = Styled.div<{theme?: string}>`
  ${tab}
  text-align: center;
`;

export const StyledTabButton = Styled.button<{theme?: string}>`
  ${tab}
`;

export const TabWrapper = Styled.ul`
  margin: 0 auto 2rem auto;
  max-width: 844px;
  width: 94%;
`;

export const TabContainer = Styled.ul`
  background-color: ${Theme.colorTertiary};
  border-radius: ${Theme.radius};
  padding: 5px;
  margin: 0;
  display: flex;
  overflow-x: auto;

  @media screen and (min-width: 768px) {
    overflow-x: initial;
  }
`;

export const SubtabContainer = Styled.ul<{theme?: string}>`
  background-color: ${Theme.colorSecondary2};
  border-top: 3px solid ${(props) => props.theme};
  border-radius: 0 0 5px 5px;
  padding: 6px;
  margin: -5px 0 0 0;
  display: flex;
  overflow-x: auto;

  @media screen and (min-width: 768px) {
    overflow-x: initial;
  }
`;

export const TabPanel = Styled.div`
  display: none;
  &.is-active-panel {
    display: block;
  }
`;

export const YearContainer = Styled.div`
  width: 94%;
  max-width: 1556px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

export const TabYear = Styled.div`
  background: ${Theme.colorTertiary};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 5px;
  width: fit-content;
  margin-left: auto;
`;

export const TabYearButton = Styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  width: 150px;

  &.active {
    background: ${Theme.colorPrimary};
    color: ${Theme.colorWhite};
  }
`;
