import { gql } from 'graphql-request';

const GET_GROUPING_LIST_GQL = gql`
query MyQuery {
  groupingList {
    grouping
  }
}
`;
export default GET_GROUPING_LIST_GQL;
