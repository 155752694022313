import { IIcon } from './Iicon';

export default function IconLinkedin({ color, height, width }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={color} height={height} width={width} viewBox="0 0 595.3 660.9">
      <path d="M240.9,5.1c-16,37.4-32.1,74.7-47.9,112.1c-1.4,3.4-2.2,7.3-2.2,10.9c-0.2,40.7-0.1,81.4-0.1,122.1c0,2.5,0,5,0,7.9 c-14.2,0-27.8,0-42.5,0c0-2.3,0-4.7,0-7.2c0-40.7,0.1-81.4-0.1-122.1c0-4.1-0.9-8.4-2.6-12.1c-15.8-35.1-32-70.1-47.9-105.2 c-0.9-2.1-1.5-4.3-2.3-6.4c15.2,0,30.4,0,45.6,0c8.8,28.5,17.6,57,26.5,85.5c0.4-0.1,0.8-0.1,1.3-0.2c8.5-28.4,17-56.8,25.5-85.3 C209.7,5.1,225.3,5.1,240.9,5.1z" />
      <path d="M298,653.6c-64.8,0-129.6,0.2-194.3-0.1c-34.3-0.1-62.8-12.9-83.3-41.2c-9.5-13.1-13.4-28-13.4-44 c-0.1-62.5-0.2-124.9,0-187.4c0.1-36.2,23-66.7,60.1-79.1c11.6-3.9,24.3-6.3,36.5-6.3c129.6-0.4,259.1-0.5,388.7-0.2 c34.2,0.1,62.8,13.1,83.3,41.4c9.4,13.1,13.4,28,13.4,44.1c0,62.5,0.2,124.9-0.1,187.4c-0.2,40.1-29.2,73.5-71.5,82.6 c-9,1.9-18.4,2.7-27.6,2.7C425.8,653.7,361.9,653.6,298,653.6z M334.1,575.7c6.7,4,13.4,8.9,20.8,12.3c19.1,9,35.7,2.8,42.9-17.1 c3.3-9.3,4.9-19.7,5.2-29.6c0.7-28.7,0.3-57.4,0.2-86.1c0-4.8-0.4-9.7-1.2-14.5c-3.8-21.3-22.4-32.6-42.9-25.5 c-7,2.4-13.5,6.3-21,9.9c0-21.9,0-43.5,0-65.5c-13.7,0-26.6,0-39.7,0c0,76.7,0,153,0,229.4c6,0,11.7,0,17.4,0 c4.6,0,10.5,1.4,13.6-0.8C332.3,586,332.6,579.9,334.1,575.7z M273.4,590.6c0-57,0-114,0-171c-13.3,0-26.1,0-39.1,0 c-0.1,2-0.4,3.6-0.4,5.3c0.1,38,0.2,76,0.3,114c0,2.7,0.2,5.5,0,8.2c-0.6,8.7-8.4,17-15.4,16.6c-5.8-0.3-9.8-7.4-9.8-16.9 c-0.1-40.3-0.3-80.6-0.4-120.9c0-2.1-0.2-4.1-0.3-6.1c-13.5,0-26.5,0-39.7,0c0,2.3,0,4.2,0,6.1c0.1,42,0,84,0.3,126 c0.1,8.2,0.6,16.6,2.8,24.4c1.3,4.7,5.5,9.5,9.7,12.2c7.8,5,17,4.3,25.8,2.4c13.3-2.9,24.6-9.2,34.1-20.7c0,7.5,0,13.7,0,20.5 C252.3,590.6,262.7,590.6,273.4,590.6z M530.2,509c-0.7-21.4-0.9-41.8-2.1-62.2c-0.6-10.4-3.6-20.5-13.1-26.9 c-18.3-12.3-38-13.6-57.9-5.5c-19.3,7.9-27.8,24.5-28.5,44.2c-1,27.6-1.3,55.3-0.2,82.8c1.4,34.5,28.7,56.1,62.5,49.9 c23.7-4.4,48.2-27.2,38-60.1c-9.6,0-19.2,0-29.4,0c0,6.3,0.1,12.1,0,18c-0.2,9.3-4.8,14.5-14,15.5c-3.5,0.4-7.2,0.4-10.7,0.1 c-9.2-0.8-14.2-5.9-14.4-15.2c-0.2-9.7-0.1-19.4-0.1-29.1c0-3.7,0-7.4,0-11.5C483.8,509,506.3,509,530.2,509z M176,359.8 c-43.1,0-85.8,0-128.5,0c0,9.9,0,19.3,0,29.3c13.8,0,27.2,0,41.2,0c0,67.6,0,134.6,0,202c13.4,0,26.1,0,39.7,0 c0-67,0-133.8,0-201.2c16.3,0,31.8,0,47.6,0C176,379.5,176,369.9,176,359.8z" />
      <path d="M436,76.2c11.9,0,23,0,34.4,0c0,59.1,0,117.9,0,176.9c-14.6,0-28.9,0-43.4,0c0-4.1,0-7.8,0-12.9c-3.2,3.5-5.5,6.3-8,8.9 c-14.1,14.3-40.7,8-46.1-11.4c-2.2-8-3.4-16.6-3.5-24.9c-0.3-44.1-0.2-88.2-0.1-132.3c0-1.2,0.2-2.5,0.3-4.1c12.4,0,24.5,0,37.2,0 c0,2.4,0,4.7,0,6.9c0,42.8,0,85.7,0,128.5c0,6.2,1.9,11.4,7.8,13.9c5.6,2.4,11.2,1.9,16.1-1.9c4.5-3.4,5.3-8.2,5.3-13.5 c-0.1-42.2,0-84.4,0-126.6C436,81.4,436,79.1,436,76.2z" />
      <path d="M236.4,164.9c1.1-18.1,1.6-35.4,3.2-52.6c3-32.6,28.5-46.1,56.5-43.3c29.7,3,46.6,21.4,47.3,52.3c0.7,27,0.5,54-0.1,81 c-0.3,13.3-2.7,26.4-10.7,37.8c-9.8,13.8-23.9,18.8-40,19.2c-6.7,0.2-13.4-1.1-20-2.2c-19.3-3.2-28.8-14.7-32.6-37.5 C236.9,201.4,237.4,182.6,236.4,164.9z M308.8,163.6c0-10.5,0.1-21.1,0-31.6c-0.1-6.3,0.1-12.7-0.6-18.9c-1-8.1-8.5-14.6-16.4-15.1 c-7-0.5-15.2,5-17.4,12.2c-1,3.4-1.3,7.1-1.3,10.6c-0.1,21.9-0.1,43.9-0.1,65.8c0,7.8-0.2,15.6,0.1,23.4 c0.2,8.8,5.1,15.7,12.4,18.1c11.7,3.9,23.1-4.8,23.3-18.2C309,194.4,308.8,179,308.8,163.6z" />
      <path d="M372.7,499.5c0,15.8-0.5,31.7,0.2,47.5c0.4,9.4-9.7,15.5-18,15.5c-10.5-0.1-17.7-6.3-17.8-15.5c0-32.1-0.1-64.1,0-96.2 c0-9.1,7.5-15.6,17.8-15.6c10.2,0,17.7,6.5,17.7,15.6C372.8,467,372.7,483.3,372.7,499.5z" />
      <path d="M460.4,479.5c0.4-8.2,0.2-15.8,1.1-23.3c1.3-11.3,16.2-17.3,28.3-11.8c6.4,2.9,9.8,7.6,9.7,14.8c-0.1,6.7,0,13.4,0,20.4 C485.7,479.5,473.4,479.5,460.4,479.5z" />
    </svg>
  );
}
