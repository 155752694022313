import bgConventionCalendar from '../../../assets/images/slide04.jpg';
import bgDelegate from '../../../assets/images/bgDelegate.jpg';
import bgDelegate2023 from '../../../assets/images/argentina_2024.jpg';
import bgDelegate2024 from '../../../assets/images/bgDelegate2024.jpg';

export const dataConventionCalendar = [
  {
    id: 0,
    title: '¡Ya queda menos para ver la India!',
    subtitle: 'Convención 2022',
    image: bgConventionCalendar,
    cta: [
      {
        type: 'link',
        name: '',
        url: '',
      },
      {
        type: 'button',
        name: 'Descargar bases',
        url: '/',
      },
    ],
  },
];

export const dataConventionCard = [
  {
    id: 0,
    title: 'Grupo de DELEGADOS',
    paragraph: 'Consulta aquí el grupo de delegados de tu DGT',
    linkUrl: '/',
    cta: 'Descargar',
    ctaUrl: '/',
    image: { 2022: bgDelegate, 2023: bgDelegate2023, 2024: bgDelegate2024 },
  },
];

export const dgtLinks:any = {
  2022: {
    '01': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+1+ICM.xlsx',
    '02': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupo_Delegados_DGT_2_ICM.xlsx',
    '03': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupo_Delegados_DGT_3_ICM.xlsx',
    '04': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupo_Delegados_DGT_4_ICM.xlsx',
    '05': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupo_Delegados_DGT_5_ICM.xlsx',
    '06': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupo_Delegados_DGT_6_ICM.xlsx',
    '07': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupo_Delegados_DGT_7_ICM.xlsx',
    '08': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos%2BDelegados%2BDGT%2B8%2BICM.xlsx',
    '09': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupo_Delegados_DGT_9_ICM.xlsx',
  },
  2023: {
    '01': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+1+Portal.xlsx',
    '02': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+2+Portal.xlsx',
    '03': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+3+Portal.xlsx',
    '04': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+4+Portal.xlsx',
    '05': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+5+Portal.xlsx',
    '06': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+6+Portal.xlsx',
    '07': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+7+Portal.xlsx',
    '08': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+8+Portal.xlsx',
    '09': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+DGT+9+Portal.xlsx',
    general: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/Grupos+Delegados+TOTAL+Portal.xlsx',
  },
  2024: {
    '01': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+1+Portal.xlsx',
    '02': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+2+Portal.xlsx',
    '03': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+3+Portal.xlsx',
    '04': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+4+Portal.xlsx',
    '05': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+5+Portal.xlsx',
    '06': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+6+Portal.xlsx',
    '07': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+7+Portal.xlsx',
    '08': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+8+Portal.xlsx',
    '09': 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+DGT+9+Portal.xlsx',
    general: 'https://adlp-digital.s3.eu-west-1.amazonaws.com/Mapfre/map015/Convencion/2024/Grupos+Delegados+Central+Portal.xlsx',
  },
};
