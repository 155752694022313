import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const StyledPagination = Styled.div`
  display: flex;
  color: ${Theme.colorDisabled};
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 2rem auto auto auto;
`;

export const StyledPaginationButton = Styled.button<{disabled?: boolean, active?: boolean, onClick?: () => void, className?: string, labe?: string}>`
  appearance: none;
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  font-size: 14px;
  font-weight: 500;
  &:first-child,
  &:last-child {
    color: ${Theme.colorSecondary};
  }
  &[disabled] {
    color: ${Theme.colorDisabled};
  }
  ${(props) => (props.active ? `color: ${Theme.colorSecondary};font-weight: 600;` : '')}
`;

export const StyledResultsPerPage = Styled.div``;

export const StyledSelect = Styled.select``;
