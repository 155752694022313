import ScrollContainer from 'react-indiana-drag-scroll';
import {
  StyledBannerCard,
  StyledBannerCarousel,
  StyledBannerImage,
  StyledBannerImageWrapper,
  StyledBannerItem,
  StyledBannerList,
  StyledBannerOverlay,
  StyledFooterBanner,
  StyledBannerTitle,
  StyledBannerWrapLinks,
} from '../../organisms/Home/StyledHome';
import { Banner, BannerData } from '../../../interfaces/Banners';
import useYear from '../../../hooks/useYear';
import { Card } from '../Card/Card';
import LinkNavigate from '../Link/LinkNavigate';

type BannerProps = {
  data: Array<BannerData>;
}

export default function BannerList(props: BannerProps) {
  const { data } = props;
  const { year } = useYear();
  const dataFiltered = data
    ?.filter((item: BannerData) => item.year === year)[0]
    ?.info;

  return (
    <StyledBannerCarousel>
      <StyledBannerList>
        <ScrollContainer className="container">
          {dataFiltered?.map((item: Banner, index: any) => (
            <StyledBannerItem key={item?.id} even={index % 2 === 0}>
              <StyledBannerCard>
                <StyledBannerImageWrapper>
                  <StyledBannerImage src={item?.imageUrl} alt={item?.title} />
                  <StyledBannerOverlay className="overlay" />
                </StyledBannerImageWrapper>
                <StyledFooterBanner className="footer-banner">
                  <StyledBannerTitle className="title">
                    {item?.title}
                  </StyledBannerTitle>
                  <StyledBannerWrapLinks className="links">
                    {item?.bannerFileUrl && item?.bannerFileName && item?.bannerFileUrl !== '#' && (
                      <Card background="transparent" display="flex" flexDirection="column">
                        <a href={`${item?.bannerFileUrl}`} target="_blank" className="card__link" rel="noreferrer">
                          {item?.bannerFileName}
                        </a>
                      </Card>
                    )}
                    <Card background="transparent" display="flex" flexDirection="column">
                      <LinkNavigate
                        customLink={`${item?.customLink}`}
                        customLinkName={item?.customLinkName ? item?.customLinkName : ''}
                        secondaryCustomLink={item?.secondaryCustomLink}
                        secondaryCustomLinkName={item.secondaryCustomLinkName ? item.secondaryCustomLinkName : 'Seguimiento planes'}
                      />
                    </Card>
                  </StyledBannerWrapLinks>
                </StyledFooterBanner>
              </StyledBannerCard>
            </StyledBannerItem>
          ))}
        </ScrollContainer>
      </StyledBannerList>
    </StyledBannerCarousel>
  );
}
