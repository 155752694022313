import Styled, { css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import Theme from '../../../styles/Theme';

const StyledLinkBase = css<{color?: string, size?:string, weight?:string, padding?:string, underline?:string, border?: string, background?: string}>`
  background-color: ${(props) => props.background};
  border-radius : ${(props) => props.border};
  color: ${(props) => props.color || Theme.fontColor};
  text-decoration: ${(props) => props.underline || 'none'};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  padding: ${(props) => props.padding};
  display: inline-block;
`;

export const StyledLink = Styled(Link)<{color?: string, size?:string, weight?:string, padding?:string, underline?:string, background?: string, border?: string}>`
  ${StyledLinkBase}
`;

export const StyledNavLink = Styled(NavLink)<{color?: string, size?:string, weight?:string, padding?:string, underline?: string, ref?:any, border?: string}>`
  ${StyledLinkBase};
`;

export const LinkAnchor = Styled.a<{color?: string, size?:string, weight?:string, padding?:string, underline?: string, ref?:any, border?: string}>`
  ${StyledLinkBase};
`;

export const StyledLinkTo = Styled(Link)<{type?:string, size?:number, mb?:string, color?: string, border?: string}>`
  appearance: none;
  background-color: ${(props) => props.color};
  border: 0;
  float: right;
  max-width: 30%;
  text-decoration: none;
  border-radius: ${Theme.radius};
  color: ${Theme.colorWhite};
  cursor: pointer;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: ${(props) => props.mb};
  line-height: 1.25;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  @media screen and (min-width: 768px) {
    min-width: ${(props) => props.size}px;

    &:hover {
      box-shadow: ${Theme.shadow};
      transform: translateY(-2px);
    }
  }
`;
