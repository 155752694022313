import LoginContainer from './StyledLogin';
import OauthLogin from '../../organisms/Login/OauthLogin';

export default function Login() {
  return (
    <LoginContainer>
      <OauthLogin />
    </LoginContainer>
  );
}
