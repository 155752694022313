import LinkTo from '../components/atoms/Link/LinkTo';
import { PlanByCode } from '../interfaces/PlanByCode.d';
import { Results } from '../interfaces/Results.d';
import Theme from '../styles/Theme';

type PlanByCodeProps = {
  planByCode: PlanByCode
}

export default function useUrlDetail(id: string, result: Results, planByCode: PlanByCodeProps) {
  return planByCode?.planByCode?.grouping?.name !== 'CONVENCION_RANKING'
    ? (
      <LinkTo
        to={`/team/convention/resumen/${result.id}/detail/${result.firstName}-${result.lastName}?grouping=${planByCode?.planByCode?.grouping?.name}&code=${id}&participantId=${result.userId}`}
        color={Theme.colorPrimary}
        style={{ textAlign: 'center', display: 'block' }}
      >
        Ver detalle
      </LinkTo>
    )
    : '';
}
