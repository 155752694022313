const replaceAt = (index:number, str:string, replacement:string) => {
  if (index > 0) {
    return str.substring(0, index) + replacement + str.substring(index + replacement.length);
  }
  return str;
};

const processTableNumber = (value: any, decimals: boolean) => {
  let stringValue:string = `${value}`;

  if (typeof stringValue === 'string') {
    stringValue = stringValue.replaceAll('"', '');
  }

  if (stringValue.includes('undefined')) {
    return stringValue.replaceAll('undefined', '');
  }

  if (stringValue === null || stringValue === 'null') {
    return '';
  }

  if (!Number.isNaN(stringValue) && stringValue.includes('%')) {
    return stringValue;
  }
  if (/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(stringValue)) {
    return stringValue;
  }

  if (/^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/.test(stringValue)) {
    return stringValue;
  }

  if (/[a-zA-Z]/g.test(stringValue)) {
    return stringValue;
  }

  if (Number.isInteger(+stringValue)) {
    return stringValue;
  }

  if (typeof stringValue === 'string') {
    stringValue = replaceAt(stringValue.lastIndexOf(','), stringValue, '-');
    stringValue = replaceAt(stringValue.lastIndexOf('.'), stringValue, '-');
  }

  if (typeof stringValue === 'string') {
    stringValue = stringValue.replaceAll('.', '');
  }

  if (typeof stringValue === 'string') {
    stringValue = stringValue.replaceAll(',', '');
  }

  if (typeof stringValue === 'string') {
    stringValue = replaceAt(stringValue.lastIndexOf('-'), stringValue, ':');
  }

  if (typeof stringValue === 'string' && typeof value !== 'number') {
    stringValue = stringValue.replaceAll('-', '');
  }

  if (typeof stringValue === 'string') {
    stringValue = stringValue.replaceAll(':', '.');
  }

  if (!Number.isInteger(parseFloat(stringValue).toFixed(2)) || decimals) {
    // stringValue = Number.parseFloat(stringValue).toFixed(2);
    // stringValue = stringValue.replaceAll('.', '-');
    // stringValue = stringValue.replaceAll(',', '.');
    // stringValue = stringValue.replaceAll('-', ',');
    // stringValue = stringValue.replaceAll(',', '.');
    // stringValue = stringValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number.parseFloat(stringValue));
  }

  if (Number.isInteger(parseFloat(stringValue).toFixed(2)) || !decimals) {
    stringValue = Number.parseInt(stringValue, 10).toString();
    return stringValue;
  }

  return stringValue;
};

export default function useTableNumber(value: any, decimals: boolean = false) {
  if (typeof value === 'object') {
    let array: any[] = [];
    if (Array.isArray(value)) {
      array = value.map((v) => {
        let item: any = null;
        if (typeof v !== 'object') {
          item = processTableNumber(v, true);
        } else {
          item = { ...v };
          Object.entries(v).forEach(([key, k]) => {
            item[key] = processTableNumber(k, true);
          });
        }
        return item;
      });
      return array;
    }
    if (value !== null && value !== undefined) {
      const processValue = Object.entries(value).map((d:any) => ({ ...d }));
      processValue.forEach((item: any, index: number) => {
        if (typeof processValue[index][1] === 'object') {
          Object.entries(processValue[index][1]).forEach(([key, val]: any) => {
            Object.entries(val).forEach(([keyInt, valInt]: any) => {
              processValue[index][1][key][keyInt] = processTableNumber(valInt, true);
            });
          });
        }
      });
    }
    return value;
  }

  const number = processTableNumber(value, decimals);
  return number;
}
