import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const CalendarItemShadow = Styled.div`
  box-shadow: 6px 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
`;

export const CalendarItem = Styled.div`
  border-radius: 20px;
  color: ${Theme.colorWhite};
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 320px;
  display: flex;
  align-items: flex-end;
  padding: 2rem;

  a {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -1rem;
    transition: position 0.3s ease, bottom 0.3s ease;
  }

  &:after {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &:hover {
    &:after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.82) 100%);
    }

    a {
      opacity: 1;
      visibility: visible;
      position: relative;
      bottom: 0;
    }

    h2, p {
      transform: translateY(-1rem);
    }
  }
`;

export const CalendarImage = Styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  transform: translate(-50%,-50%);
  object-fit: cover;
`;

export const CalendarContent = Styled.div`
  position: relative;
  z-index: 3;
`;

export const CalendarTitle = Styled.h2`
  font-family: 'Fixture Condensed Medium',sans-serif;
  font-size: 46px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0 0 0.62rem;
  transition: all 0.3s ease;
`;

export const CalendarParagraph = Styled.p`
  font-size: 16px;
  line-height: 1.25;
  margin: 0;
  transition: all 0.3s ease;
`;
