import Styled from 'styled-components';
import { Form } from 'formik';
import { Link } from 'react-router-dom';
import Theme from '../../../styles/Theme';

export const Container = Styled.div`
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    background-image: url(${Theme.loginImage});
  }
`;

export const FormContainer = Styled.div`
  background: ${Theme.colorWhite};
  border-radius: ${Theme.radius};
  border: 1px solid ${Theme.colorSecondary3};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  padding: 67px 2rem;
  margin-bottom: 73px;
  width: 90%;

  label {
    margin-bottom: 2rem;
    width: fit-content;
  }

  button {
    max-width: 262px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    padding: 67px 95px;
  }
`;

export const FormLogin = Styled(Form)`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  width: 94%;
`;

export const FormLink = Styled(Link)`
  color: ${Theme.colorPrimary};
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  text-decoration: underline;
  text-align: right;
  margin-bottom: 10px;
`;

export default { FormContainer, FormLogin, FormLink };
