import { useQuery } from 'react-query';
import GET_SUMMARY_CONVENTION_GQL from '../graphql/queries/getSummaryConventionGql';
import { createGraphQLClient } from '../graphql/graphqlClient';

export interface IData {
  userId?: string,
  grouping?: string,
  timeframe?: string,
  startDate?: string,
  endDate?: string,
  myTeam?: boolean,
}

export default function usePlanSummaryConvention(options: IData) {
  const graphQLClient = createGraphQLClient();

  return useQuery(
    ['SummaryConvention', options],
    async () => graphQLClient && graphQLClient.request(GET_SUMMARY_CONVENTION_GQL, options),
  );
}
