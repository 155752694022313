import { gql } from 'graphql-request';

const GET_SUMMARY_CONVENTION_GQL = gql`
  query myQuery($timeframe: String, $startDate: String, $myTeam: Boolean, $grouping: String, $subgrouping: String, $endDate: String, $userId: ID, $year: Int) {
    summaryConvention(timeframe: $timeframe, startDate: $startDate, myTeam: $myTeam, grouping: $grouping, subgrouping: $subgrouping, endDate: $endDate, userId: $userId, year: $year) {
      participantsCount
      participantsDgtCount
    }
  }
`;

export default GET_SUMMARY_CONVENTION_GQL;
