import { gql } from 'graphql-request';

const GET_VIDEOS = gql`
  query MyQuery($year: Int) {
    videoHome(year: $year) {
      nodes {
        year
        urlVimeo
        title
        thumbnailSmall
        thumbnailMedium
        thumbnailLarge
        id
      }
    }
  }
`;
export default GET_VIDEOS;
