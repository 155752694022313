import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useHexToRgb from '../../../hooks/useHexToRgb';
import useNumberFormat from '../../../hooks/useNumberFormat';
import Typography from '../Typography/Typography';
import {
  StyledCharBar,
  StyledChartPie,
  StyledNumberLine,
} from './StyledGraphic';

interface IChart {
  label: string,
  theme: string,
  score: number,
  total: number,
}

interface IChartBar {
  title: string,
  theme: string,
  scoresTemporalities: any,
}

interface INumberLine {
  theme: string,
  score: number,
  total: number,
}

function ChartProgressBar({
  label, theme, score, total,
}: IChart) {
  return (
    total !== undefined ? (
      <div className="graphic graphic--progressbar">
        <div className="graphic__title">{label}</div>
        <div className="graphic__wrapper">
          <div className="graphic__total">
            <div
              className="graphic__progress"
              style={{
                background: theme,
                width: `${((score ?? 0) / (total ?? 0)) * 100}%`,
              }}
            />
          </div>
          <span
            className="graphic__score"
            style={{ color: theme }}
          >
            {useNumberFormat(score)}
          </span>
        </div>
      </div>
    ) : (
      <div className="graphic graphic--progressbar">
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div className="graphic__title">{label}</div>
          <span
            className="graphic__score"
            style={{ color: theme }}
          >
            {useNumberFormat(score)}
          </span>
        </div>
      </div>
    )
  );
}

function ChartHalfCircle({
  label, theme, score, total,
}: IChart) {
  return (
    <div className="graphic">
      <span
        className="graphic__score"
        style={{ color: theme }}
      >
        {`${score} / ${total}`}
      </span>
      <div className="graphic__total">
        <div
          className="graphic__progress"
          style={{
            backgroundImage: `conic-gradient(${theme}, ${theme} calc(${((score ?? 0) / (total ?? 0)) * 100}% / 2), rgba(156, 175, 187, 0.5) 0)`,
          }}
        />
      </div>
      <div className="graphic__title">{label}</div>
    </div>
  );
}

function NumberLine({ theme, score, total }: INumberLine) {
  let pointsLeft = score >= 1 ? score / 10 : 1;
  let pointsRight = total > 1 ? total / 10 : 1;
  pointsLeft = Math.ceil(pointsLeft);
  pointsRight = Math.ceil(pointsRight);

  if (pointsRight > pointsLeft) {
    pointsRight -= pointsLeft;
  }

  let stringPointsLeft = '.';
  let stringPointsRight = '.';

  let counter = 1;

  do {
    stringPointsLeft += '.';
    counter += 1;
  } while (counter < pointsLeft);

  do {
    stringPointsRight += '.';
    counter += 1;
  } while (counter < pointsRight);
  return (
    <StyledNumberLine>
      <Typography
        color={theme}
        display="flex"
        margin="0"
        size="24px"
        type="h6"
      >
        {`1 ${stringPointsLeft}`}
      </Typography>
      { score > 1 && (
        <Typography
          color={theme}
          display="flex"
          margin="0"
          size="72px"
          type="h2"
        >
          {score}
        </Typography>
      ) }
      <Typography
        color={theme}
        display="flex"
        margin="0"
        size="24px"
        type="h6"
      >
        {`${stringPointsRight} ${total}`}
      </Typography>
    </StyledNumberLine>
  );
}

function ChartScale({
  label, theme, score, total,
}: IChart) {
  return (
    <div className="graphic graphic--scale" data-label={label} data-score={score} data-total={total}>
      <svg width="100%" height="168" viewBox="0 0 448 168" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M445 3C259.291 113.607 103.735 134.962 3 143.5V166H445V3Z" fill="url(#paint0_linear_5760_10716)" />
        <path d="M3 144C103.735 135.42 259.291 114.148 445 3" stroke={theme} strokeWidth="5" strokeLinecap="round" />
        <path d="M248 5C248 2.23858 250.239 0 253 0H313C315.761 0 318 2.23858 318 5V38.7872C318 41.5487 315.761 43.7872 313 43.7872H287.773L282.735 49L277.697 43.7872H253C250.239 43.7872 248 41.5487 248 38.7872V5Z" fill={theme} />
        <path d="M278.864 30L285.728 14.634H276.758V11.8H289.16V14.166L282.322 30H278.864Z" fill="white" />
        <path opacity="0.6" d="M285 82.5V80.5H281V82.5H285ZM281 165.5V167.5H285V165.5H281ZM281 85.9583V87.9583H285V85.9583H281ZM285 92.875V90.875H281V92.875H285ZM281 99.7917V101.792H285V99.7917H281ZM285 106.708V104.708H281V106.708H285ZM281 113.625V115.625H285V113.625H281ZM285 120.542V118.542H281V120.542H285ZM281 127.458V129.458H285V127.458H281ZM285 134.375V132.375H281V134.375H285ZM281 141.292V143.292H285V141.292H281ZM285 148.208V146.208H281V148.208H285ZM281 155.125V157.125H285V155.125H281ZM285 162.042V160.042H281V162.042H285ZM281 82.5V85.9583H285V82.5H281ZM281 92.875V99.7917H285V92.875H281ZM281 106.708V113.625H285V106.708H281ZM281 120.542V127.458H285V120.542H281ZM281 134.375V141.292H285V134.375H281ZM281 148.208V155.125H285V148.208H281ZM281 162.042V165.5H285V162.042H281Z" fill={theme} />
        <defs>
          <linearGradient id="paint0_linear_5760_10716" x1="195.1" y1="30.9429" x2="214.804" y2="142.537" gradientUnits="userSpaceOnUse">
            <stop stopColor={theme} />
            <stop offset="1" stopColor="#E8EBED" stopOpacity="0.31" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

function Pie({
  label, theme, score, total, percentage, showData,
}: any) {
  return (
    <StyledChartPie
      label={label}
      theme={theme}
      percentage={percentage}
    >
      {showData && (
        <div>
          <b>{useNumberFormat(score)}</b>
          <span>{useNumberFormat(total)}</span>
        </div>
      )}
    </StyledChartPie>
  );
}

function ChartPie({
  label, theme, score, total,
}: IChart) {
  const percentage = (score / total) * 100;
  return (
    <Pie
      label={label}
      theme={theme}
      score={score}
      total={total}
      percentage={percentage}
    />
  );
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function ChartBar({
  title, theme, scoresTemporalities,
}: IChartBar) {
  const labels = ['T1', 'T2', 'T3', 'T4'];
  const score = scoresTemporalities.map((item: any) => (item.score !== undefined ? item.score : 0));

  const data: any = {
    labels,
    datasets: [
      {
        label: 'Puntos',
        data: score,
        backgroundColor: useHexToRgb(theme),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
  };

  return (
    <StyledCharBar>
      <Bar options={options} data={data} />
    </StyledCharBar>
  );
}

export default function Graphic({
  label, score, total, type, theme,
  scoresTemporalities, title,
}: any) {
  switch (type) {
  case 'progressbar':
    return <ChartProgressBar label={label} theme={theme} score={score} total={total} />;
  case 'half-circle':
    return <ChartHalfCircle label={label} theme={theme} score={score} total={total} />;
  case 'number-line':
    return <NumberLine score={score} total={total} theme={theme} />;
  case 'scale':
    return <ChartScale label={label} theme={theme} score={score} total={total} />;
  case 'pie':
    return <ChartPie label={label} theme={theme} score={score} total={total} />;
  case 'bar':
    return <ChartBar title={title} theme={theme} scoresTemporalities={scoresTemporalities} />;
  default:
    return null;
  }
}
