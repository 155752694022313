export default function useFilterCsvColumns(csvColumns: any, csvData: any) {
  // Filter not wanted columns
  // Filtering columns where have not data in any of their rows
  // Filter columns which have to be hidden because of some conditions
  const filteredKeys:any = [];
  const puestoDgt = '00168';

  if (csvColumns.length !== 0 && csvData.length !== 0) {
    let filterCsvColumns = csvColumns.filter(
      (e:any) => e?.key !== 'resultId' && e?.key !== 'puestoId' && e?.key !== 'id',
    );
    const puestos = csvData.map(
      (e:any) => e.puestoId,
    ).filter((item:any, i:any, ar:any) => ar.indexOf(item) === i);

    if (puestos.length === 1 && puestos.includes(puestoDgt)) {
      filterCsvColumns = filterCsvColumns.filter((item:any) => item?.key !== 'denomination' && item?.key !== 'nif');
    }

    const hashEmptyKeys:any = {};
    csvData.map(
      (dt:any) => {
        Object.keys(dt).map((e:any) => {
          if (hashEmptyKeys[e]) {
            hashEmptyKeys[e].push(dt[e]);
          } else {
            hashEmptyKeys[e] = [];
          }
          return hashEmptyKeys;
        });
        return hashEmptyKeys;
      },
    );

    Object.keys(hashEmptyKeys).map((e:any) => {
      const groupedValues:any = Object.values(hashEmptyKeys[e]).filter(
        (v, i, a) => a.indexOf(v) === i,
      );
      if (groupedValues.length !== 1 || (groupedValues.length === 1 && !groupedValues.includes(null) && !groupedValues.includes(''))) {
        filteredKeys.push(e);
      }
      return filteredKeys;
    });

    filterCsvColumns = filterCsvColumns.filter((e:any) => filteredKeys.includes(e.key) && e.key !== 'dataDate');

    return filterCsvColumns;
  }
  return [];
}
