import { gql } from 'graphql-request';

const USER_RESULTS = gql`
  query userResults($userId: ID, $planStatus: String, $planType: String, $year: Int) {
    userResults(userId: $userId, planStatus: $planStatus, planType: $planType, year: $year) {
      planCode
      planStatus
      planDescription
      od
      id
      dt
      dgt
      dataDate
      basesUrl
      amount
      nif
      planName
      planType
      win
    }
  }
`;
export default USER_RESULTS;
