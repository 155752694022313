import { gql } from 'graphql-request';

const GET_EXCLUDE_POLICY_GQL = gql`
query getExcludePolicy($planCode: String!, $userId: ID!) {
  excludedPolicy(planCode: $planCode, userId: $userId) {
    id
    amount
    createdAt
    desLinNegocio
    importId
    keyCode
    planId
    policy
    reason
    updatedAt
    userId
  }
}`;

export default GET_EXCLUDE_POLICY_GQL;
