import { IIcon } from './Iicon';

export default function IconInstagram({ color, height, width }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill={color} height={height} width={width} viewBox="0 0 419 419">
      <path d="M5,310.8c0-67.1,0-134.1,0-201.2c0.2-1.3,0.5-2.6,0.7-3.9c1.5-7.3,2.4-14.8,4.7-21.8C26.2,36.4,67.8,6,117.6,5.8 c61.2-0.2,122.4-0.1,183.6,0c19.4,0,37.9,4.3,54.6,14.1c38,22.3,57.7,55.7,57.9,99.8c0.4,60.4,0.2,120.8,0,181.2 c0,7.5-0.4,15.3-2.1,22.6c-10.2,45.8-38.1,75.2-83.3,87.8c-6,1.7-12.2,2.3-18.4,3.4c-67.1,0-134.2,0-201.3,0 c-4.2-0.7-8.4-1.1-12.5-2c-45.8-10.4-75.3-38.2-87.9-83.4C6.7,323.2,6.1,316.9,5,310.8z M209.3,98.1c-62,0.4-111.9,50.3-111.9,112 c0,61.8,50.7,112.4,112.3,112.1c62-0.4,111.9-50.3,111.9-112C321.6,148.4,270.8,97.8,209.3,98.1z M362.3,91.4c0-18.7-15.3-34-34-34 c-18.6,0-34.1,15.4-34.1,33.9c0,18.5,15.6,34,34.1,34.1C346.8,125.5,362.2,110,362.3,91.4z" />
      <path d="M297.4,210.4c-0.2,48.6-39.8,88-88.3,87.7c-48.5-0.3-87.7-39.7-87.5-88.1c0.2-48.6,39.8-88,88.3-87.7 C258.4,122.6,297.5,162,297.4,210.4z" />
      <path d="M338.1,91.3c0,5.4-4.5,10-9.8,9.9c-5.3,0-9.9-4.8-9.8-10c0.1-5.2,4.5-9.6,9.7-9.7C333.6,81.5,338.1,85.9,338.1,91.3z" />
    </svg>
  );
}
