import { gql } from 'graphql-request';

const GET_TEAM_TIMEFRAME_RESULTS_GQL = gql`
query MyQuery($grouping: String, $subgrouping: String, $myTeam: Boolean, $type: String!, $year: Int, $status: String, $timeframe: String) {
  summaryTeamChartByTimeframe(type: $type, myTeam: $myTeam, grouping: $grouping, subgrouping: $subgrouping, year: $year, status: $status, timeframe: $timeframe) {
    participantsCount
    totalUserMetCount
    totalUserWinCount
    totalAmount
    totalTreboles
  }
}
`;

export default GET_TEAM_TIMEFRAME_RESULTS_GQL;
