import { useState, memo } from 'react';
import StyledSwitch from './StyledSwitch';

export default memo((props: any) => {
  const { checked, onChange, name } = props;
  const [switchState, setSwitchState] = useState(checked);
  function handleOnChange() {
    onChange();
    setSwitchState(!switchState);
  }
  return (
    <StyledSwitch htmlFor={name} checked={switchState}>
      <input
        id={name}
        type="checkbox"
        name={name}
        checked={switchState}
        onChange={handleOnChange}
      />
    </StyledSwitch>
  );
});
