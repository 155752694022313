import { gql } from 'graphql-request';

const GET_TERRITORY_USERS_GQL = gql`
query MyQuery($dgt: String, $dt: String, $od: String, $year: Int, $planType: String, $planStatus: String, $category: String) {
  dropdownTerritoriesUsers(dgt: $dgt, dt: $dt, od: $od, year: $year, planType: $planType, planStatus: $planStatus, category: $category) {
    arrayUsersData
    arrayPlanTypes
    arrayPlanStatus
    arrayOds
    arrayDtsOds
    arrayDts
    arrayDgtsDtsOds
    arrayDgts
    arrayCategories
    arrayYears
  }
}
`;
export default GET_TERRITORY_USERS_GQL;
