import { gql } from 'graphql-request';

const GET_DELEGATE_PLAN_DETAIL = gql`
  query MyQuery($year: Int, $grouping: String, $subgrouping: String, $timeframe: String, $startD: String, $endD: String, $status: String) {
    delegatePlanDetail(year: $year, grouping: $grouping, subgrouping: $subgrouping, timeframe: $timeframe, startD: $startD, endD: $endD, status: $status) {
      campaigns {
        nodes {
          name
          prize
          win
        }
      }
      conventions {
        nodes {
          rankingGeneral
          rankingDgt
          name
          met
          insurance
        }
      }
      rappels {
        nodes {
          win
          state
          name
          amount
        }
      }
    }
  }
`;
export default GET_DELEGATE_PLAN_DETAIL;
