import { memo } from 'react';
import {
  StyledTab,
  TabWrapper,
  TabContainer,
  SubtabContainer,
} from './StyledTabs';

export interface TabParams {
  onChangeTab: Function;
  onChangeSubtab?: Function;
  tabs: TabList[];
  activeTab: string;
  activeSubtab?: string;
  theme?: string;
}
export interface TabList {
  id: string;
  label: string;
  subgroupings?: TabList[];
}

function Tabs({
  tabs, onChangeTab, activeTab, onChangeSubtab, activeSubtab, theme,
}:TabParams) {
  const completeActiveTab = tabs?.find((tab: any) => tab.id === activeTab);
  return (
    <section>
      <TabWrapper>
        <>
          <TabContainer>
            {tabs.map((currentTab) => (
              <StyledTab
                onClick={() => (onChangeTab(currentTab.id))}
                key={currentTab.id}
                // eslint-disable-next-line no-nested-ternary
                className={(activeTab === currentTab.id && currentTab.subgroupings) ? 'are-both-active' : (activeTab === currentTab.id ? 'is-active' : '')}
                data-target={`#${currentTab.id}`}
                theme={theme}
              >
                {currentTab.label}
              </StyledTab>
            ))}
          </TabContainer>
          {completeActiveTab?.subgroupings && onChangeSubtab && (
            <SubtabContainer theme={theme}>
              {completeActiveTab?.subgroupings.map((currentSubtab) => (
                <StyledTab
                  onClick={() => (onChangeSubtab(currentSubtab.id))}
                  key={currentSubtab.id}
                  className={`${activeSubtab === currentSubtab.id ? 'is-active-subtab' : ''}`}
                  data-target={`#${currentSubtab.id}`}
                  theme={theme}
                >
                  {currentSubtab.label}
                </StyledTab>
              ))}
            </SubtabContainer>
          )}
        </>
      </TabWrapper>
    </section>
  );
}

Tabs.defaultProps = {
  theme: '',
  activeSubtab: '',
  onChangeSubtab: undefined,
};

export default memo(Tabs);
