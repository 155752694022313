import {
  StyledFooter,
  StyledFooterContainer,
  SocialList,
  SocialLink,
  Copyright,
} from './StyledFooter';
import dataSocialLinks from './dataSocialLinks';
import IconSocial from '../../atoms/Icon/IconSocial';
import Container from '../../atoms/Container/Container';

export default function Footer() {
  return (
    <StyledFooter>
      <Container>
        <StyledFooterContainer>
          <Copyright>
            MAPFRE Plataforma de incentivos ©
            {new Date().getFullYear()}
            .
            Todos los derechos reservados
          </Copyright>
          <SocialList>
            {
              dataSocialLinks.map((social) => (
                <li key={social.type}>
                  <SocialLink href={social.href} title={social.title} target="_blank" rel="nofollow noreferrer">
                    <IconSocial
                      type={social.type}
                      color={social.color}
                      height={social.height}
                      width={social.width}
                    />
                  </SocialLink>
                </li>
              ))
            }
          </SocialList>
        </StyledFooterContainer>
      </Container>

    </StyledFooter>
  );
}
