import IGrid from './IGrid';
import StyledGrid from './StyledGrid';

export default function Grid({
  bg,
  columnsMs,
  columnsLg,
  children,
  childDimensionsMs,
  childDimensionsLg,
  rowsMs,
  rowsLg,
  spaceBetween,
  regular,
  rowHeight,
  margin,
  alignItems,
  maxWidth,
  minWidth,
  width,
  className,
  minChildWidth,
  ...otherProps
}
  : IGrid) {
  return (
    <StyledGrid
      bg={bg}
      columnsMs={columnsMs}
      columnsLg={columnsLg}
      childDimensionsMs={childDimensionsMs}
      childDimensionsLg={childDimensionsLg}
      rowsMs={rowsMs}
      rowsLg={rowsLg}
      spaceBetween={spaceBetween}
      regular={regular}
      margin={margin}
      rowHeight={rowHeight}
      maxWidth={maxWidth}
      minWidth={maxWidth}
      className={className}
      alignItems={alignItems}
      minChildWidth={minChildWidth}
      width={width}
      {...otherProps}
    >
      {children}
    </StyledGrid>
  );
}
