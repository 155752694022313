import { Column } from '../interfaces/Column.d';

export default function useUniqueColumns(columns: Column[]) {
  const uniqueColumns = columns.reduce((acc: Column[], current: Column) => {
    const x = acc.find((item: Column) => item.Header === current.Header);

    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  return { uniqueColumns };
}
