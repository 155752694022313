import { gql } from 'graphql-request';

const GET_MY_INFO_GQL = gql`
  query me{
    me{
      id
      firstName
      lastName
      nif
      role {
        name
      }
    }
  }
`;

export default GET_MY_INFO_GQL;
