import { IIcon } from './Iicon';

export default function IconDownload({
  color, height, width,
}: IIcon) {
  return (
    <svg width={width} height={height} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.33333 6.25C9.33333 6.17708 9.31146 6.11719 9.26771 6.07031C9.22396 6.02344 9.16806 6 9.1 6H7.46667V3.25C7.46667 3.18229 7.44358 3.1237 7.3974 3.07422C7.35122 3.02474 7.29653 3 7.23333 3H5.83333C5.77014 3 5.71545 3.02474 5.66927 3.07422C5.62309 3.1237 5.6 3.18229 5.6 3.25V6H3.96667C3.90347 6 3.84878 6.02474 3.8026 6.07422C3.75642 6.1237 3.73333 6.18229 3.73333 6.25C3.73333 6.32292 3.75521 6.38281 3.79896 6.42969L6.36563 9.17969C6.40938 9.22656 6.46528 9.25 6.53333 9.25C6.60139 9.25 6.65729 9.22656 6.70104 9.17969L9.26042 6.4375C9.30903 6.375 9.33333 6.3125 9.33333 6.25ZM14 8C14 8.82812 13.7266 9.53516 13.1797 10.1211C12.6328 10.707 11.9729 11 11.2 11H3.26667C2.36736 11 1.59809 10.6576 0.958854 9.97266C0.319618 9.28776 0 8.46354 0 7.5C0 6.82292 0.170139 6.19792 0.510417 5.625C0.850695 5.05208 1.30764 4.6224 1.88125 4.33594C1.87153 4.17969 1.86667 4.06771 1.86667 4C1.86667 2.89583 2.23125 1.95313 2.96042 1.17188C3.68958 0.390625 4.56944 0 5.6 0C6.35833 0 7.05226 0.226562 7.68177 0.679688C8.31129 1.13281 8.76945 1.73438 9.05625 2.48438C9.40139 2.16146 9.80486 2 10.2667 2C10.7819 2 11.2219 2.19531 11.5865 2.58594C11.951 2.97656 12.1333 3.44792 12.1333 4C12.1333 4.39583 12.0337 4.75521 11.8344 5.07813C12.4663 5.23958 12.9852 5.59245 13.3911 6.13672C13.797 6.68099 14 7.30208 14 8Z" fill={color} />
    </svg>

  );
}
