import { useQueryClient } from 'react-query';
import useYear from '../../../hooks/useYear';
import Dropdown from '../../atoms/Dropdown/Dropdown';
import FilterModel from './FilterModel';
import FiltersGroup from './StyledFiltersGroup';

export interface IFilter {
  temporalidad?: string[],
  campaignType?: string[],
  rappelType?: string[],
  conventionType?: string[],
  categories?: string[],
  status?: string[],
}

export function dropdownDataFunction() {
  const { year } = useYear();
  const data = [
    {
      id: 'campaignType',
      name: 'grouping',
      type: 'string',
      label: 'Tipo de campaña',
      options: [],
    },
    {
      id: 'rappelType',
      name: 'grouping',
      type: 'string',
      label: 'Tipo de Rappel',
      options: [],
    },
    {
      id: 'conventionType',
      name: 'grouping',
      type: 'string',
      label: 'Tipo de Convención',
      options: [],
    },
    {
      id: 'categories',
      name: 'categoryId',
      type: 'string',
      label: 'Tipo de Rappel',
      options: [],
    },
    {
      id: 'temporalidad',
      name: 'dateRange',
      type: 'string',
      label: 'Temporalidad',
      options: [
        { value: '', label: 'Seleccione una opción' },
        { value: 'SEMANAL', label: 'Semanal' },
        { value: 'QUINCENAL', label: 'Quincenal' },
        { value: 'MENSUAL', label: 'Mensual' },
        { value: 'BIMESTRAL', label: 'Bimestral' },
        { value: `TRIMESTRAL,${year}-01-01,${year}-03-31`, label: 'Trimestre 1' },
        { value: `TRIMESTRAL,${year}-04-01,${year}-06-30`, label: 'Trimestre 2' },
        { value: `TRIMESTRAL,${year}-07-01,${year}-09-30`, label: 'Trimestre 3' },
        { value: `TRIMESTRAL,${year}-10-01,${year}-12-31`, label: 'Trimestre 4' },
        { value: 'CUATRIMESTRAL', label: 'Cuatrimestral' },
        { value: 'SEMESTRAL', label: 'Semestral' },
        { value: 'ANUAL', label: 'Anual' },
        { value: 'INTERANUAL', label: 'Interanual' },
      ],
    },
    {
      id: 'status',
      name: 'status',
      type: 'string',
      label: 'Estado',
      options: [
        { label: 'Todas', value: '' },
        { label: 'En curso', value: 'in_progress' },
        { label: 'Finalizada', value: 'finished' },
        { label: 'Revisión', value: 'review' },
        { label: 'Liquidado', value: 'liquidated' },
        { label: 'Cerrado', value: 'closed' },
      ],
    },
  ];
  return { data };
}

export interface IGroupFilterOptions {
  selectedFilters: IFilter;
  filter: FilterModel;
  setGlobalFilter: (filter :FilterModel) => void;
}

export interface IDropdownData {
  id: string;
  name: string;
  type: string;
  label: string;
  options: IOptions[]|[];
}

export interface IOptions {
  value: string | number | undefined;
  label: string;
  type?: string;
}

function getOptionsForSelect(type: String) {
  const queryClient = useQueryClient();
  const { year } = useYear();

  const groupingList :any = queryClient.getQueryState(['GroupingList']);
  const list = groupingList?.data?.groupingList?.grouping[year];

  const listWSubgroupings = list && Object.keys(list)?.map(
    (pType: any) => list[pType]?.slice(1).map((c:any) => {
      let arraySubs = [];
      if (c?.subgroupings && c?.subgroupings?.length > 0) {
        arraySubs = c.subgroupings?.slice(1).map(
          (e:any) => ({
            label: e.label, value: e.id.toUpperCase(), type: pType.toLowerCase(), sub: true,
          }),
        );
      }
      return [{
        label: c.label, value: c.id.toUpperCase(), type: pType.toLowerCase(), sub: false,
      }, arraySubs].flat();
    }).flat(),
  );

  let formatedOptions = type === 'campaignType' ? listWSubgroupings && listWSubgroupings[0]
    : type === 'rappelType' ? listWSubgroupings && listWSubgroupings[1] : listWSubgroupings && listWSubgroupings[2];

  if (formatedOptions && formatedOptions.length > 0) {
    formatedOptions = [{ label: 'Seleccione una opción', value: '', type: '' }, ...formatedOptions];
  }
  return formatedOptions;
}

export default function GroupFilterGeneral({
  selectedFilters,
  filter,
  setGlobalFilter,
}: IGroupFilterOptions) {
  let opts = [];

  const updateFilter = (newFilter: FilterModel) => {
    setGlobalFilter(newFilter);
  };

  const { data: dropdownData } = dropdownDataFunction();
  const arrayDropdowns = dropdownData
    .filter((ele: IDropdownData) => Object.keys(selectedFilters).includes(ele.id))
    .map((drdata: IDropdownData) => {
      switch (drdata.id) {
      case 'campaignType':
        opts = getOptionsForSelect('campaignType');
        drdata.options = opts;
        break;
      case 'categories':
        // campaign types query
        opts = [
          { value: '', label: 'Seleccione una opción' },
          { value: '1', label: 'Category 1' },
          { value: '2', label: 'Category 2' },
          { value: '3', label: 'Category 3' },
        ];
        drdata.options = opts;
        break;
      case 'rappelType':
        // campaign types query
        opts = getOptionsForSelect('rappelType');
        drdata.options = opts;
        break;
      case 'conventionType':
        opts = getOptionsForSelect('conventionType');
        drdata.options = opts;
        break;
      case 'year':
        break;
      case 'status':
        opts = [
          { label: 'Seleccione una opción', value: undefined },
          { label: 'Todas', value: '' },
          { label: 'En curso', value: 'in_progress' },
          { label: 'Finalizada', value: 'finished' },
          { label: 'Revisión', value: 'review' },
          { label: 'Liquidado', value: 'liquidated' },
          { label: 'Cerrado', value: 'closed' },
        ];
        // eslint-disable-next-line
        drdata.options = opts;
        break;
      default:
        break;
      }

      let filterResult: any;
      Object.entries(filter).forEach((item: any) => {
        const [filterKey, filterValue] = item;
        if (filterKey === drdata.name && filterResult === undefined) {
          filterResult = filterValue;
        }
      });

      return (
        <Dropdown
          key={drdata.id}
          options={drdata.options}
          label={drdata.label}
          filter={filter}
          name={drdata.name}
          setFilter={updateFilter}
          defaultValue={filterResult || (filter ? (filter.dateRange || filter.grouping || filter.status) : '')}
        />
      );
    });

  return (
    <FiltersGroup>
      { arrayDropdowns }
    </FiltersGroup>
  );
}
