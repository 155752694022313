import { Outlet, useLocation } from 'react-router-dom';
import GlobalStyle from '../../styles/GlobalStyles';
import Header from '../organisms/Header/Header';
import Footer from '../organisms/Footer/Footer';
import { HeaderNotLogged } from '../organisms/Header/StyledHeader';
import LinkTo from '../atoms/Link/LinkTo';
import Logo from '../atoms/Logo/Logo';
import Navigation from '../molecules/Navigation/Navigation';
import Container from '../atoms/Container/Container';
import { getTokenFromStore } from '../../store/sessionStore';
import useNewSession from '../../hooks/useNewSession';

export default function Layout(props: any) {
  const { user, category } = props;
  const isAuth = !!getTokenFromStore();

  const location = useLocation();
  const currentURL = location.pathname;

  useNewSession(user, currentURL);

  return (
    <>
      <GlobalStyle />
      {isAuth
        ? <Header user={user} category={category} />
        : (
          <Container>
            <HeaderNotLogged>
              <LinkTo to="/">
                <Logo />
              </LinkTo>
              <Navigation />
            </HeaderNotLogged>
          </Container>
        ) }
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
