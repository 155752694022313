import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
  idSession: {
    userId: string;
    sessionId: string;
    userIp: string;
    userAgent: string;
  };
}

type Actions = {
  setIdSession: (userId: string, sessionId: string, userIp: string, userAgent: string) => void;
  clearIdSession: () => void;
}

const sessionIdentifierStore = create(persist<State & Actions>((set) => ({
  idSession: {
    userId: '',
    sessionId: '',
    userIp: '',
    userAgent: '',
  },
  setIdSession: (userId, sessionId, userIp, userAgent) => set(() => ({
    idSession: {
      userId,
      sessionId,
      userIp,
      userAgent,
    },
  })),
  clearIdSession: () => set({
    idSession: {
      userId: '',
      sessionId: '',
      userIp: '',
      userAgent: '',
    },
  }),
}), {
  name: 'idSession',
}));

export const getSessionFromStore = () => {
  const { idSession } = sessionIdentifierStore.getState();
  return idSession;
};

export default sessionIdentifierStore;
