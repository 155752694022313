import { gql } from 'graphql-request';

const GET_RESULTS_BY_CODE_GQL = gql`
query MyQuery($code: String!, $nif: String, $firstName: String, $lastName: String, $dgt: String, $dt: String, $od: String, $category: String, $group: String, $numberElements: Int, $numberPage: Int, $order: String, $orderType: String) {
  myTeamResultsByCode(code: $code, nif: $nif, firstName: $firstName, lastName: $lastName, dgt: $dgt, dt: $dt, od: $od, category: $category, group: $group, numberElements: $numberElements, numberPage: $numberPage, order: $order, orderType: $orderType) {
    count
    nodes {
      id
      amount
      category
      currency
      dgt
      dt
      od
      nif
      userId
      puestoId
      fulfills
      extraFields
      participants
      points
      ranking
      rankingGroup
      status
      updateDate
      updatedAt
      wins
      territoryNameDgt
      territoryNameDt
      territoryNameOd
      firstName
      lastName
      dataDate
    }
  }
}
`;

export default GET_RESULTS_BY_CODE_GQL;
