import { StyledBannerContainer } from './StyledHome';
import BannerList from '../../atoms/Banner/BannerList';
import useBanners from '../../../hooks/useBanners';

export default function CardsHomeBanner() {
  const { data } = useBanners();

  return (
    <StyledBannerContainer>
      {data?.length > 0 && (
        <BannerList data={data} />
      )}
    </StyledBannerContainer>
  );
}
