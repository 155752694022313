import useFormatDate from '../hooks/useDate';
import formatColumn from '../hooks/useFormatColumn';
import formatValue from '../hooks/useFormatValue';
import useMappingColumnNames from '../hooks/useMappingColumnNames';
import useUrlDetail from '../hooks/useUrlDetail';
import { Generic } from '../interfaces/Generic.d';
import { PlanByCode } from '../interfaces/PlanByCode.d';
import { PlanLevel } from '../interfaces/PlanLevel.d';
import { Results } from '../interfaces/Results.d';
import useLowerCaseKeys from '../hooks/useLowerCaseKeys';
import useRemoveAccents from '../hooks/useRemoveAccents';
import useLowerCaseValues from '../hooks/useLowerCaseValues';
import useReassignColumn from '../hooks/useReassignColumn';

type MyTeamResultsByCode = {
  myTeamResultsByCode: {
    nodes: Results[],
  }
}

export default function DetailResultsAdapter(
  id: string | undefined,
  myTeamResultsByCode: MyTeamResultsByCode,
  planByCode: {
    planByCode: PlanByCode,
  },
) {
  let resultsWithoutDGTs: Generic = {};
  const dataFormatted: any = [];
  const orderedExtraFields: Generic[] = [];

  const translations = planByCode ? JSON.parse(planByCode?.planByCode?.translations) : {};

  const results = myTeamResultsByCode?.myTeamResultsByCode?.nodes
    ?.map((result: Results) => ({
      dgt: result.territoryNameDgt ? `${result.dgt} - ${result?.territoryNameDgt}` : result.dgt,
      dt: result.territoryNameDt ? `${result.dt} - ${result.territoryNameDt}` : result.dt,
      od: result.territoryNameOd ? `${result.od} - ${result.territoryNameOd}` : result.od,
      denomination: `${result.firstName} ${result.lastName}`,
      nif: result?.nif,
      extraFields: result?.extraFields,
      dataDate: result?.dataDate,
      Detalle: id ? useUrlDetail(id, result, planByCode) : '',
    }));

  results?.map((result: Generic) => {
    resultsWithoutDGTs = {};
    Object.keys(result?.extraFields).filter((keys:string) => !['DGT', 'DT', 'OD'].includes(keys)).map((key:string) => {
      if (key === 'FECHA ACT' || key === 'FECHA DATO') {
        if (result?.dataDate) {
          resultsWithoutDGTs['FECHA DATO'.replace(/ /g, '')] = useFormatDate(result?.dataDate);
        } else {
          resultsWithoutDGTs['FECHA DATO'.replace(/ /g, '')] = useFormatDate(result?.extraFields[key]?.split('T')[0]);
        }
      } else {
        resultsWithoutDGTs[useRemoveAccents(key.replace(/ /g, '').split('.').join(''))] = formatValue(result?.extraFields[key], key, translations);
      }

      return resultsWithoutDGTs;
    });

    const rowWithExtras = { ...result, ...resultsWithoutDGTs };
    delete rowWithExtras.extraFields;
    dataFormatted.push(rowWithExtras);

    return result;
  });

  let totalCols: Generic[] = [];

  const plansLevel1 = planByCode?.planByCode?.planLevels
    ?.filter((planLevel:PlanLevel) => planLevel.level === 1);

  plansLevel1?.push({
    id: '0',
    field: 'resultId',
    level: 1,
    order: 0,
  });

  if (planByCode !== undefined) {
    const hasDenomination = plansLevel1
      ?.filter((planLevel:PlanLevel) => planLevel.field.toLowerCase() === 'denomination');

    if (hasDenomination.length === 0) {
      plansLevel1?.push({
        id: '01',
        field: 'denomination',
        level: 1,
        order: 3,
      });
    }

    plansLevel1.sort((a: any, b: any) => a.order - b.order).map((planLevel: PlanLevel) => {
      const planLevelFormatted = planLevel?.field?.replace(/ /g, '')?.split('.')?.join('');

      if (planLevelFormatted === 'resultId') {
        orderedExtraFields.push({
          Header: formatColumn(planLevel?.field, translations),
          accessor: 'resultId',
        });
      } else if (Object.keys(resultsWithoutDGTs).map((keys:string) => keys.replace(/ /g, '')).includes(planLevelFormatted)) {
        orderedExtraFields.push({
          Header: formatColumn(planLevel.field.split('.').join(''), translations),
          accessor: planLevelFormatted,
        });
      } else if (totalCols?.map((totalCol: any) => totalCol.accessor?.replace(/ /g, '').includes(planLevelFormatted))) {
        if (planLevelFormatted === 'DOCUMENTO') {
          orderedExtraFields.push({
            Header: formatColumn(planLevel.field.split('.').join(''), translations),
            accessor: 'nif',
          });
        }
        if (planLevelFormatted !== 'DOCUMENTO') {
          orderedExtraFields.push({
            Header: formatColumn(planLevel.field.split('.').join(''), translations),
            accessor: planLevelFormatted.toLowerCase(),
          });
        }
      } else if (planLevelFormatted === 'denomination') {
        orderedExtraFields.push({
          Header: formatColumn(planLevel?.field, translations),
          accessor: 'denomination',
        });
      }

      return orderedExtraFields;
    });

    if (results?.length > 0) {
      Object.keys(results[0]).map((col: string) => {
        if (col !== 'dataDate') {
          totalCols.push({
            Header: useMappingColumnNames(col, 'convention'),
            accessor: col.replace(/ /g, ''),
          });
        }
        return totalCols;
      });

      totalCols = [orderedExtraFields].flat();
    }
  }

  let totalColumnsFiltered: any = [];
  const colsFilterDetail = totalCols.filter((column: any) => column.Header !== 'Detalle');
  if (planByCode?.planByCode?.grouping?.name !== 'CONVENCION_RANKING') {
    totalColumnsFiltered = [
      ...colsFilterDetail,
      {
        Header: 'Detalle',
        accessor: 'Detalle',
      }];
  } else {
    totalColumnsFiltered = colsFilterDetail;
  }

  const filteredTotalColumns = Array.from(
    new Set(totalColumnsFiltered.reverse().map(JSON.stringify)),
  ).reverse().map(
    (e:any) => JSON.parse(e),
  );

  const columnsFormatted = filteredTotalColumns.map((i) => ({
    Header: i.Header.replaceAll('(porcentaje)', '%').replaceAll('(decimales)', ''),
    accessor: i.accessor,
  }));

  const { data } = useLowerCaseKeys(dataFormatted);
  const columns = useLowerCaseValues(columnsFormatted);
  const columnsXlsx = useReassignColumn(columns.filter((column: any) => (column.Header !== 'Detalle' && column.Header !== 'resultId')));

  return {
    columns,
    data,
    columnsXlsx,
  };
}
