import { gql } from 'graphql-request';

const GET_CURRENT_USER_PLANS_GQL = gql`
query MyQuery($year: Int) {
  mePlans(year: $year) {
    campaign
    rappel
    convention
    ranking
  }
}
`;
export default GET_CURRENT_USER_PLANS_GQL;
