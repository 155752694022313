import Styled, { css } from 'styled-components';
import Theme from '../../../styles/Theme';

const StyledBtnSecondary = css`
  background-color: ${Theme.colorWhite};
  border: 1px solid ${Theme.colorBorder};
  border-radius: 8px;
  color: #666666;
  font-weight: 400;
  padding: 0.72rem 1rem;
  text-transform: underline;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  svg path{
    fill: #666666;
  }
`;

const StyledButton = Styled.button<{type?: string, size?: number, mb?: string, isType?: string, color?: string, padding?: string}>`
  appearance: none;
  background-color: ${Theme.colorPrimary};
  border: 0;
  border-radius: ${Theme.radius};
  color: ${(props) => (props.color ? props.color : Theme.colorWhite)};
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: ${(props) => props.mb};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : '18px 24px')};
  line-height: 1.25;
  min-width: 100%;
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  ${(props) => props.isType === 'secondary' && StyledBtnSecondary}

  @media screen and (min-width: 768px) {
    min-width: ${(props) => props.size}px;

    &:hover {
      box-shadow: ${Theme.shadow};
      transform: translateY(-2px);
    }
  }

  .btn__login {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &.active {
    background: ${Theme.colorPrimary};
    color: ${Theme.colorWhite};
  }

  &.btn__logout {
    min-width: 100px;
    padding: 0;
    font-size: 14px;
    background-color: transparent;

    span {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      margin: 0 0 0.25rem 0;
    }

    &:hover {
      box-shadow: none;
      border: 0;
      transform: none;
    }
  }
  &.btn__subscribed {
    min-width: 100px;
    padding: 0;
    font-size: 14px;
    color: white;
    appearance: none;
    border: 0;
    border-radius: ${Theme.radius};
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    line-height: 1.25;
    min-width: 100%;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      margin: 0 0 0.25rem 0;
    }

    &:hover {
      box-shadow: none;
      border: 0;
      transform: none;
    }
  }
  &.btn__excluded_police {
    height: 3rem;
    min-width: 70% !important;
    margin-left: auto;
    margin-right: auto;
    background-color: ${Theme.colorWhite};
    border: 1px solid ${(props) => (props.color ? props.color : Theme.colorWhite)};
    border-radius: 8px;
    cursor: pointer;
    color: ${(props) => (props.color ? props.color : Theme.colorWhite)};
    margin-bottom: 30px;
    &:hover {
      color: ${(props) => (props.color ? props.color : Theme.colorWhite)};;
    }
  }
`;

export const ButtonBack = Styled.button<{color?: string}>`
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  color: ${Theme.colorPrimary};
  font-weight: 500;
  font-size: 16px;

  &:before {
    content: "<";
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
`;

export default StyledButton;
