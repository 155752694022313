import { useState } from 'react';
import Theme from '../../../styles/Theme';
import Button from '../../atoms/Button/Button';
import IconOutlook from '../../atoms/Icon/IconOutlook';
import Spinner from '../../atoms/Spinner/Spinner';
import { FormContainer } from './StyledForm';

export default function OauthLogin() {
  const [isSending, setIsSending] = useState<boolean>(false);

  const performOauth = () => {
    setIsSending(true);

    window.location.href = `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}\
                            /oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_AZURE_CLIENT_ID}\
                            &redirect_uri=${process.env.REACT_APP_BASE_URL}/login/azure\
                            &scope=openid offline_access email profile\
                            &response_type=code\
                            &response_mode=query\
                            &code_challenge=IeG2Z6b0wgV33iNUUbQYvTsW6J3IIAr785xlbVHRUio\
                            &code_challenge_method=S256`;
  };

  return (
    <FormContainer>
      <Button
        type="submit"
        onClick={performOauth}
        size={262}
        disabled={isSending}
      >
        {!isSending ? (
          <span className="btn__login">
            Iniciar sesión
            <IconOutlook color={Theme.colorWhite} width="20px" height="20px" />
          </span>
        ) : (
          <Spinner background="rgba(200,200,200,0.6)" color="#fff" />
        )}
      </Button>
    </FormContainer>
  );
}
