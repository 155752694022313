import { gql } from 'graphql-request';

const GET_MY_TEAM_DATA_FILTERS_GQL = gql`
query MyQuery($type: String!, $grouping: String, $subgrouping: String, $code: String, $year: Int) {
  dropdownFilter(type: $type, grouping: $grouping, subgrouping: $subgrouping, code: $code, year: $year) {
    arrayDgts
    arrayDts
    arrayOds
    arrayCategories
    arrayNifs
    arrayFirstNames
    arrayLastNames
    arrayDgtsDtsOds
    arrayDtsOds
    arrayGroups
    arrayYears
    arrayStatuses
  }
}
`;

export default GET_MY_TEAM_DATA_FILTERS_GQL;
