import Styled from 'styled-components';

const FiltersGroup = Styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 0 0 1.5rem;
  position: relative;
  z-index: 7;
  width: 100%;
`;

export default FiltersGroup;
