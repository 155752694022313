import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const MonthsWrapper = Styled.div`
  overflow: hidden;
  width: 100%;
  margin: 1.5rem 0 1.5rem;
  padding: 0 0 0.5rem;
  border-bottom: 2px solid ${Theme.colorTertiary};
`;

export const MonthList = Styled.ul`
  padding: 0;
  margin: 0 auto;
  max-width: 900px;
  display: flex;
  overflow: auto;
`;

export const MonthItem = Styled.div`
  border-radius: 0;
  position: relative;
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const StyledButton = Styled.button`
  background: transparent;
  border: 0;
  color: ${Theme.fontColorSecondary};
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: center;
  padding: 8px;
  margin: 0;

  &.selected {
    background: ${Theme.colorSecondary3};
    border-radius: 50%;
    position: relative;
    height: 40px;
    width: 40px;
    z-index: 2;
  }

  &.active {
    background: ${Theme.colorTertiary};
    width: 100%;
    position: relative;

    &::before,
    &::after {
      content: "";
      background: ${Theme.colorTertiary};
      position: absolute;
      top: 0;
      width: 40px;
      height: 40px;
    }

    &::before {
      left: -40px;
    }

    &::after {
      right: -40px;
    }
  }
`;
