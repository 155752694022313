import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Theme from '../../../styles/Theme';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import { LinkTable, Table } from '../../atoms/Table/Table';
import Typography from '../../atoms/Typography/Typography';
import Container from '../../atoms/Container/Container';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import Button from '../../atoms/Button/Button';
import LinkTo from '../../atoms/Link/LinkTo';
import FilterModel from '../../molecules/Filter/FilterModel';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import useNumberFormat from '../../../hooks/useNumberFormat';
import useColumnTableUserResults from '../../../hooks/useColumnTableUserResults';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import USER_RESULTS from '../../../graphql/queries/getUserResultsGql';
import GET_TERRITORY_USERS_GQL from '../../../graphql/queries/getTerritoriesUsersGql';
import GET_TERRITORY_NIF_USERS_GQL from '../../../graphql/queries/getTerritoriesNifUsersGql';

export default function ResultsTeam() {
  const graphQLClient = createGraphQLClient();
  const navigate = useNavigate();
  const { year } = useYear();
  const { columns } = useColumnTableUserResults();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());

  if (!globalFilter.year) {
    setGlobalFilter({ ...globalFilter, year });
  }

  const variablesFilters = {
    dgt: globalFilter.dgt,
    dt: globalFilter.dt,
    od: globalFilter.od,
    category: globalFilter.category,
    year: globalFilter?.year || year,
  };

  const {
    data: dataFilters,
    isFetching: isFetchingFilters,
    refetch: refetchFilters,
  } = useQuery(
    ['ResultsTeamFilters'],
    async () => (graphQLClient && graphQLClient.request(GET_TERRITORY_USERS_GQL, variablesFilters)),
    {
      staleTime: Infinity,
    },
  );

  const dataUserFilter = dataFilters;

  const {
    data: dataNifFilter,
    isFetching: isFetchingNifFilter,
    refetch: refetchNifFilter,
  } = useQuery(
    ['ResultsNifFilter', variablesFilters],
    async () => (graphQLClient && graphQLClient.request(
      GET_TERRITORY_NIF_USERS_GQL,
      variablesFilters,
    )),
    {
      staleTime: Infinity,
    },
  );

  const variablesResults = {
    userId: globalFilter?.nif,
    planStatus: globalFilter?.status,
    planType: globalFilter?.planType,
    category: globalFilter?.category,
    year: globalFilter?.year || year,
  };

  const {
    data: dataResults,
    isFetching: isFetchingResults,
    refetch: refetchResults,
  } = useQuery(
    ['Results', variablesResults],
    async () => (graphQLClient && graphQLClient.request(
      USER_RESULTS,
      variablesResults,
    )),
    {
      enabled: false,
      staleTime: Infinity,
    },
  );

  if (dataUserFilter?.dropdownTerritoriesUsers) {
    dataUserFilter.dropdownTerritoriesUsers.arrayStatuses = [
      { label: 'Todos', name: undefined },
      ...dataUserFilter.dropdownTerritoriesUsers.arrayPlanStatus,
    ];
  }

  if (dataNifFilter?.dropdownTerritoriesNifUsers) {
    const nifs = dataNifFilter.dropdownTerritoriesNifUsers.arrayUsersData;
    dataNifFilter.dropdownTerritoriesNifUsers.arrayNifs = nifs;
  }

  const typeName = [
    { Campaign: 'Campaña' },
    { Rappel: 'Rappel' },
    { Convention: 'Convención' },
  ];

  const resultsAdapter = dataResults?.userResults?.map((item: any) => ({
    ...item,
    amount: useNumberFormat(item.amount),
    dataDate: item.dataDate !== undefined ? useFormatDate(item.dataDate) : '',
    download: item?.basesUrl !== '#' ? <LinkTable url={item?.basesUrl} title="Descargar" /> : '',
    planType: typeName[item?.planType] || '',
    result: item?.planCode ? <LinkTo to={`/team/campaign/resumen/${item?.planCode}`} color={Theme.colorPrimary} style={{ display: 'block', textAlign: 'center' }}>Ver resultados</LinkTo> : '',
  }));

  const handleClearFilters = () => {
    setGlobalFilter(new FilterModel());
    refetchFilters();
    refetchNifFilter();
  };

  useEffect(() => {
    if (globalFilter.status !== undefined
      || globalFilter.nif !== undefined
      || globalFilter.planType !== undefined
      || globalFilter.category !== undefined
      || globalFilter.year !== undefined
      || ((!isFetchingFilters && !isFetchingNifFilter) && globalFilter.status !== 'in_progress')
    ) {
      refetchResults();
    }
  }, [
    globalFilter.status,
    globalFilter.nif,
    globalFilter.planType,
    globalFilter.category,
    globalFilter.year,
  ]);

  useEffect(() => {
    setGlobalFilter({ ...globalFilter, nif: '' });
  }, [dataNifFilter]);

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/team/summary/general')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorCampaign} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Resultados</Typography>

      <div className="filter__grid">
        <div className="filter__form_1_full">
          {(!isFetchingFilters) ? (
            dataUserFilter?.dropdownTerritoriesUsers && (
              <FilterForm
                dataFilters={dataUserFilter?.dropdownTerritoriesUsers}
                filter={globalFilter}
                setFilter={setGlobalFilter}
                type=""
                fetching
                autoFilter={0}
                showYearFilter
              />
            )
          ) : <FilterFormSkeleton countFilters={3} buttons={false} />}
        </div>
      </div>
      <div className="filter__form_2_full">
        {(!isFetchingNifFilter) ? (
          dataNifFilter?.dropdownTerritoriesNifUsers && (
            <FilterForm
              dataFilters={dataNifFilter.dropdownTerritoriesNifUsers}
              filter={globalFilter}
              setFilter={setGlobalFilter}
              type=""
              fetching
              autoFilter={0}
            />
          )
        ) : <FilterFormSkeleton countFilters={1} buttons={false} />}
      </div>

      <div className="filter__button">
        <Button type="button" onClick={handleClearFilters} padding="0.5rem 1rem" size={200}>
          Limpiar filtros
        </Button>
      </div>

      {!isFetchingResults ? (
        dataResults?.userResults !== undefined && (
          <Table
            columns={columns}
            data={resultsAdapter || []}
            theme={Theme.colorCampaign}
          />
        )
      ) : <TableSkeleton countCell={6} theme={Theme.colorCampaign} />}
    </Container>
  );
}
