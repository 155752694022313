import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../../atoms/Spinner/Spinner';
import Typography from '../../atoms/Typography/Typography';
import AzureLoginContainer from './AzureLoginContainer';
import sessionStore from '../../../store/sessionStore';

export default function AzureLogin() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const responseCode = new URLSearchParams(search).get('code');
  const [redirect, setRedirect] = useState(false);
  const [notExistUser, setNotExistUser] = useState(false);

  const { setSession } = sessionStore();

  const getAuthToken = () => fetch(
    `${process.env.REACT_APP_API_BASE_URL}/login/azure`,
    {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      method: 'POST',
      body: JSON.stringify(
        {
          code: responseCode,
          code_verifier: 'e0f87fa924de51232946db7386601a909589fbe6e4a40108e1fecb11',
          redirect_uri: `${process.env.REACT_APP_BASE_URL}/login/azure`,
        },
      ),
    },
  );

  useEffect(() => {
    getAuthToken().then((response) => {
      response
        .text()
        .then((data) => {
          const { status, token } = JSON.parse(data);

          if (status !== 200) {
            setNotExistUser(true);
            setRedirect(true);
          }

          if (token && token !== undefined) {
            setSession(true, token);
            setRedirect(true);
          }
        });
    }).catch(() => {
      setRedirect(true);
    });
  }, []);

  if (redirect) {
    setTimeout(() => {
      navigate('/', { replace: true });
      window.location.reload();
    }, 100);
  }

  return (
    <AzureLoginContainer>
      {!notExistUser ? (
        <Typography display="flex" align="center">
          Accediendo &nbsp;
          <Spinner background="rgba(200,200,200,0.6)" color="#fff" />
        </Typography>
      ) : (
        <Typography display="flex" align="center">
          El usuario con el que estás intentando acceder no
          está autorizado para acceder a esta aplicación &nbsp;
          <Spinner background="rgba(200,200,200,0.6)" color="#fff" />
        </Typography>
      )}
    </AzureLoginContainer>
  );
}
