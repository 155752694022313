export default function useFormatDate(date: string) {
  if (typeof date !== 'undefined' && date !== undefined && date !== null) {
    let newDate = date.replaceAll('/', '-');
    if (/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(newDate)) {
      newDate = newDate.split('-').reverse().join('-');
    }
    const dateFormat = new Date(newDate);
    const dateFull = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).formatToParts(dateFormat).reduce((acc: any, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
    return `${dateFull.day}/${dateFull.month}/${dateFull.year}`;
  }
  return '';
}
