import { gql } from 'graphql-request';

const GET_PLAN_PARTICIPANTS_WIN_GQL = gql`
query MyQuery($grouping: String, $subgrouping: String, $myTeam: Boolean, $type: String!, $year: Int, $status: String) {
  planParticipantsWin(type: $type, myTeam: $myTeam, grouping: $grouping, subgrouping: $subgrouping, year: $year, status: $status) {
    totalUserWinCount
    participantsCount
  }
}
`;

export default GET_PLAN_PARTICIPANTS_WIN_GQL;
