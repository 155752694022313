import { IIcon } from './Iicon';
import IconPhone from './IconPhone';
import IconInstagram from './IconInstagram';
import IconFacebook from './IconFacebook';
import IconTwitter from './IconTwitter';
import IconLinkedin from './IconLinkedin';
import IconYoutube from './IconYoutube';

function IconSocial({
  color, height, type, width,
}: IIcon):any {
  switch (type) {
  case 'phone':
    return <IconPhone color={color} height={height} width={width} />;
  case 'instagram':
    return <IconInstagram color={color} height={height} width={width} />;
  case 'facebook':
    return <IconFacebook color={color} height={height} width={width} />;
  case 'twitter':
    return <IconTwitter color={color} height={height} width={width} />;
  case 'youtube':
    return <IconYoutube color={color} height={height} width={width} />;
  case 'linkedin':
    return <IconLinkedin color={color} height={height} width={width} />;
  default:
    return null;
  }
}

export default IconSocial;
