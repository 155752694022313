import { gql } from 'graphql-request';

const GET_SUMMARY_BY_TIMEFRAME = gql`
  query MyQuery($timeframe: String!, $type: String!, $year: Int, $subgrouping: String, $status: String, $startDate: String, $myTeam: Boolean, $grouping: String, $endDate: String) {
    summaryByTimeframe(timeframe: $timeframe, type: $type, year: $year, subgrouping: $subgrouping, status: $status, startDate: $startDate, myTeam: $myTeam, grouping: $grouping, endDate: $endDate) {
      totalUserWinCount
      totalUserMetCount
      totalTreboles
      totalPlanWinCount
      totalPlanMetCount
      totalAmount
      total
      points
      participantsCount
    }
  }
`;

export default GET_SUMMARY_BY_TIMEFRAME;
