import { gql } from 'graphql-request';

const GET_CURRENT_USER_DATA_INFO_GQL = gql`
query MyQuery($type: String, $startDate: String, $endDate: String, $grouping: String, $subgrouping: String, $timeframe: String, $year: Int, $first: Int, $level: Int, $status: String) {
  me {
    email
    firstName
    lastName
    id
    activityCode
    calculatedCategory
    updatedAt
    plans(type: $type, startDate: $startDate, endDate: $endDate, grouping: $grouping, subgrouping: $subgrouping, timeframe: $timeframe, year: $year, first: $first, status: $status) {
      nodes {
        id
        grouping {
          name
        }
        subgrouping {
          name
        }
        code
        basesUrl
        startDate
        endDate
        amount
        name
        description
        groupedData
        groupedItems
        scope
        timeframe
        status
        planStatusName
        type
        fulfillsNumber
        participantsNumber
        winsNumber
        updatedAt
        dataDate
        resultsAmount
        translations
        subscribed
        results {
          nodes {
            planId
            id
            amount
            category
            userId
            firstName
            lastName
            dgt
            dt
            od
            fulfills
            extraFields
            nif
            ranking
            rankingGroup
            status
            updateDate
            wins
            dataDate
            currency
            territoryNameDgt
            territoryNameDt
            territoryNameOd
          }
        }
        planLevels(level: $level) {
          field
          level
          planId
          order
          id
        }
      }
    }
  }
}
`;

export default GET_CURRENT_USER_DATA_INFO_GQL;
