import useYear from '../../../hooks/useYear';
import IFilterForm from './IFilterForm';

export default function InitialValues() {
  const { year } = useYear();
  const initialValues: IFilterForm = {
    name: '',
    lastName: '',
    nif: '',
    category: '',
    dgt: '',
    dt: '',
    od: '',
    year,
    status: '',
    planType: '',
  };

  return { initialValues };
}
