import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import Theme from '../../../styles/Theme';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import Container from '../../atoms/Container/Container';
import { Table } from '../../atoms/Table/Table';
import Typography from '../../atoms/Typography/Typography';
import GET_MULTI_DATA from '../../../graphql/queries/getMultiDataGql';
import useNumberFormat from '../../../hooks/useNumberFormat';
import { Card } from '../../atoms/Card/Card';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import { createGraphQLClient } from '../../../graphql/graphqlClient';

export default function RappelDetail() {
  const graphQLClient = createGraphQLClient();
  const { id, denomination } = useParams();
  const navigate = useNavigate();
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const from = params.get('from');

  const {
    data: MultiData,
    isFetching: isFetchingConventionMultiData,
  } = useQuery(
    ['RappelMultidata', { resultId: id, myTeam: true }],
    async () => (graphQLClient && graphQLClient.request(
      GET_MULTI_DATA,
      { resultId: id, myTeam: true },
    )),
  );

  const MultiDataOrdered = MultiData
    ?.getMultiData
    ?.groupedResult?.sort((a: any, b: any) => a.level - b.level);

  const nonShowedTables = from && from === 'mpg' ? 0 : 1;

  const arrayColumnsRappel = MultiDataOrdered?.map((dataLevel: any) => {
    if (((!from || (from && from !== 'mpg')) && dataLevel?.level !== 1) || (from && from === 'mpg')) {
      if (dataLevel?.has_grouping === true) {
        const arrayGroupedCols = Object.keys(dataLevel?.result)?.map((res: any) => {
          if (dataLevel?.result[res].length > 0) {
            return Object.keys(dataLevel?.result[res][0]);
          }
          return undefined;
        });

        const formattedCols = arrayGroupedCols.flat()?.map((column: any) => ({
          Header: column.replaceAll('(porcentaje)', '').replaceAll('(decimales)', ''),
          accessor: column.split('.').join(''),
        }));
        const uniqueColumns = formattedCols.reduce((acc: any, current: any) => {
          const x = acc.find((item: any) => item.accessor.split('.').join('') === current.accessor.split('.').join(''));

          if (!x) {
            return acc.concat([current]);
          }
          return acc;
        }, []);

        let totalColsFiltered: any = [];
        const colsFilterDetail = uniqueColumns.filter((column: any) => column.Header !== 'groupedItem');
        totalColsFiltered = [{ Header: 'Agrupación', accessor: 'groupedItem' }, ...colsFilterDetail];
        return totalColsFiltered;
      }
      return Object?.keys(dataLevel?.result)?.map((column: any) => ({
        Header: column.replaceAll('(porcentaje)', '').replaceAll('(decimales)', ''),
        accessor: column.split('.').join(''),
      })).filter((column: any) => (column.Header !== 'Cumple Rentabilidad'));
    }
    return [];
  }).slice(nonShowedTables);

  const arrayDataRappel = MultiDataOrdered?.map((dataLevel: any) => {
    if (((!from || (from && from !== 'mpg')) && dataLevel?.level !== 1) || (from && from === 'mpg')) {
      if (dataLevel?.has_grouping === true) {
        const arrayGroupedItems = Object.keys(dataLevel?.result)?.map((res: any) => {
          if (dataLevel?.result[res].length > 0) {
            return dataLevel?.result[res];
          }
          return undefined;
        });

        const arrayGroupedFormattedItems = Object.keys(arrayGroupedItems.flat()).map((key: any) => {
          const formattedGroupedData:any = {};
          Object.keys(arrayGroupedItems.flat()[key])?.map((key2: any) => {
            const currentValue = arrayGroupedItems.flat()[key][key2];
            const decimals = key2?.includes('(porcentaje)') || key2?.includes('(decimales)');
            // eslint-disable-next-line no-restricted-globals, no-nested-ternary
            formattedGroupedData[key2.split('.').join('')] = !isNaN(currentValue)
              ? key2?.includes('(porcentaje)') ? `${useNumberFormat(currentValue * 100, decimals)} %` : useNumberFormat(currentValue, decimals)
              : arrayGroupedItems.flat()[key][key2];
            return formattedGroupedData;
          });
          return formattedGroupedData;
        });
        return arrayGroupedFormattedItems.flat();
      }
      const dataLevelFormatted:any = {};
      // eslint-disable-next-line no-return-assign
      Object.keys(dataLevel?.result)?.map((key: any) => {
        const currentValue = dataLevel?.result[key];
        const decimals = key?.includes('(porcentaje)') || key?.includes('(decimales)');
        // eslint-disable-next-line no-restricted-globals, no-nested-ternary
        dataLevelFormatted[key.split('.').join('')] = !isNaN(currentValue)
          ? key?.includes('(porcentaje)') ? `${useNumberFormat((currentValue || 0) * 100, decimals)} %` : useNumberFormat(currentValue, decimals)
          : currentValue;
        return dataLevelFormatted;
      });
      return [dataLevelFormatted];
    }
    return [];
  }).slice(nonShowedTables);

  let widths = arrayColumnsRappel && arrayDataRappel?.map((_e: any, i: number) => {
    let response;
    if (i === 0) {
      response = arrayColumnsRappel[i].length > 3 ? 100 : 55;
    } else if (!arrayColumnsRappel[i + 1]) {
      response = arrayColumnsRappel[i].length > 4 ? 100 : 55;
    } else if (i % 2 !== 0) {
      const current = arrayColumnsRappel[i].length;
      const next = !arrayColumnsRappel[i + 1] ? 0 : arrayColumnsRappel[i + 1].length;
      const sum = 100 / (current + next);
      response = [Math.round(current * sum) - 3, Math.round(next * sum) - 3];
    }
    return response;
  });

  widths = widths?.flat().filter((element: number|undefined) => element !== undefined);

  const processArrayColumnsRappel = arrayColumnsRappel?.map((a:any) => a.map((i:any) => (
    i.accessor === 'detail' ? i : {
      Header: i.Header.replaceAll('(porcentaje)', '').replaceAll('(decimales)', ''),
      accessor: i.accessor,
    }
  )));

  return (
    <Container>
      <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
      <Typography align="center" color={Theme.colorRappel} display="block" size="28px" margin="2rem auto" weight="500">
        {`${denomination?.split('-').join(' ')} - DETALLE`}
      </Typography>
      {!isFetchingConventionMultiData ? (
        <Card display="flex" justifyContent="space-between" flexWrap="wrap" background="transparent">
          {arrayDataRappel?.map((rappelTable: any, index: number) => (
            <Card
              key={`${index * 2}`}
              display="flex"
              width={`${widths[index]}%`}
              background="transparent"
              flexGrow={!widths[index + 1] || index === 0 ? '0' : '1'}
              overflow="auto"
              flexWrap="wrap"
              justifyContent={(index === 1 || index === 4 ? 'flex-end' : '')}
            >
              <Table
                columns={processArrayColumnsRappel[index]}
                data={rappelTable}
                theme={Theme.colorRappel}
                defaultPageSize={1000}
              />
            </Card>
          ))}
        </Card>
      ) : (<TableSkeleton countCell={6} theme={Theme.colorRappel} />)}
    </Container>
  );
}
