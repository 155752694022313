import { ReactNode, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import Login from '../../pages/Login/Login';
import { getTokenFromStore } from '../../../store/sessionStore';

interface IProtectedRouteProps {
  children: ReactNode;
  currentPath?: string;
}

export default function ProtectedRoute({
  children,
  currentPath,
}: IProtectedRouteProps): ReactElement | null {
  const isAuth = !!getTokenFromStore();

  if (isAuth === true) {
    return children as ReactElement;
  }

  if (currentPath === '/login') {
    return <Login />;
  }

  return <Navigate to="/login" replace />;
}

ProtectedRoute.defaultProps = {
  currentPath: '',
};
