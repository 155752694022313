import { gql } from 'graphql-request';

const GET_TEAM_RESULTS_GQL = gql`
query MyQuery($grouping: String, $subgrouping: String, $myTeam: Boolean, $type: String!, $year: Int, $status: String) {
  summaryGlobalPositionChartByTimeframe(type: $type, myTeam: $myTeam, grouping: $grouping, subgrouping: $subgrouping, year: $year, status: $status) {
    totalPlanWinCount
    totalUserWinCount
    totalTreboles
    totalAmount
    totalPlanMetCount
    totalUserMetCount
    participantsCount
    points
    total
  }
}
`;

export default GET_TEAM_RESULTS_GQL;
