export default class ParamsModel {
  public query?: string;

  public numberElements?: number;

  public numberPage?: number;

  public order?: string;

  public orderType?: string;

  constructor(param?: ParamsModel) {
    if (param) {
      this.query = param.query;
      this.numberElements = param.numberElements;
      this.numberPage = param.numberPage;
      this.order = param.order;
      this.orderType = param.orderType;
    }
  }
}
