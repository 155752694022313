import { gql } from 'graphql-request';

const GET_PLAN_LEVELS_BY_CODE_GQL = gql`
query MyQuery($code: String!) {
  planLevelResults(code: $code) {
    id
    field
    level
    order
  }
}
`;

export default GET_PLAN_LEVELS_BY_CODE_GQL;
