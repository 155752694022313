import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const StyledModalBackground = Styled.div<{show?:boolean}>`
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const StyledModal = Styled.div`
  position: absolute;
  min-width: 40vw;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  height: 800px;
`;

export const StyledContainerModal = Styled.div`
  position: relative;
`;

export const StyledModalContent = Styled.div`
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
  width: 100%;
  padding: 1rem 1rem 0 1rem;
`;

export const StyledModalHeader = Styled.div`
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const StyledModalHeading = Styled.div`
  margin: 0 0 1rem;
  padding: 1rem;
  color: ${Theme.fontColor};
  background-color: #f1f1f1;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;

export const StyledClosedButton = Styled.button`
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  align-self: flex-end;
`;

export default StyledModal;
