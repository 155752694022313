import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const PreviewerContainer = Styled.div`
  position: fixed;
  top: 0;
  right: 0;
  border: 1px solid #cccccc;
  padding: 30px;
  margin: 20px;
  z-index: 1000;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 5px #ff9999;
  text-align: center;
`;

export const ClosePreviewButton = Styled.div`
  background: ${Theme.colorPrimary};
  color: ${Theme.colorSecondary2};
  padding: 10px;
  margin: 20px 0 0 0;
  border-radius: 10px;
  cursor: pointer;
`;
