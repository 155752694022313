export default function useMappingColumnNames(col:string, type:string) {
  switch (col) {
  case 'fulfills':
    return 'Cumple';
  case 'denomination':
    return 'Denominación';
  case 'rankingGroup':
    return 'Ranking';
  case 'amount':
    return type === 'campaign' ? 'Importe Campaña' : 'Importe Rappel';
  case 'IMPORTE':
    return type === 'campaign' ? 'Importe Campaña' : 'Importe Rappel';
  case 'Importe':
    return type === 'campaign' ? 'Importe Campaña' : 'Importe Rappel';
  case 'category':
    return 'Categoría';
  case 'dgt':
    return 'DGT';
  case 'dt':
    return 'DT';
  case 'od':
    return 'OD';
  case 'nif':
    return 'NIF';
  case 'updatedAt':
    return 'Datos a día';
  case 'dataDate':
    return 'Datos a día';
  case 'FECHA ACT':
    return 'Datos a día';
  case 'FECHA DATO':
    return 'Datos a día';
  case 'FECHADATO':
    return 'Datos a día';
  case 'CoberturaSaldosNetos':
    return 'Cobertura Saldos Netos';
  case 'PuntosSaldosNetos':
    return 'Puntos Saldos Netos';
  case 'NPProdFinancieros':
    return 'NP Prod Financieros';
  case 'CobProdFinancieros':
    return 'Cob Prod Financieros';
  case 'PuntosProdFinancieros':
    return 'Puntos Prod Financieros';
  case 'SaldosNetos':
    return 'Saldos Netos';
  case 'CobFP':
    return 'Cob FP';
  case 'PuntosFP':
    return 'Puntos FP';
  case 'NPFP':
    return 'NP FP';
  case 'RiesgoInd':
    return 'Riesgo Ind';
  case 'RiesgoCol':
    return 'Riesgo Col';
  case 'AccInd':
    return 'Acc Ind';
  case 'AccCol':
    return 'Acc Col';
  default:
    return col;
  }
}
