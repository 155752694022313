import Styled from 'styled-components';

const Info = Styled.div`
  text-align: right;
  margin: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
  gap: 0 1rem;
`;

export default Info;
