import { Link } from 'react-router-dom';
import Styled from 'styled-components';

export const HeaderSummaryTeam = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleSummaryTeam = Styled.h2<{theme: string}>`
  color: ${(props) => props.theme};
  font-size: 28px;
  font-weight: 400;
  margin: 0.5rem 0 1rem;
`;

export const LinkSummaryTeam = Styled(Link)<{theme: string}>`
  color: ${(props) => props.theme};
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
`;
