import Styled, { css } from 'styled-components';
import Theme from '../../../styles/Theme';

const StyledText = css<{color?: string, size?:string, weight?:string, margin?:string, align?: string, display?: string}>`
  color: ${(error) => (error ? (props) => props.color : Theme.fontColor)};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  margin: ${(props) => props.margin};
  text-transform: initial;
  display: ${(props) => props.display ?? 'inline-block'};
  text-align: ${(props) => props.align};
  position: relative;
  z-index: 2;
`;

export const StyledParagraph = Styled.p`
  ${StyledText};
`;

export const StyledTitleH1 = Styled.h1`
  ${StyledText};
`;

export const StyledTitleH2 = Styled.h2`
  ${StyledText};
`;

export const StyledTitleH3 = Styled.h3`
  ${StyledText};
`;

export const StyledTitleH4 = Styled.h4`
  ${StyledText};
`;

export const StyledTitleH5 = Styled.h5`
  ${StyledText};
`;

export const StyledTitleH6 = Styled.h6`
  ${StyledText};
`;

export const StyledTitleSpan = Styled.span`
  ${StyledText};
`;
