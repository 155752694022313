import { StyledRedeem } from './StyledNavigation';

type Params = {
  message: string;
  url: string;
}

export default function Redeem(Props: Params) {
  const { message, url } = Props;
  return (
    <StyledRedeem
      href={url}
      target="_blank"
      rel="noreferrer nofollow"
    >
      {message}
    </StyledRedeem>
  );
}
