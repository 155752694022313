function formatNumber(number: any, decimal: number, csv: boolean) {
  let numberResult: string = '';

  if (csv) {
    numberResult = Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimal,
      style: 'decimal',
    }).format((number !== undefined || number !== null) ? number.toFixed(decimal) : 0);
    return numberResult?.replace(/\./g, '');
  }

  return Intl.NumberFormat('de-DE', {
    minimumFractionDigits: decimal,
    style: 'decimal',
  }).format((number !== undefined || number !== null) ? number.toFixed(decimal) : 0);
}

export default function useNumberFormat(
  value: any,
  isDecimal: boolean = false,
  csv: boolean = false,
) {
  const decimal = isDecimal ? 2 : 0;

  if (value === null || value === undefined || value instanceof Array || typeof value === 'boolean') {
    return '';
  }

  if (typeof value === 'string') {
    const result = parseFloat(value);
    return Number.isNaN(result) ? value : Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimal,
      style: 'decimal',
    }).format(parseFloat(value.replace('.', '')));
  }

  return formatNumber(value, decimal, csv);
}
