import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const StyledIconButton = Styled.a<{disabled?: boolean}>`
  appearance: none;
  background-color: white;
  border: 0;
  border-radius: ${Theme.radius};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.4' : 'none')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: ${Theme.shadow};
  margin-left: auto;
  margin-right: auto;
  width: 75px;
  height: 75px;
`;

export const StyledImageButton = Styled.a<{disabled?: boolean, cursorAuto?: boolean}>`
  appearance: none;
  background-color: white;
  border: 0;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled || props.cursorAuto ? 'auto' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.4' : 'none')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: ${Theme.shadow};
  margin-left: auto;
  margin-right: auto;
  height: 70px;
`;

export const StyledImageButtonWrapper = Styled.div`
  display: inline-grid;
  margin-right: 30px;
  text-align: center;
`;
