import { Column } from '../interfaces/Column.d';
import useReassignColumn from './useReassignColumn';

export default function useSummaryTeamColumns(planType: string | undefined) {
  let columns:Column[] = [{}];
  if (planType && planType === 'Campaign') {
    columns = [
      { Header: 'Avísame', accessor: 'subscribed' },
      { Header: 'Campaña', accessor: 'name' },
      { Header: 'Gana', accessor: 'wins' },
      { Header: 'Participantes', accessor: 'participants' },
      { Header: 'Datos a día', accessor: 'updatedAt' },
      { Header: 'Estado', accessor: 'status' },
    ];
  } else if (planType && planType === 'Rappel') {
    columns = [
      { Header: 'Avísame', accessor: 'subscribed' },
      { Header: 'Rappel', accessor: 'name' },
      { Header: 'Importe', accessor: 'amount' },
      { Header: 'Participantes', accessor: 'participants' },
      { Header: 'Cumple', accessor: 'comply' },
      { Header: 'Datos a día', accessor: 'updatedAt' },
      { Header: 'Estado', accessor: 'status' },
    ];
  } else if (planType && planType === 'Convention') {
    columns = [
      { Header: 'Avísame', accessor: 'subscribed' },
      { Header: 'Convención', accessor: 'name' },
      { Header: 'Participa', accessor: 'participants' },
      { Header: 'Gana', accessor: 'wins' },
      { Header: 'Datos a día', accessor: 'dataDate' },
      { Header: 'Estado', accessor: 'status' },
      { Header: 'Ver resultados convención', accessor: 'results' },
    ];
  }

  const columnsExcel = columns ? useReassignColumn(columns)?.reassignColumn : [{}];

  return {
    columns,
    columnsExcel,
  };
}
