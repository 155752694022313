import { Generic } from '../interfaces/Generic.d';

export default function useLowerCaseKeys(array: Generic) {
  const data = array.map((obj: Generic) => {
    const lowerCaseObj = Object.keys(obj).reduce((acc: Generic, key: string) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {} as Generic);
    return lowerCaseObj;
  });

  return { data };
}
