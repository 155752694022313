import { gql } from 'graphql-request';

const SEND_USER_ACCESS_RECORD = gql`
  mutation sendUserAccessRecord($userId: String!, $sessionId: String!, $userIp: String!, $userAgent: String!, $visitedSection: String) {
    sendUserAccessRecord(
      userId: $userId,
      sessionId: $sessionId,
      userIp: $userIp,
      userAgent: $userAgent,
      visitedSection: $visitedSection,
    ) {
      id
    }
  }
`;

export default SEND_USER_ACCESS_RECORD;
