export default function isMediator(category: any) {
  const mediatorCategories = [
    'DELEGADOS',
    'DELEGADO',
    'AGENTES',
    'AGENTE',
    'APS',
    'GDLG',
  ];

  return mediatorCategories.includes(category);
}
