import { gql } from 'graphql-request';

const CREATE_SESSION_GQL = gql`
  mutation signinUser($email: String!, $password: String!) {
    signinUser(
      credentials: {
        email: $email,
        password: $password,
      }) {
      token
    }
  }
`;

export default CREATE_SESSION_GQL;
