import { gql } from 'graphql-request';

const APPROVE_IMPORT_GQL = gql`
  mutation validateImport($id: ID!, $valid: Boolean!) {
    validateImport(
      id: $id,
      valid: $valid
    ) {
      id
    }
  }
`;

export default APPROVE_IMPORT_GQL;
