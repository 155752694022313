import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { IFilter } from '../../../interfaces/IFilter.d';
import { IPlan } from '../../../interfaces/IPlan.d';
import Theme from '../../../styles/Theme';
import Container from '../../atoms/Container/Container';
import { LinkTable, Table } from '../../atoms/Table/Table';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import FilterModel from '../../molecules/Filter/FilterModel';
import MonthsLine from '../../molecules/MonthsLine/MonthsLine';
import GroupFilterGeneral from '../../molecules/Filter/GroupFilterGeneral';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import useRemoveUndefinedProperties from '../../../hooks/useRemoveUndefinedProperties';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_PLANS_BY_TYPE_GQL from '../../../graphql/queries/getPlansByTypeGql';

export default function RappelsCalendar() {
  const { year } = useYear();
  const graphQLClient = createGraphQLClient();
  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const selectedFilters: IFilter = { temporalidad: [], rappelType: [], year: [] };

  const rappelsParams:any = {
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    grouping: globalFilter.grouping || undefined,
    subgrouping: globalFilter.subgrouping || undefined,
    type: 'Rappel',
    year,
  };

  useRemoveUndefinedProperties(rappelsParams);

  const {
    data: planRappel,
    error: errorRappel,
    refetch: refetchRappel,
    isLoading: isLoadingRappel,
    isFetching: isFetchingRappel,
  } = useQuery(
    ['RappelCalendar'],
    async () => (graphQLClient && graphQLClient.request(
      GET_PLANS_BY_TYPE_GQL,
      rappelsParams,
    )),
  );

  const dataRappels = planRappel?.planByType?.nodes?.map((plan: IPlan) => ({
    plan: plan?.description,
    startDate: plan?.startDate && useFormatDate(plan?.startDate),
    endDate: plan?.endDate && useFormatDate(plan?.endDate),
    detail: plan?.basesUrl === '#' ? <Container>No disponible</Container> : <LinkTable url={plan?.basesUrl} title="Descargar" />,
  }));

  useEffect(() => {
    refetchRappel();
  }, [globalFilter.dateRange, globalFilter.grouping, globalFilter.subgrouping]);

  if (errorRappel) {
    return <Container>Error</Container>;
  }

  const columns = [
    { Header: 'Rappeles', accessor: 'plan' },
    { Header: 'Fecha inicio', accessor: 'startDate' },
    { Header: 'Fecha fin', accessor: 'endDate' },
    { Header: 'Detalle', accessor: 'detail' },
  ];

  return (
    <Container>
      <>
        <GroupFilterGeneral
          selectedFilters={selectedFilters}
          filter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <MonthsLine
          dateRange={globalFilter.dateRange}
        />
        {(
          !isLoadingRappel
          && !isFetchingRappel
          && columns && dataRappels) ? (
            <Table
              columns={columns}
              data={dataRappels}
              theme={Theme.colorRappel}
              className="tb__calendar"
            />
          ) : <TableSkeleton countCell={6} theme={Theme.colorRappel} />}
      </>
    </Container>
  );
}
