export default function useColumnTableUserResults() {
  const columns = [
    { Header: 'Tipo de plan', accessor: 'planType' },
    { Header: 'Planes', accessor: 'planDescription' },
    { Header: 'NIF', accessor: 'nif' },
    { Header: 'DGT', accessor: 'dgt' },
    { Header: 'DT', accessor: 'dt' },
    { Header: 'OD', accessor: 'od' },
    { Header: 'Importe', accessor: 'amount' },
    { Header: 'Gana', accessor: 'win' },
    { Header: 'Resultados', accessor: 'result' },
    { Header: 'Datos a día', accessor: 'dataDate' },
    { Header: 'Bases', accessor: 'download' },
  ];
  return { columns };
}
