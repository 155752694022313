import { memo } from 'react';
import {
  TableMainSkeleton,
  StyledTableWrapHeadSkeleton,
  TableWrapSkeleton,
  TableRowHeadSkeleton,
  TableRowSkeleton,
} from './StyledTable';

type params = {
  countCell: number;
  theme: string;
}

function TableWrapHeadSkeleton({ countCell, theme }: params) {
  return (
    <StyledTableWrapHeadSkeleton>
      {Array(countCell)
        .fill(0)
        .map((_, i: number) => <TableRowHeadSkeleton key={`${Math.random() * i}`} theme={theme} />)}
    </StyledTableWrapHeadSkeleton>
  );
}

function TableSkeleton({ countCell, theme }: params) {
  return (
    <TableMainSkeleton>
      <TableWrapHeadSkeleton key={Math.random()} theme={theme} countCell={countCell} />
      {Array(10)
        .fill(0)
        .map((_, i: number) => (
          <TableWrapSkeleton key={`${Math.random() * i}`}>
            {Array(countCell)
              .fill(0)
              .map((__, index: number) => <TableRowSkeleton key={`${Math.random() * index}`} />)}
          </TableWrapSkeleton>
        ))}
    </TableMainSkeleton>
  );
}

export default memo(TableSkeleton);
