export default function useControlledColumns(col: string) {
  if (col === 'DGT') return 'dgt';
  if (col === 'DT') return 'dt';
  if (col === 'OD') return 'od';
  if (col === 'DT') return 'dt';
  if (col === 'NIF') return 'nif';
  if (col === 'DIVISA') return 'currency';
  if (col === 'FECHA DATO') return 'FECHA ACT';

  return col;
}
