import LinkTo from '../components/atoms/Link/LinkTo';
import { Generic } from '../interfaces/Generic.d';
import Theme from '../styles/Theme';

export default function useUrlDetailComparativeMgp(
  myDataHash: Generic,
  dataCurrentUserInfo: Generic,
) {
  const codeConvention = dataCurrentUserInfo?.me?.plans?.nodes[0]?.code;

  return dataCurrentUserInfo?.me?.plans?.nodes[0]?.grouping?.name !== 'CONVENCION_RANKING'
    ? (
      <LinkTo
        to={`/global-position/convention/${myDataHash?.id}/detail/${myDataHash?.firstName}-${myDataHash?.lastName}?code=${codeConvention}&grouping=${dataCurrentUserInfo?.me?.plans?.nodes[0]?.grouping?.name}&participantId=${Number(myDataHash?.userId)}`}
        color={Theme.colorPrimary}
        style={{ textAlign: 'center', display: 'block' }}
      >
        Ver detalle
      </LinkTo>
    ) : '';
}
