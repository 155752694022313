// import useRenderSubTable, { IRenderSubTable } from '../../../hooks/useRenderSubTable';
import Theme from '../../../styles/Theme';
import IconArrow from '../../atoms/Icon/IconArrow';
import { LinkTable } from '../../atoms/Table/Table';

export const columnsGroupedResults = [
  { Header: 'DGT', accessor: 'dgt' },
  { Header: 'DT', accessor: 'dt' },
  { Header: 'OD', accessor: 'od' },
  { Header: 'Denominación', accessor: 'denomination' },
  { Header: 'NIF', accessor: 'nif' },
  { Header: 'Cat.', accessor: 'category' },
  { Header: 'R. 1T', accessor: 'r1t' },
  { Header: 'R. 2T', accessor: 'r2t' },
  { Header: 'R. 3T', accessor: 'r3t' },
  { Header: 'R. 4T', accessor: 'r4t' },
  { Header: 'R. A...', accessor: 'ra' },
  { Header: 'Total', accessor: 'total' },
];
export const columns = [
  { Header: 'DGT', accessor: 'dgt' },
  { Header: 'DT', accessor: 'dt' },
  { Header: 'OD', accessor: 'od' },
  { Header: 'Denominación', accessor: 'denomination' },
  { Header: 'NIF', accessor: 'nif' },
  { Header: 'Cat.', accessor: 'category' },
  { Header: 'Total', accessor: 'total' },
];

export const headers = [
  { label: 'Rappeles', key: 'plan' },
  { label: 'Participa', key: 'participants' },
  { label: 'Cumple', key: 'comply' },
  { label: 'Importe', key: 'amount' },
  { label: 'Situación', key: 'situation' },
  { label: 'Datos a día', key: 'dataDate' },
];

export const columnsSummary = [
  { Header: 'Avísame', accessor: 'subscribed' },
  { Header: 'Rappeles', accessor: 'plan' },
  { Header: 'Participa', accessor: 'participants' },
  { Header: 'Cumple', accessor: 'comply' },
  { Header: 'Importe', accessor: 'amount' },
  { Header: 'Estado', accessor: 'situation' },
  { Header: 'Datos a día', accessor: 'dataDate' },
  { Header: 'Detalle', accessor: 'details' },
  { Header: 'Bases', accessor: 'download' },
];

export const columnsGrouped = [
  { Header: 'DGT', accessor: 'dgt' },
  { Header: 'DT', accessor: 'dt' },
  { Header: 'OD', accessor: 'od' },
  { Header: 'Denominación', accessor: 'denomination' },
  { Header: 'NIF', accessor: 'nif' },
  { Header: 'Cat.', accessor: 'category' },
  { Header: 'Rappel 1T', accessor: 'valort1' },
  { Header: 'Rappel 2T', accessor: 'valort2' },
  { Header: 'Rappel 3T', accessor: 'valort3' },
  { Header: 'Rappel 4T', accessor: 'valort4' },
  { Header: 'Rappel Anual', accessor: 'valorAnual' },
  { Header: 'Rappel Total', accessor: 'valorTotal' },
];

export const columnsGroupedCsv = [
  { label: 'DGT', key: 'dgt' },
  { label: 'DT', key: 'dt' },
  { label: 'OD', key: 'od' },
  { label: 'Denominación', key: 'denomination' },
  { label: 'NIF', key: 'nif' },
  { label: 'Cat.', key: 'category' },
  { label: 'Rappel 1T', key: 'valort1' },
  { label: 'Rappel 2T', key: 'valort2' },
  { label: 'Rappel 3T', key: 'valort3' },
  { label: 'Rappel 4T', key: 'valort4' },
  { label: 'Rappel Anual', key: 'valorAnual' },
  { label: 'Rappel Total', key: 'valorTotal' },
];

export const columnsResults = [
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }:any) => (
      <label {...row.getToggleRowExpandedProps()}>
        <LinkTable title="Detalle" onClick={() => '#'}>
          {row.isExpanded
            ? <IconArrow color={`${Theme.colorPrimary}`} rotate />
            : <IconArrow color={`${Theme.colorPrimary}`} /> }
        </LinkTable>
      </label>
    ),
  },
];
