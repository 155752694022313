import {
  memo, useCallback, useEffect, useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import ComparativeAdapter from '../../../adapters/ComparativeAdapter';
import useExportXLSX from '../../../hooks/useExportXLSX';
import useExportStore from '../../../store/exportStore';
import Theme from '../../../styles/Theme';
import { Card } from '../../atoms/Card/Card';
import IconDownload from '../../atoms/Icon/IconDownload';
import { LoadingSpinner } from '../../atoms/Loading/Loading';

function CardDataComparativeExport(props: any) {
  const {
    data, dependency, fileName,
  } = props;

  const [dataExport, setDataExport] = useState<any>(undefined);

  const { readyToExport } = useExportStore((state) => ({
    readyToExport: state.readyToExport,
  }), shallow);

  const { setReadyToExport } = useExportStore();

  const handleExport = useCallback(() => {
    setReadyToExport(true);
  }, []);

  useEffect(() => {
    if (readyToExport === true) {
      setTimeout(() => {
        const {
          otherColumnsXlsx,
          otherData,
        } = ComparativeAdapter(data, dependency);

        if (otherColumnsXlsx) {
          setReadyToExport(false);
          setDataExport({
            otherColumnsXlsx,
            otherData,
          });
        }
      }, 600);
    }
  }, [readyToExport === true]);

  useEffect(() => {
    if (dataExport !== undefined) {
      useExportXLSX(
        dataExport.otherData,
        dataExport.otherColumnsXlsx.reassignColumn,
        fileName,
      );
    }
  }, [dataExport !== undefined]);

  return (
    <Card
      background={Theme.colorWhite}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      gap="1rem"
      margin="1rem 0 0.5rem"
    >
      <button
        type="button"
        className="btn-outline-secondary"
        onClick={() => handleExport()}
      >
        {(readyToExport === false) ? (
          <span>
            <IconDownload color="#666" width="16px" height="16px" />
            Descargar XLSX
          </span>
        ) : (
          <span>
            <LoadingSpinner color="#666" width="16px" />
            Descargando ...
          </span>
        )}
      </button>
    </Card>
  );
}

export default memo(CardDataComparativeExport);
