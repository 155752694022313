import IconCampaign from './IconCampaign';
import IconConvention from './IconConvention';
import IconGoal from './IconGoal';
import IconRanking from './IconRanking';
import IconRappel from './IconRappel';
import IconSummary from './IconSummary';
import { IIcon } from './Iicon';
import IconUser from './IconUser';
import IconBook from './IconBook';
import IconSearch from './IconSearch';

function IconMenu({
  color, height, type, width,
}: IIcon):any {
  switch (type) {
  case 'summary':
    return <IconSummary color={color} height={height} width={width} />;
  case 'summary/graphics':
    return <IconSummary color={color} height={height} width={width} />;
  case 'campaign':
    return <IconCampaign color={color} height={height} width={width} />;
  case 'rappel':
    return <IconRappel color={color} height={height} width={width} />;
  case 'convention':
    return <IconConvention color={color} height={height} width={width} />;
  case 'ranking':
    return <IconRanking color={color} height={height} width={width} />;
  case 'goal':
    return <IconGoal color={color} height={height} width={width} />;
  case 'user':
    return <IconUser color={color} height={height} width={width} />;
  case 'book':
    return <IconBook color={color} height={height} width={width} />;
  case 'search':
    return <IconSearch color={color} height={height} width={width} />;
  default:
    return null;
  }
}

export default IconMenu;
