import {
  IPagination,
  IPaginationButton,
} from './IPagination';
import {
  StyledPagination,
  StyledPaginationButton,
} from './StyledPagination';

export function Pagination(props: IPagination) {
  const {
    children,
    className,
  } = props;

  return (
    <StyledPagination className={className}>
      {children}
    </StyledPagination>
  );
}

export function PaginationButton({
  label,
  onClick,
  disabled,
  active,
  className,
}: IPaginationButton) {
  return (
    <StyledPaginationButton
      disabled={disabled}
      active={active}
      onClick={onClick}
      className={className}
    >
      {label}
    </StyledPaginationButton>
  );
}
