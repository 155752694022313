import Theme from '../styles/Theme';
import useGlobalPositionCharts from './useGlobalPositionCharts';
import useNumberFormat from './useNumberFormat';
import useSummaryConvention from './useSummaryConvention';
import usePlan from './usePlan';

export default function useSummaryGlobalPosition(variables: any) {
  const {
    data: campaign,
    refetch: refetchCampaign,
    isFetching: isFetchingCampaign,
  } = useGlobalPositionCharts(variables);

  const {
    data: rappel,
    refetch: refetchRappel,
    isFetching: isFetchingRappel,
  } = useGlobalPositionCharts(variables);

  const {
    data: convention,
    refetch: refetchConvention,
    isFetching: isFetchingConvention,
  } = useGlobalPositionCharts(variables);

  const processRankingGral = (obj:any) => {
    if (obj?.extraFields?.['RANKING GANADORES']) {
      return obj?.extraFields?.['RANKING GANADORES'];
    }
    if (obj?.extraFields?.['RANKING GRAL']) {
      return obj?.extraFields?.['RANKING GRAL'];
    }
    if (obj?.extraFields?.['RANKING GLOBAL']) {
      return obj?.extraFields?.['RANKING GLOBAL'];
    }
    if (obj?.extraFields?.['Ranking ']) {
      return obj?.extraFields?.['Ranking '];
    }
    if (obj?.extraFields?.RANKING) {
      return obj?.extraFields?.RANKING;
    }
    return obj?.extraFields?.['RANKING GRAL'];
  };

  const processRankingDgt = (obj:any) => {
    if (obj?.extraFields?.['Posición  DGT']) {
      return obj?.extraFields?.['Posición  DGT'];
    }
    if (obj?.extraFields?.['RANKING DGT']) {
      return obj?.extraFields?.['RANKING DGT'];
    }
    if (obj?.extraFields?.['Ranking DGT']) {
      return obj?.extraFields?.['Ranking DGT'];
    }
    return obj?.extraFields?.['RANKING DGT'];
  };

  const processConvention = (obj: any) => ({
    totalGeneral: obj?.participantsCount,
    totalDgt: obj?.participantsDgtCount,
    dataDate: obj?.dataDate,
    wins: obj?.extraFields?.GANA || obj?.extraFields?.Gana ? obj?.extraFields?.GANA || obj?.extraFields?.Gana : 'No',
    type: obj?.grouping,
    rankingGeneral: processRankingGral(obj),
    rankingDgt: processRankingDgt(obj),
    milesTotal: obj?.extraFields?.['MIN MILLAS'],
    miles: obj?.extraFields?.['MILLAS TOTAL'],
  });

  if (variables.type === 'Convention') {
    const { data: plan } = usePlan(variables);
    const { data: summary } = useSummaryConvention(variables);
    const summaryGlobalPositionChartByTimeframe = convention?.summaryGlobalPositionChartByTimeframe;
    const newConvention = {
      ...plan?.me?.plans?.nodes?.filter((p: any) => p.type === 'Convention')[0]?.results?.nodes?.filter((p: any) => p.userId === variables.userId)[0],
      ...plan?.me?.plans?.nodes?.filter((p: any) => p.type === 'Convention')[0],
      ...summary?.summaryConvention,
    };
    if (convention?.summaryGlobalPositionChartByTimeframe !== undefined) {
      let data = processConvention(newConvention);
      data = { ...summaryGlobalPositionChartByTimeframe, ...data };
      convention.summaryGlobalPositionChartByTimeframe = data;
    }
  }

  const rc = campaign?.summaryGlobalPositionChartByTimeframe;
  const rr = rappel?.summaryGlobalPositionChartByTimeframe;
  const rcv = convention?.summaryGlobalPositionChartByTimeframe;

  const currentYear = new Date().getFullYear();

  function getScore(hashData:any) {
    if (variables.timeframe) {
      switch (variables?.timeframe) {
      case 'ANUAL':
        return hashData.Anual;
      case 'SEMESTRAL':
        return hashData.Semestral;
      case 'INTERANUAL':
        return hashData.Interanual;
      case 'MENSUAL':
        return hashData.Mensual;
      case 'TRIMESTRAL':
        if (variables?.startDate === `${currentYear}-01-01`) {
          return hashData['1T'];
        } if (variables?.startDate === `${currentYear}-04-01`) {
          return hashData['2T'];
        } if (variables?.startDate === `${currentYear}-07-01`) {
          return hashData['3T'];
        }
        return hashData['4T'];
      default:
        return hashData?.total;
      }
    }
    return hashData?.total;
  }

  const formatNumber = (number: any) => parseFloat(number).toFixed(0);

  const SummaryCampaignGlobalPosition = {
    id: 0,
    title: 'Campañas',
    amount: useNumberFormat(getScore(rc?.totalAmount[0]), true),
    type: 'progressbar',
    theme: `${Theme.colorCampaign}`,
    slug: 'campaign',
    stadistics: [
      {
        id: 0, label: 'Participa', score: getScore(rc?.participantsCount[0]), total: getScore(rc?.total[0]),
      },
      {
        id: 1, label: 'Gana', score: getScore(rc?.totalPlanWinCount[0]), total: getScore(rc?.total[0]),
      },
      {
        id: 2, label: 'Tréboles', score: getScore(rc?.totalTreboles[0]), total: getScore(rc?.total[0]),
      },
      {
        id: 3, label: 'Puntos', score: useNumberFormat(getScore(rc?.points[0]), true), total: useNumberFormat(getScore(rc?.total[0]), true),
      },
    ],
  };

  const SummaryRappelGlobalPosition = {
    id: 1,
    title: 'Rappeles',
    amount: useNumberFormat(getScore(rr?.totalAmount[0]), true),
    type: 'progressbar',
    theme: `${Theme.colorRappel}`,
    slug: 'rappel',
    stadistics: [
      {
        id: 0,
        label: 'Participa',
        score: getScore(rr?.participantsCount[0]),
        total: getScore(rr?.total[0]),
      },
      {
        id: 2,
        label: 'Cumple',
        score: getScore(rr?.totalPlanMetCount[0]),
        total: getScore(rr?.total[0]),
      },
    ],
  };

  const SummaryConventionGlobalPosition = {
    typeData: rcv?.type,
    id: 2,
    title: 'Convención',
    gain: rcv?.wins,
    type: 'half-circle',
    date: rcv?.dataDate,
    theme: `${Theme.colorConvention}`,
    slug: 'convention',
    stadistics: [
      {
        id: 0,
        label: 'Ranking Gral',
        score: rcv?.rankingGeneral !== undefined ? rcv?.rankingGeneral : 0,
        total: rcv?.totalGeneral !== undefined ? rcv?.totalGeneral : 0,
      },
      {
        id: 1,
        label: 'Ranking DGT',
        score: rcv?.rankingDgt !== undefined ? rcv?.rankingDgt : 0,
        total: rcv?.totalDgt !== undefined ? rcv?.totalDgt : 0,
      },
    ],
  };

  if (rcv?.type === 'CONVENCION_MILLAS') {
    const obj = {
      id: 2,
      label: 'Millas',
      score: rcv?.miles !== undefined ? formatNumber(rcv?.miles) : 0,
      total: rcv?.milesTotal !== undefined ? formatNumber(rcv?.milesTotal) : 0,
    };
    SummaryConventionGlobalPosition.stadistics.push(obj);
  }

  if (variables?.myTeam) {
    const obj = {
      id: 0,
      label: 'Gana',
      score: getScore(rcv?.participantsCount[0]),
      total: getScore(rcv?.totalPlanWinCount[0]),
    };
    SummaryConventionGlobalPosition.stadistics = [obj];
  }

  const SummaryRankingGlobalPosition = {
    id: 3,
    title: 'Ranking',
    type: 'scale',
    date: '12-02-2022',
    theme: `${Theme.colorRanking}`,
    slug: 'ranking',
    stadistics: [
      { id: 0, score: 7, total: 10 },
    ],
  };

  return {
    SummaryCampaignGlobalPosition,
    SummaryRappelGlobalPosition,
    SummaryConventionGlobalPosition,
    SummaryRankingGlobalPosition,
    isFetchingCampaign,
    isFetchingRappel,
    isFetchingConvention,
    refetchCampaign,
    refetchRappel,
    refetchConvention,
  };
}
