import { useNavigate } from 'react-router-dom';
import useImpersonate from '../../../hooks/useImpersonate';
import Typography from '../../atoms/Typography/Typography';
import {
  PreviewerContainer,
  ClosePreviewButton,
} from './StyledImpersonate';

export default function Impersonate() {
  const { impersonating, impersonatedUser } = useImpersonate();
  const navigate = useNavigate();

  const stopPreviewing = () => {
    localStorage.removeItem('impersonateUser');
    navigate('/admin/users', { replace: true });
    window.location.reload();
  };

  return (
    impersonating()
      ? (
        <PreviewerContainer>
          <Typography size="14px" margin="0 auto 1rem auto">
            Estás impersonando a
            {' '}
            <br />
            {`${impersonatedUser?.firstName} ${impersonatedUser?.lastName}`}
          </Typography>
          <ClosePreviewButton onClick={() => stopPreviewing()}>
            Dejar de impersonar
          </ClosePreviewButton>
        </PreviewerContainer>
      )
      : null
  );
}
