import { StyledBannerArticle } from '../../organisms/Home/StyledHome';

type Params = {
  title: string;
  paragraph: string;
  image: string | undefined;
  cardsCount: number;
}

export default function BannerArticle(props: Params) {
  const {
    title,
    paragraph,
    image,
    cardsCount,
  } = props;

  return (
    <StyledBannerArticle cardsCount={cardsCount}>
      <>
        <h2>{title}</h2>
        {image && <img src={image} alt="" width="80" />}
      </>
      <p>{paragraph}</p>
    </StyledBannerArticle>
  );
}
