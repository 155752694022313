import { useState, useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { columnsSummary, headers } from './dataConventionTeam';
import Theme from '../../../styles/Theme';
import { Tabs } from '../../atoms/Tabs';
import { LinkTable, Table } from '../../atoms/Table/Table';
import { Card } from '../../atoms/Card/Card';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import LinkTo from '../../atoms/Link/LinkTo';
import SkeletonCard from '../../atoms/Card/SkeletonCard';
import TableSkeleton from '../../atoms/Table/TableSkeleton';
import FilterModel from '../../molecules/Filter/FilterModel';
import Score from '../../molecules/Score/Score';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import ParamsModel from '../../models/PaginationModel';
import SubscriptionButton from '../../molecules/Subscriptios/SubscriptionButton';
import FilterFormSkeleton from '../../organisms/FilterForm/FilterFormSkeleton';
import FilterForm from '../../organisms/FilterForm/FilterForm';
import useYear from '../../../hooks/useYear';
import useFormatDate from '../../../hooks/useDate';
import useCsvNumber from '../../../hooks/useCsvNumber';
import useNumberFormat from '../../../hooks/useNumberFormat';
import { getGraphQLClient } from '../../../graphql/graphqlClient';
import GET_PLAN_COUNT_GQL from '../../../graphql/queries/getPlanCounterGql';
import GET_MY_TEAM_DATA_FILTERS_GQL from '../../../graphql/queries/getMyTeamDataFiltersGql';
import GET_PLAN_PARTICIPANTS_WIN_GQL from '../../../graphql/queries/getPlanParticipantsWinGql';
import GET_MY_TEAM_PLANS_BY_PARAMS_GQL from '../../../graphql/queries/getMyTeamPlansByParamsGql';

export interface TeamConventionParams {
  id: string;
  user: any;
}

export default function ConventionTeam({ id, user }:TeamConventionParams) {
  const type = 'Convention';
  const userId = user?.me?.id;
  const graphQLClient = getGraphQLClient();
  const { year } = useYear();
  const { grouping } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState<FilterModel>(new FilterModel());
  const [globalParams, setGlobalParams] = useState<ParamsModel>(new ParamsModel());
  const [activeTab, setActiveTab] = useState(grouping || id || '');
  const [activeSubtab, setActiveSubtab] = useState('');

  const conventionParams = {
    type,
    timeframe: (globalFilter.dateRange && globalFilter.dateRange.split(',')[0]) ? globalFilter.dateRange.split(',')[0].toString() : undefined,
    startDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[1]) ? globalFilter.dateRange.split(',')[1].toString() : undefined,
    endDate: (globalFilter.dateRange && globalFilter.dateRange.split(',')[2]) ? globalFilter.dateRange.split(',')[2].toString() : undefined,
    category: globalFilter.category || undefined,
    dgt: globalFilter.dgt || undefined,
    dt: globalFilter.dt || undefined,
    od: globalFilter.od || undefined,
    group: globalFilter.group || undefined,
    year,
    firstName: globalFilter.firstName || undefined,
    lastName: globalFilter.lastName || undefined,
    nif: globalFilter.nif || undefined,
    grouping: activeTab !== 'resumen' ? activeTab.toUpperCase() : undefined,
    subgrouping: activeSubtab !== 'resumen' ? activeSubtab?.toUpperCase() : '',
    search: globalFilter.search || '',
    status: globalFilter.status || '',
    planStatus: globalFilter.status || '',
    numberElements: globalParams?.numberElements || 10,
    numberPage: globalParams?.numberPage || 1,
    order: globalParams?.order || 'name',
    orderType: globalParams?.orderType || 'desc',
    myTeam: true,
  };

  const {
    data: dataCount,
    isFetching: isFetchingCount,
    refetch: refetchCount,
    status: statusCount,
  } = useQuery(
    ['ConventionTeamCount', conventionParams],
    async () => (graphQLClient && graphQLClient.request(GET_PLAN_COUNT_GQL, conventionParams)),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: dataFilters,
    error: errorFilters,
    isFetching: isFetchingFilters,
  } = useQuery(
    ['ConventionTeamFilters', { type, year }],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_DATA_FILTERS_GQL,
      { type, year },
    )),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: planConvention,
    error: errorConvention,
    refetch: refetchConvention,
    isFetching: isFetchingConvention,
    status: statusConvention,
  } = useQuery(
    ['ConventionTeam', conventionParams],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      conventionParams,
    )),
    {
      staleTime: Infinity,
    },
  );

  const conventionParamsCSV = {
    ...conventionParams,
    numberElements: undefined,
  };

  const {
    data: planConventionCSV,
    refetch: refetchConventionCSV,
    isFetching: isFetchingConventionCSV,
    status: statusConventionCSV,
  } = useQuery(
    ['ConventionTeamXlsx', conventionParamsCSV],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_TEAM_PLANS_BY_PARAMS_GQL,
      conventionParamsCSV,
    )),
    {
      staleTime: Infinity,
    },
  );

  const variablesChartByTimeframe = {
    myTeam: true,
    type,
    year,
    grouping: activeTab !== 'resumen' ? activeTab.toUpperCase() : undefined,
    status: globalFilter.status ? globalFilter.status : undefined,
  };

  const {
    data: dataChartByTimeframe,
    isFetching: isFetchingChartByTimeframe,
  } = useQuery(
    ['ConventionTeamResults', variablesChartByTimeframe],
    async () => (graphQLClient
      && graphQLClient.request(GET_PLAN_PARTICIPANTS_WIN_GQL, variablesChartByTimeframe)),
    {
      staleTime: Infinity,
    },
  );

  const groupingList: any = queryClient.getQueryState(['GroupingList']);
  const list = groupingList?.data?.groupingList?.grouping;
  const tabs = list ? list[year].Convention : [];

  useEffect(() => {
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
  }, []);

  useEffect(() => {
    navigate(`/team/convention/${activeTab}`, { replace: true });
    setGlobalFilter({ ...globalFilter, grouping: activeTab });
    setGlobalParams({ ...globalParams, numberPage: 1 });

    const completeActiveTab :any = tabs.find((tab: any) => tab.id === activeTab);
    if (completeActiveTab?.subgroupings) {
      setActiveSubtab(completeActiveTab?.subgroupings[0].id);
    }
  }, [activeTab]);

  useEffect(() => {
    if (statusCount !== 'success') {
      refetchCount();
    }
    if (statusConvention !== 'success' && statusCount !== 'success') {
      refetchConvention();
    }
    if (statusConventionCSV !== 'success' && statusCount !== 'success') {
      refetchConventionCSV();
    }
  }, [globalFilter]);

  const debouncedSearch = (value :any, currentGlobalFilter: FilterModel) => {
    setGlobalFilter({ ...currentGlobalFilter, search: value });
  };

  const setServicesValueDebounced = useRef(_.debounce(debouncedSearch, 1000));

  const handleChange = (e :any) => {
    setServicesValueDebounced.current(e.currentTarget.value, globalFilter);
  };

  const dataConvention = planConvention?.myTeamPlans?.nodes?.map((c:any) => ({
    conventionName: c?.planDescription,
    participants: useNumberFormat(c?.participantsNumber),
    wins: c?.winsNumber,
    amount: useNumberFormat(c?.resultsAmount),
    dataDate: useFormatDate(c?.planDataDate),
    status: c.planStatusName,
    conventionResults: <LinkTo to={`/team/convention/resumen/${c?.planCode}`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver resultados</LinkTo>,
    timeframe: c?.planTimeframe,
    startDate: c?.planStartDate,
    endDate: c?.planEndDate,
    grouping: c?.planGrouping,
    subscribed: <SubscriptionButton
      subscribed={c.planSubscribed}
      planCode={c.planCode}
      userId={userId}
    />,
    download: <LinkTable url={c?.planBasesUrl} title="Descargar" />,
  }));

  const dataConventionCSV = planConventionCSV?.myTeamPlans?.nodes?.map((c:any) => ({
    conventionName: c?.planDescription,
    participants: useNumberFormat(c?.participantsNumber),
    wins: c?.winsNumber,
    amount: useNumberFormat(c?.resultsAmount),
    dataDate: useFormatDate(c?.planDataDate),
    status: c.planStatusName,
    conventionResults: <LinkTo to={`/team/convention/${c?.planCode}`} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver resultados</LinkTo>,
    timeframe: c?.planTimeframe,
    startDate: c?.planStartDate,
    endDate: c?.planEndDate,
    grouping: c?.planGrouping,
  }));

  const processDataConvention = dataConventionCSV?.map((d: any) => ({ ...d }));

  processDataConvention?.forEach((item: any, index: number) => {
    Object.entries(item).forEach(([key, value]: any) => {
      const decimals = false;
      processDataConvention[index][key] = useCsvNumber(value, decimals);
    });
  });

  const scoreIndicators = () => {
    const results = dataChartByTimeframe?.planParticipantsWin;
    const score = [
      {
        id: 0,
        title: 'Participa',
        subtitle: '',
        score: (results?.participantsCount?.[0]?.Anual || ''),
        icon: 'participant',
      },
      {
        id: 1,
        title: 'Ganan',
        subtitle: '',
        score: (results?.totalUserWinCount?.[0]?.Anual || ''),
        icon: 'cup',
      },
    ];
    return score;
  };

  if (errorConvention || errorFilters) {
    return <Container>Error</Container>;
  }

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/team/summary/general')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorConvention} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Convenciones</Typography>

      <Card background="transparent" display="flex" alignItems="center" justifyContent="space-between">
        <input
          type="text"
          placeholder="Buscar por nombre de convención"
          onChange={handleChange}
          onPaste={handleChange}
          className="search"
          style={{ height: '38px', maxWidth: '450px', margin: '0 0 1rem' }}
        />
      </Card>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={setActiveTab}
        activeSubtab={activeSubtab}
        onChangeSubtab={setActiveSubtab}
        theme={Theme.colorConvention}
      />

      {!isFetchingFilters ? (
        <FilterForm
          dataFilters={dataFilters}
          filter={globalFilter}
          setFilter={setGlobalFilter}
          type={type}
          fetching={isFetchingConvention}
        />
      ) : <FilterFormSkeleton countFilters={10} />}

      {headers && processDataConvention && !isFetchingConventionCSV && (
        <CardDataExportXLSX
          headers={headers}
          data={processDataConvention}
          name="convention_team"
        />
      )}

      {!isFetchingCount && !isFetchingConvention && dataConvention ? (
        <Table
          columns={columnsSummary}
          data={dataConvention}
          theme={Theme.colorConvention}
          defaultPageSize={10}
          countsRecords={dataCount?.planCounter?.counter}
          countPerPage={10}
          globalParams={globalParams}
          setGlobalParams={setGlobalParams}
        />
      ) : <TableSkeleton countCell={6} theme={Theme.colorConvention} />}

      {!isFetchingChartByTimeframe ? (
        <Score score={scoreIndicators()} cols={2} maxWidth="260px" margin="0 auto 4rem auto" />
      ) : (<SkeletonCard cols={2} maxWidth="260px" />)}
    </Container>
  );
}
