import ILink from './ILink';
import { StyledNavLink } from './StyledLink';

export default function NavLinkTo(props: ILink) {
  const {
    to,
    children,
    target,
    color,
    size,
    weight,
    padding,
    onClick,
    border,
    ...otherProps
  } = props;

  return (
    <StyledNavLink
      to={to}
      target={target}
      color={color}
      size={size}
      weight={weight}
      padding={padding}
      onClick={onClick}
      border={border}
      {...otherProps}
    >
      {children}
    </StyledNavLink>
  );
}
