import { useNavigate } from 'react-router-dom';
import sessionStore, { getTokenFromStore } from '../store/sessionStore';
import sessionIdentifierStore from '../store/sessionIdentifierStore';

export default function useLogoutInactivity(min: number) {
  const navigate = useNavigate();
  const { clearSession } = sessionStore();
  const { clearIdSession } = sessionIdentifierStore();

  document.addEventListener('mousemove', () => {
    localStorage.setItem('lastActivity', new Date().toString());
  });

  document.addEventListener('click', () => {
    localStorage.setItem('lastActivity', new Date().toString());
  });

  document.addEventListener('keypress', () => {
    localStorage.setItem('lastActivity', new Date().toString());
  });

  const logout = () => {
    const auth = getTokenFromStore();

    if (auth !== undefined && auth !== null) {
      setTimeout(() => {
        clearSession();
        clearIdSession();
        localStorage.removeItem('impersonateUser');
        localStorage.removeItem('year');
        navigate('/home/summary', { replace: true });
      }, 2000);
    }
  };

  const timeInterval = setInterval(() => {
    const lastActivity = new Date(localStorage.getItem('lastActivity')!);
    const now = new Date();
    const diffMs = Math.abs(+lastActivity - +now);
    const seconds = Math.floor((diffMs / 1000));
    const minute = Math.floor((seconds / 60));
    if (minute >= min) {
      logout();
      clearInterval(timeInterval);
    }
  }, 3000);
}
