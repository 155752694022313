/* eslint no-nested-ternary: 0 */
import {
  usePagination, useTable, useSortBy, useExpanded,
} from 'react-table';
import {
  useEffect,
  useState,
  Fragment,
} from 'react';
import {
  HeaderRow,
  Row,
  StyledSortButton,
  StyledWrapTable,
  StyledTable,
  StyledTableContainer,
  TableBody,
  TableHead,
  Cell,
  HeaderCell,
  ViewMore,
  DownloadLink,
  CellInner,
} from './StyledTable';
import { ISortButton, IViewMore } from './ITable';
import { Pagination, PaginationButton } from '../Pagination/Pagination';
import Theme from '../../../styles/Theme';
import IconEye from '../Icon/IconEye';
import Typography from '../Typography/Typography';
import TableResults from './TableResults';
import ParamsModel from '../../models/PaginationModel';

export function LinkTable({
  url, title, children, underline, onClick,
}: IViewMore) {
  if (url) {
    return (
      <DownloadLink href={url} target="_blank" underline={underline} rel="nofollow noreferrer">
        {title}
        <IconEye color={`${Theme.colorPrimary}`} />
      </DownloadLink>
    );
  }

  return (
    <ViewMore onClick={onClick} underline={underline}>
      {title}
      {children}
    </ViewMore>
  );
}

export function SortButton(props: ISortButton) {
  const {
    className,
    isSortedDesc,
    column,
    updateParams,
    activePage,
  } = props;

  useEffect(() => {
    updateParams({
      numberPage: activePage,
      order: column.id,
      // eslint-disable-next-line no-nested-ternary
      orderType: !column.isSorted ? '' : column.isSortedDesc ? 'desc' : 'asc',
    });
  }, [column.isSortedDesc]);

  return <StyledSortButton type="button" className={className}>{isSortedDesc ? '▼' : '▲'}</StyledSortButton>;
}

export function Table({
  columns,
  data,
  theme,
  renderRowSubComponent,
  className,
  forceFullWidth,
  defaultPageSize,
  countsRecords,
  countPerPage,
  globalParams,
  setGlobalParams,
  isSubTable,
}: any) {
  const [newColumns, setNewColumns] = useState(columns);
  const puestoDGT = '00168';
  const isChildTable = isSubTable === true;

  useEffect(() => {
    if (data[0]) {
      const arrayPuestos = data.map(
        (d:any) => d?.puestoId,
      ).filter((item:any, i:any, ar:any) => ar.indexOf(item) === i);
      const auxColumns = columns.map((col: any) => {
        const auxCol = col;

        if ((arrayPuestos.length === 1 && arrayPuestos.includes(puestoDGT)) && (col.accessor === 'denomination' || col.accessor.toLowerCase() === 'nif')) {
          return null;
        }
        if (data[0] && (typeof data[0][col.accessor]) === 'string' && (/^[0-9]*$/.test((data[0][col.accessor])
          ?.replace('.', '')
          ?.replace(',', '')
          ?.replace('%', '')))) {
          auxCol.sortType = (
            rowA: { original: { [x: string]: string; }; },
            rowB: { original: { [x: string]: string; }; },
          ) => {
            const auxA = Number(rowA?.original[col?.accessor]?.replace('.', '')?.replace(',', '.'));
            const auxB = Number(rowB?.original[col?.accessor]?.replace('.', '')?.replace(',', '.'));
            if (auxA > auxB) return -1;
            if (auxB > auxA) return 1;
            return 0;
          };
        }
        return auxCol;
      });
      setNewColumns(auxColumns.filter((x: null) => x !== null));
    }
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    visibleColumns,
    state: { pageIndex },
  } = useTable(
    {
      columns: newColumns,
      data,
      initialState: { pageIndex: 0, pageSize: defaultPageSize ?? 10 },
      autoResetSortBy: false,
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  const [visiblePages, setVisiblePages] = useState<any>([]);
  const [activePage, setActivePage] = useState<number>(pageIndex + 1);
  const filterPages = (list: any, total: number) => list.filter((item: number) => item <= total);
  const [emptyHeaders, setEmptyHeaders] = useState<any>([]);

  const countPages = countPerPage !== undefined
    ? Math.ceil(countsRecords / countPerPage)
    : Math.ceil(parseInt(data.length, 10) / (defaultPageSize ?? 10));

  const [, setGlobalParamsAux] = useState<ParamsModel>(new ParamsModel());

  const updateParams = setGlobalParams !== undefined
    ? (params: ParamsModel) => setGlobalParams(params)
    : (params: ParamsModel) => setGlobalParamsAux(params);

  function getVisiblePages(numberPage: number, total: number) {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    }
    if (numberPage % 5 >= 0 && numberPage > 4 && numberPage + 2 < total) {
      return [1, numberPage - 1, numberPage, numberPage + 1, total];
    } if (numberPage % 5 >= 0 && numberPage > 4 && numberPage + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }

    const arrayFirst = [];
    const arrayLast = [];
    for (let i = numberPage; i > (numberPage - 3); i -= 1) {
      if (numberPage > 3) {
        arrayFirst.push(i);
      }
    }
    for (let i = (numberPage + 1); i < (numberPage + 4); i += 1) {
      arrayLast.push(i);
    }
    let array = [...arrayFirst];
    if (!array.includes(numberPage)) {
      array = [...array, 1, 2, 3];
    }
    array.sort();

    if (!array.includes(numberPage)) {
      array.push(numberPage);
    }
    if (numberPage <= 3) {
      array = [...array, 4, 5, 6];
    }
    if (numberPage > 3) {
      array = [...array, ...arrayLast];
    }
    array.sort(
      (a, b) => {
        if (a === Infinity) return 1;
        if (Number.isNaN(a)) return -1;
        return a - b;
      },
    );

    array = [...array, total];
    const aux: any[] = [];
    array.forEach((a) => {
      if (!aux.includes(a)) {
        aux.push(a);
      }
    });
    array = aux;
    return array;
  }

  function changePage(currentPage: number, cursor: string) {
    if ((cursor === 'prev' || cursor === 'next') && setGlobalParams !== undefined) {
      updateParams({
        numberPage: currentPage,
        orderType: globalParams?.orderType,
        order: globalParams?.order,
      });
    }
    const activePageCurrent = pageIndex + 1;
    setActivePage(currentPage);
    if (currentPage === activePageCurrent) {
      return;
    }

    setVisiblePages(filterPages(getVisiblePages(currentPage, countPages), countPages));

    gotoPage(currentPage - 1);
  }

  useEffect(() => {
    setVisiblePages(getVisiblePages(0, countPages).map((pageNumber: number) => pageNumber));
  }, []);

  useEffect(() => {
    setActivePage(globalParams?.numberPage || 1);
    setVisiblePages(
      getVisiblePages(globalParams?.numberPage || 1, countPages)
        .map((pageNumber: number) => pageNumber),
    );
  }, [data]);

  useEffect(() => {
    const allH = headerGroups.map((hG) => hG.headers.map((header) => header.id)).flat();
    const empty: string[] = [];
    for (let counter = 0; counter < allH.length; counter += 1) {
      const au = data.map(
        (dt: { [x: string]: any; }) => dt[allH[counter]],
      ).filter((x: null) => (x !== null && x !== ''));

      if (au.length === 0) {
        empty.push(allH[counter]);
      }
    }
    setEmptyHeaders(empty);
  }, [data]);

  const handleShowSorting = (column: any) => {
    if (column.isSorted) {
      return (
        <SortButton
          isSortedDesc={column.isSortedDesc}
          column={column}
          updateParams={updateParams}
          activePage={activePage}
        />
      );
    }
    return '';
  };

  const getResultId = (row: any) => {
    const result = row?.values?.resultId || row?.original?.resultId;
    return result?.length > 0 && result !== null ? result : false;
  };

  const centerHeaders = ['GANA', 'PARTICIPA', 'SITUACIÓN', 'ESTADO', 'CUMPLE', 'RANKING'];

  const getClass = (cell: any) => {
    if (centerHeaders?.includes(cell?.column?.Header.toUpperCase())) {
      return 'align-center';
    }
    if (!centerHeaders?.includes(cell?.column?.Header.toUpperCase())) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= centerHeaders.length; i++) {
        if (cell?.column?.Header.toUpperCase().includes(centerHeaders[i])) {
          return 'align-center';
        }
      }
    }
    if (/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/.test(cell?.value)) {
      return 'align-center';
    }
    if (cell?.value !== undefined && typeof (cell?.value) === 'number') {
      return 'align-right';
    }
    if ((typeof (cell?.value) === 'string') && cell?.value.startsWith('-')) {
      return 'align-right';
    }
    if ((typeof (cell?.value) === 'string') && cell?.value.includes('%')) {
      return 'align-right';
    }
    if ((typeof (cell?.value) === 'string') && /^[0-9]*$/.test(cell?.value.split('.').join('').split(',').join('')
      .split('%')
      .join(''))) {
      return 'align-right';
    }
    return '';
  };

  return (
    <StyledTableContainer
      className={isChildTable ? (headerGroups[0]?.headers?.length > 8 || forceFullWidth ? 'subtable-container' : 'subtable-container subtable-width') : 'table-container'}
      numColumns={forceFullWidth ? undefined : headerGroups[0]?.headers?.length}
    >
      <StyledWrapTable className={className}>
        <StyledTable {...getTableProps()}>
          <TableHead className={isChildTable ? 'index-subtable' : ''}>
            {headerGroups.map((headerGroup) => (
              <HeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  !emptyHeaders.includes(column.id) && column.Header !== 'resultId' && column.Header !== 'puestoId' && (
                    <HeaderCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      theme={theme}
                      className={index === 0 && isChildTable ? 'index-subtable' : (index === 0 ? 'first-cell' : '')}
                    >
                      {column.render('Header')}
                      {handleShowSorting(column)}
                    </HeaderCell>
                  )
                ))}
              </HeaderRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.length ? page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.id}>
                  <Row {...row.getRowProps()}>
                    {row.cells.map((cell: any, index: any) => (
                      !emptyHeaders.includes(cell.column.id) && cell.column.Header !== 'resultId' && cell.column.Header !== 'puestoId' && (
                        <Cell {...cell.getCellProps()} className={index === 0 && isChildTable ? 'index-subtable' : (index === 0 ? 'first-cell' : '')}>
                          {headerGroups.map((headerGroup: any) => (
                            headerGroup.headers.map((column: any) => (
                              <b key={column.id}>{column.Header}</b>
                            )).filter((_:any, i:number) => i === index)
                          ))}
                          <span className={`${getClass(cell)}`}>
                            {cell.render('Cell')}
                          </span>
                          <span className="tooltip">{cell.render('Cell')}</span>
                        </Cell>
                      )
                    ))}
                  </Row>
                  {row.isExpanded ? (
                    <tr>
                      <CellInner colSpan={visibleColumns?.length}>
                        {getResultId(row)
                          ? (
                            <TableResults
                              resultId={getResultId(row)}
                              parent={columns.length}
                            />
                          )
                          : renderRowSubComponent({ row }) }
                      </CellInner>
                    </tr>
                  ) : null}
                </Fragment>
              );
            }) : (
              <Row>
                <Cell colSpan={visibleColumns.length}>
                  <Typography display="block" align="center" margin="1rem 0" size="16px"> No hay datos</Typography>
                </Cell>
              </Row>
            )}
          </TableBody>
        </StyledTable>
      </StyledWrapTable>
      {visiblePages.length >= 2 && (
        <Pagination>
          <PaginationButton
            label="< Atrás"
            onClick={
              () => { changePage(activePage - 1, 'prev'); }
            }
            disabled={activePage === 1}
          />

          {visiblePages.map((pageItem: number, index: number, array: any) => (
            <Fragment key={pageItem}>
              {array[index - 1] + 1 < pageItem ? (
                <span>.....</span>
              ) : null}
              <PaginationButton
                key={pageItem}
                label={array[index - 1] + 2 < pageItem ? `${pageItem}` : pageItem}
                onClick={() => { changePage(pageItem, 'next'); }}
                active={pageItem === activePage}
              />
            </Fragment>
          ))}
          <PaginationButton
            label="Siguiente >"
            onClick={() => { changePage(activePage + 1, 'next'); }}
            disabled={activePage === countPages}
          />
        </Pagination>
      )}
    </StyledTableContainer>
  );
}
