import { useQuery } from 'react-query';
import GET_CURRENT_USER_DATA_INFO_GQL from '../graphql/queries/getCurrentUserDataInfoGql';
import { createGraphQLClient } from '../graphql/graphqlClient';

export interface IData {
  type: string,
  grouping?: string,
  timeframe?: string,
  startDate?: string,
  endDate?: string,
  level?: number,
  myTeam?: boolean,
  year?: number,
  status?: string,
}

export default function usePlan(options: IData) {
  const graphQLClient = createGraphQLClient();

  return useQuery(
    [options.type],
    async () => (graphQLClient && graphQLClient.request(GET_CURRENT_USER_DATA_INFO_GQL, options)),
  );
}
