import { gql } from 'graphql-request';

const GET_USER_SEARCH_GQL = gql`
  query MyQuery($query: String, $year: Int, $numberElements: Int, $numberPage: Int, $order: String, $orderType: String) {
    userSearch(query: $query, year: $year, numberElements: $numberElements, numberPage: $numberPage, order: $order, orderType: $orderType) {
      id
      email
      nuuma
      nif
      firstName
      lastName
      counts
      calculatedCategory(year: $year)
    }
  }
`;

export default GET_USER_SEARCH_GQL;
