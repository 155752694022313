import { IImage, IWrapImage } from './IImage';
import { ImageFull, ImageRegular, StyledWrapImage } from './StyledImage';

export function Image(props: IImage) {
  const { type, src, title } = props;

  return (
    type === 'full' ? <ImageFull src={src} title={title} /> : <ImageRegular src={src} title={title} />
  );
}

export function WrapImage({
  children, cover, minHeight, className,
}: IWrapImage) {
  return (
    <StyledWrapImage cover={cover} minHeight={minHeight} className={className}>
      {children}
    </StyledWrapImage>
  );
}
