import { ReactNode } from 'react';
import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

interface IContainer {
  children: ReactNode | null;
}

const StyledContainer = Styled.div`
  margin: 0 auto;
  max-width: ${Theme.sizeContainer};
  width: 94%;

  @media screen and (min-width: 1080px) {
    max-width: ${Theme.sizeContainerXL};
  }
`;

export default function Container(props: IContainer) {
  const { children } = props;
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
}

const StyledFullContainer = Styled.div`
  margin: 0 auto;
  max-width: ${Theme.sizeContainer};
  width: 100%;

  @media screen and (min-width: 1080px) {
    max-width: ${Theme.sizeContainerXL};
  }
`;

const StyledContainerRight = Styled.div`
  margin-bottom: 3rem;
  width: 100%;
`;

export const StyledContainerIntoContainer = Styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export function FullContainer(props: IContainer) {
  const { children } = props;
  return (
    <StyledFullContainer>
      { children}
    </StyledFullContainer>
  );
}

export function ContainerRight(props: IContainer) {
  const { children } = props;
  return (
    <StyledContainerRight>
      { children}
    </StyledContainerRight>
  );
}
