import { useState } from 'react';
import { useGlobalState } from 'react-query-global-state';
import { useNavigate } from 'react-router-dom';
import { TabYear, TabYearButton, YearContainer } from '../../atoms/Tabs/StyledTabs';
import CardsHomeBanner from '../../organisms/Home/CardsHomeBanner';
import HomeNews from '../../organisms/Home/HomeNews';
import HomeVideos from '../../organisms/Home/HomeVideos';
import HomeWinnersPlans from '../../organisms/Home/HomeWinnersPlans';
import useNotification from '../../../hooks/useNotification';
import BannerCustom from '../../organisms/Home/BannerCustom';
import BannerArticle from '../../atoms/Banner/CardsHomeBannerArticle';
import useBanners from '../../../hooks/useBanners';
import hand from '../../../assets/images/hand.gif';

export default function SummaryHome() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<number>(
    localStorage.getItem('year')
      ? Number(localStorage.getItem('year'))
      : new Date().getFullYear(),
  );
  const [globalYear, setGlobalYear] = useGlobalState('year', selectedTab);

  const { data: notificationsPromote } = useNotification(true);
  const { data: notifications } = useNotification(false);
  const { data } = useBanners();

  const years: Array<{name: number}> = [{
    name: new Date().getFullYear() - 1,
  }, {
    name: new Date().getFullYear(),
  }];

  const changeYear = (year: number) => {
    setSelectedTab(year);
    setGlobalYear(year);
    navigate('/');
  };

  localStorage.setItem('year', globalYear.toString());

  return (
    <>
      <HomeWinnersPlans data={notificationsPromote} year={globalYear} />
      <YearContainer>
        <BannerArticle
          title="¿Qué información vas a consultar?"
          paragraph=""
          image={data ? (data.length > 4 ? hand : undefined) : undefined}
          cardsCount={data ? data.length : 0}
        />
        <TabYear>
          {years.map((year : { name: number }) => (
            <TabYearButton
              key={year.name}
              type="button"
              className={`${(globalYear === year.name) ? 'active' : ''}`}
              onClick={() => changeYear(year.name)}
            >
              {year.name}
            </TabYearButton>
          ))}
        </TabYear>
      </YearContainer>
      <CardsHomeBanner />
      <BannerCustom year={globalYear} />
      <HomeVideos />
      <HomeNews data={notifications} year={globalYear} />
    </>
  );
}
