/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-comment-textnodes */
import Theme from '../../../styles/Theme';
import IconArrow from '../../atoms/Icon/IconArrow';
import { LinkTable, Table } from '../../atoms/Table/Table';
import useRenderSubTable, { IRenderSubTable } from '../../../hooks/useRenderSubTable';
import Container from '../../atoms/Container/Container';
import CardDataExportXLSX from '../../molecules/Cards/CardDataExportXLSX';
import useMappingColumnNames from '../../../hooks/useMappingColumnNames';
import useControlledColumns from '../../../hooks/useControlledColumns';
import useDate from '../../../hooks/useDate';
import useProcessDataCSV from '../../../hooks/useProcessDataCSV';
import LinkTo from '../../atoms/Link/LinkTo';

export function renderRowSubComponent(results: any) {
  if (results?.row !== undefined) {
    const resultsValues = results?.row?.original?.results?.nodes;
    const extraFieldsValues = results?.row?.original?.results?.nodes
      ?.map((obj: any) => obj?.extraFields);
    const planLevelsValues = results?.row?.original?.planLevels;

    const props: IRenderSubTable = {
      resultsValues,
      extraFieldsValues,
      planLevelsValues,
      planType: 'campaign',
      translations: results?.row?.original?.translations,
    };
    const { processedKeys, processedValues } = useRenderSubTable(props);

    const processHeadersFirst = {};
    const processHeadersObjects:any = {};
    const processHeadersList:any = [];
    processedKeys.forEach((e: any) => {
      processHeadersList[e.accessor] = e.Header;
    });
    Object.assign(processHeadersObjects, processHeadersList);
    const newProcessHeader = { ...processHeadersFirst, ...processHeadersObjects };
    const processHedersCSV: any[] = [];
    const processHedersCSVKeys: any[] = ['IMPORTE'];
    Object.entries(newProcessHeader).forEach(([key, value]: any) => {
      if (!processHedersCSVKeys.includes(key)) {
        processHedersCSVKeys.push(key);
        processHedersCSV.push({ label: useMappingColumnNames(value, 'campaign'), key: useControlledColumns(key) });
      }
    });
    processedValues.forEach((item: any, index: number) => {
      Object.entries(item).forEach(([key, value]: any) => {
        if (key === 'FECHA ACT') {
          processedValues[index][key] = useDate(value);
        }
      });
    });

    const newProcessValues = useProcessDataCSV(processedValues);

    return (
      <Container>
        <CardDataExportXLSX
          headers={processHedersCSV}
          data={newProcessValues}
          name="campaign_global_position_sub_table"
        />
        <Table
          columns={processedKeys?.filter((e:any) => e?.accessor !== 'denomination')}
          data={processedValues}
          isScrollingSubtable={results?.row?.allCells?.length < processedKeys?.length}
        />
      </Container>
    );
  }
  return null;
}

export function renderRowSubComponentCommercial(results: any) {
  if (results?.row !== undefined) {
    const data = results?.row?.original?.subLevelData;
    const dgt = results?.row?.values?.territory;

    const colsSubLevel:any = [];
    data[dgt].map((e:any) => (
      Object.keys(e).map((c:any) => (!colsSubLevel.includes(c)
      && colsSubLevel.push(c)))
    ));

    const uniqueCols:any = [];
    colsSubLevel.map((c:any) => {
      if (c === 'groupedItem') {
        uniqueCols.push({ Header: 'Agrupación', accessor: c });
      } else {
        uniqueCols.push({ Header: c, accessor: c });
      }
      return uniqueCols;
    });

    // eslint-disable-next-line no-nested-ternary
    uniqueCols.sort((x:any, y:any) => (x.Header === 'Agrupación' ? -1 : y.Header === 'Agrupación' ? 1 : 0));

    return (
      <Table
        columns={uniqueCols}
        data={data[dgt]}
        isScrollingSubtable={results?.row?.allCells?.length < uniqueCols?.length}
        isSubTable
      />
    );
  }

  return null;
}

export const headersCampaignByCode = [
  { label: 'DGT', key: 'dgt' },
  { label: 'Total Puntos', key: 'totalPoints' },
  { label: 'Obj. Cumplidos', key: 'objs' },
  { label: 'Posición Ranking', key: 'ranking' },
  { label: 'Gana', key: 'win' },
  { label: 'Gana Tréboles', key: 'treboleWin' },
  { label: 'Datos a día', key: 'dataDate' },
  { label: 'Prod.DGT', key: '0' },
];

export const columns = [
  { Header: 'Avísame', accessor: 'subscribed' },
  { Header: 'Campañas', accessor: 'plan' },
  { Header: 'Gana', accessor: 'winsNumber' },
  { Header: 'Importe', accessor: 'amount' },
  { Header: 'Fecha inicio', accessor: 'startDate' },
  { Header: 'Fecha fin', accessor: 'endDate' },
  { Header: 'Estado', accessor: 'planStatusName' },
  { Header: 'Datos a día', accessor: 'dataDate' },
  {
    Header: 'Detalle',
    accessor: 'detail',
    Cell: ({ row }:any) => (
      row?.original?.planLevels?.map(
        (e:any) => e.level,
      ).sort((a:any, b:any) => b - a)[0] < 2
      ? (
        <label {...row.getToggleRowExpandedProps()}>
          <LinkTable title="Detalle" onClick={() => renderRowSubComponent(row)}>
            {row.isExpanded
              ? <IconArrow color={`${Theme.colorPrimary}`} rotate />
              : <IconArrow color={`${Theme.colorPrimary}`} /> }
          </LinkTable>
        </label>
        ) : <LinkTo to={row?.original?.detail?.props?.to} color={Theme.colorPrimary} style={{ textAlign: 'center', display: 'block' }}>Ver detalle</LinkTo>
    ),
  },
  {
    Header: 'Comparativa',
    accessor: 'comparative',
  },
  { Header: 'Bases', accessor: 'download' },
];

export const columnsCommercial = [
  { Header: 'Posición', accessor: 'position' },
  { Header: 'DGT', accessor: 'dgt' },
  { Header: 'DT', accessor: 'dt' },
  { Header: 'OD', accessor: 'od' },
  { Header: 'Puntos', accessor: 'points' },
];
