import { useLocation } from 'react-router-dom';
import RankingCalendar from './RankingCalendar';
import RankingGlobalPosition from './RankingGlobalPosition';
import RankingTeam from './RankingTeam';
import RankingHome from './RankingHome';

export default function Ranking() {
  const location = useLocation();

  switch (location.pathname) {
  case '/home/ranking':
    return <RankingHome />;
  case '/global-position/ranking':
    return <RankingGlobalPosition />;
  case '/team/ranking':
    return <RankingTeam />;
  case '/calendar/ranking':
    return <RankingCalendar />;
  default:
    return null;
  }
}
