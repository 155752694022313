import { StyledIconButton, StyledImageButton, StyledImageButtonWrapper } from './StyledImageButton';
import Typography from '../Typography/Typography';

export default function ImageButton({
  icon,
  image,
  name,
  ...otherProps
}: any) {
  return (
    <StyledImageButtonWrapper>
      {icon && (
        <StyledIconButton
          {...otherProps}
        >
          <span>{icon}</span>
        </StyledIconButton>
      )}
      {image && (
        <StyledImageButton
          {...otherProps}
        >
          <img src={image} alt={name} style={{ height: '70px', borderRadius: '8px' }} />
        </StyledImageButton>
      )}
      <Typography
        color="#CEB277"
        size="18px"
        margin="0.3rem 0 1rem"
        weight="600"
        type="h3"
      >
        {name}
      </Typography>
    </StyledImageButtonWrapper>
  );
}
