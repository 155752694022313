import { create } from 'zustand';
import { Generic } from '../interfaces/Generic.d';

type ExportState = {
  id: string,
  readyToExport: boolean,
  bodyExport?: Generic[],
  setReadyToExport: (readyToExport: boolean) => void,
}

const useExportStore = create<ExportState>((set) => ({
  id: '',
  readyToExport: false,
  bodyExport: undefined,
  setReadyToExport: (readyToExport: boolean) => set({ readyToExport }),
}));

export default useExportStore;
