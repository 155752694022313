import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

const AzureLoginContainer = Styled.div`
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    background-image: url(${Theme.loginImage});
  }
`;

export default AzureLoginContainer;
