import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { getGraphQLClient } from '../graphql/graphqlClient';
import GET_CURRENT_USER_INFO_GQL from '../graphql/queries/getCurrentUserInfoGql';
import GET_USER_CATEGORY_GQL from '../graphql/queries/getUserCategoryGql';
import GET_GROUPING_LIST_GQL from '../graphql/queries/getGroupingListGql';

export default function useCategoryInfo(year: number) {
  const graphQLClient = getGraphQLClient();

  const { data: userInfo } = useQuery(
    ['UserInfo'],
    async () => (graphQLClient && graphQLClient.request(GET_CURRENT_USER_INFO_GQL)),
    {
      staleTime: Infinity,
    },
  );

  const {
    data: userCategory,
    refetch: refetchUserCategory,
  } = useQuery(
    ['UserCategory'],
    async () => (graphQLClient
      && graphQLClient.request(GET_USER_CATEGORY_GQL, {
        userId: userInfo?.me?.id,
        year,
      })),
    {
      staleTime: Infinity,
      refetchOnMount: false,
      enabled: false,
    },
  );

  const {
    data: groupingList,
  } = useQuery(
    ['GroupingList'],
    async () => (graphQLClient && graphQLClient.request(GET_GROUPING_LIST_GQL)),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    refetchUserCategory();
  }, [year]);

  return {
    userInfo,
    userCategory,
    groupingList,
  };
}
