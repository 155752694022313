import { Translation } from '../interfaces/Translation.d';

export default function formatColumn(extraField: string, translations: Translation) {
  if (translations) {
    if (translations[extraField]) {
      if (translations[extraField]?.includes('(decimales)')) {
        return translations[extraField]?.replace(/\(decimales\)/gi, '');
      }
      if (translations[extraField]?.includes('porcentaje')) {
        return translations[extraField]?.replace(/\(porcentaje\)/gi, '');
      }
      return translations[extraField];
    } if (extraField === 'denomination') {
      return 'Denominación';
    }
  }
  return extraField;
}
