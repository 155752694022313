import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { memo } from 'react';
import Layout from './components/templates/Layout';
import Error from './components/pages/Error';
import Campaign from './components/pages/Campaign/Campaign';
import Rappels from './components/pages/Rappels/Rappels';
import Convention from './components/pages/Convention/Convention';
import CampaignCalendar from './components/pages/Campaign/CampaignCalendar';
import RappelsCalendar from './components/pages/Rappels/RappelsCalendar';
import RankingCalendar from './components/pages/Ranking/RankingCalendar';
import RappelsTeam from './components/pages/Rappels/RappelsTeam';
import ConventionCalendar from './components/pages/Convention/ConventionCalendar';
import Ranking from './components/pages/Ranking/Ranking';
import CampaignTeam from './components/pages/Campaign/CampaignTeam';
import ProtectedRoute from './components/atoms/ProtectedRoute/ProtectedRoute';
import Summary from './components/pages/Summary/Summary';
import AzureLogin from './components/pages/AzureLogin/AzureLogin';
import CampaignResults from './components/pages/Campaign/CampaignResults';
import RappelResults from './components/pages/Rappels/RappelResults';
import RappelDetailResults from './components/pages/Rappels/RappelDetailResults';
import CampaignDetailResults from './components/pages/Campaign/CampaignDetailResults';
import AdminImports from './components/pages/Admin/AdminImports';
import AdminUsers from './components/pages/Admin/AdminUsers';
import RankingTeam from './components/pages/Ranking/RankingTeam';
import ConventionTeam from './components/pages/Convention/ConventionTeam';
import CampaignGPComparative from './components/pages/Campaign/CampaignGPComparative';
import RappelGPComparative from './components/pages/Rappels/RappelGPComparative';
import ConventionDetailResults from './components/pages/Convention/ConventionDetailResults';
import ConventionDetail from './components/pages/Convention/ConventionDetail';
import useCategoryInfo from './hooks/useCategoryInfo';
import CampaignDetail from './components/pages/Campaign/CampaignDetail';
import RappelDetail from './components/pages/Rappels/RappelDetail';
import SummaryTeamGraphics from './components/pages/Summary/SummaryTeamGraphics';
import useYear from './hooks/useYear';
import SummaryTeam from './components/pages/Summary/SummaryTeam';
import ResultsTeam from './components/pages/Results/ResultsTeam';
import MirrorSignin from './components/pages/Login/MirrorSignin';

function App() {
  const location = useLocation();
  const { year } = useYear();
  const { userInfo, userCategory } = useCategoryInfo(year);

  return (
    <Routes>
      <Route path="/" element={<Layout user={userInfo} category={userCategory} />}>
        <Route
          path="/login"
          element={(
            <ProtectedRoute {...{ currentPath: location.pathname }}>
              <Navigate to="/home/summary" />
            </ProtectedRoute>
          )}
        />
        <Route
          index
          element={(
            <ProtectedRoute>
              <Navigate to="/home/summary" />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/home"
          element={(
            <ProtectedRoute>
              <Navigate to="/home/summary" />
            </ProtectedRoute>
          )}
        />
        <Route
          path="/home/summary"
          element={(
            <ProtectedRoute>
              <Summary />
            </ProtectedRoute>
          )}
        />
        <>
          <Route
            path="/global-position"
            element={(
              <ProtectedRoute>
                <Navigate to="/global-position/summary" />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/global-position/summary"
            element={(
              <ProtectedRoute>
                <Summary />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/global-position/campaign"
            element={(
              <ProtectedRoute>
                <Campaign />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/global-position/campaign"
          >
            <Route
              path=":id"
            >
              <Route
                index
                element={(
                  <ProtectedRoute>
                    <CampaignGPComparative />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="detail/:denomination"
                element={(
                  <ProtectedRoute>
                    <CampaignDetail />
                  </ProtectedRoute>
                )}
              />
            </Route>
          </Route>
          <Route
            path="/global-position/rappel"
            element={(
              <ProtectedRoute>
                <Rappels />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/global-position/rappel"
          >
            <Route
              path=":id"
            >
              <Route
                index
                element={(
                  <ProtectedRoute>
                    <RappelGPComparative />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="detail/:denomination"
                element={(
                  <ProtectedRoute>
                    <RappelDetail />
                  </ProtectedRoute>
                )}
              />
            </Route>
          </Route>
          <Route
            path="/global-position"
          >
            <Route
              path="convention"
              element={(
                <ProtectedRoute>
                  <Convention />
                </ProtectedRoute>
              )}
            />
            <Route
              path="convention/:id/detail/:denomination"
              element={(
                <ProtectedRoute>
                  <ConventionDetail />
                </ProtectedRoute>
              )}
            />
          </Route>
          <Route
            path="/global-position/ranking"
            element={(
              <ProtectedRoute>
                <Ranking />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/calendar"
            element={(
              <ProtectedRoute>
                <Navigate to="/calendar/summary" />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/calendar/summary"
            element={(
              <ProtectedRoute>
                <Summary />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/calendar/campaign"
            element={(
              <ProtectedRoute>
                <CampaignCalendar />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/calendar/rappel"
            element={(
              <ProtectedRoute>
                <RappelsCalendar />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/calendar/convention"
            element={(
              <ProtectedRoute>
                <ConventionCalendar />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/calendar/ranking"
            element={(
              <ProtectedRoute>
                <RankingCalendar />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/team"
          >
            <Route
              index
              element={(
                <ProtectedRoute>
                  <Navigate to="/team/summary" />
                </ProtectedRoute>
              )}
            />
            <Route
              path="summary"
              element={(
                <ProtectedRoute>
                  <SummaryTeamGraphics />
                </ProtectedRoute>
              )}
            />
            <Route
              path="summary/general"
              element={(
                <ProtectedRoute>
                  <SummaryTeam />
                </ProtectedRoute>
              )}
            />
            <Route
              path="ranking"
              element={(
                <ProtectedRoute>
                  <RankingTeam />
                </ProtectedRoute>
              )}
            />
            <Route
              path="results"
              element={(
                <ProtectedRoute>
                  <ResultsTeam />
                </ProtectedRoute>
              )}
            />
            <Route
              path="campaign"
            >
              <Route
                index
                element={(
                  <ProtectedRoute>
                    <Navigate to="resumen" />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="resumen"
                element={(
                  <ProtectedRoute>
                    <CampaignTeam id="resumen" user={userInfo} />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="results/:grouping"
                element={(
                  <ProtectedRoute>
                    <CampaignResults />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="resumen"
              >
                <Route
                  path=":id"
                >
                  <Route
                    index
                    element={(
                      <ProtectedRoute>
                        <CampaignDetailResults />
                      </ProtectedRoute>
                    )}
                  />
                  <Route
                    path="detail/:denomination"
                    element={(
                      <ProtectedRoute>
                        <CampaignDetail />
                      </ProtectedRoute>
                    )}
                  />
                </Route>
              </Route>
              <Route
                path=":grouping"
                element={(
                  <ProtectedRoute>
                    <CampaignTeam id="" user={userInfo} />
                  </ProtectedRoute>
                )}
              />
            </Route>
            <Route
              path="rappel"
            >
              <Route
                index
                element={(
                  <ProtectedRoute>
                    <Navigate to="resumen" />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="resumen"
                element={(
                  <ProtectedRoute>
                    <RappelsTeam id="resumen" user={userInfo} />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="results/:grouping"
                element={(
                  <ProtectedRoute>
                    <RappelResults />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="resumen"
              >
                <Route
                  path=":id"
                >
                  <Route
                    index
                    element={(
                      <ProtectedRoute>
                        <RappelDetailResults />
                      </ProtectedRoute>
                    )}
                  />
                  <Route
                    path="detail/:denomination"
                    element={(
                      <ProtectedRoute>
                        <RappelDetail />
                      </ProtectedRoute>
                    )}
                  />
                </Route>
              </Route>
              <Route
                path=":grouping"
                element={(
                  <ProtectedRoute>
                    <RappelsTeam id="" user={userInfo} />
                  </ProtectedRoute>
                )}
              />
            </Route>
            <Route
              path="convention"
            >
              <Route
                index
                element={(
                  <ProtectedRoute>
                    <Navigate to="resumen" />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="resumen"
                element={(
                  <ProtectedRoute>
                    <ConventionTeam id="resumen" user={userInfo} />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="results/:grouping"
                element={(
                  <ProtectedRoute>
                    <ConventionTeam id="" user={userInfo} />
                  </ProtectedRoute>
                )}
              />
              <Route
                path="resumen"
              >
                <Route
                  path=":id"
                >
                  <Route
                    index
                    element={(
                      <ProtectedRoute>
                        <ConventionDetailResults />
                      </ProtectedRoute>
                    )}
                  />
                  <Route
                    path="detail/:denomination"
                    element={(
                      <ProtectedRoute>
                        <ConventionDetail />
                      </ProtectedRoute>
                    )}
                  />
                </Route>
              </Route>
              <Route
                path=":grouping"
                element={(
                  <ProtectedRoute>
                    <ConventionTeam id="" user={userInfo} />
                  </ProtectedRoute>
                )}
              />
            </Route>
          </Route>
        </>
        <Route path="/login/azure" element={<AzureLogin />} />
        <Route path="/mirror-signin" element={<MirrorSignin />} />

        <Route
          path="/admin"
          element={(
            <ProtectedRoute>
              <Navigate to={userCategory?.userCategory?.roles?.includes('Central') ? '/admin/users' : '/admin/imports'} />
            </ProtectedRoute>
          )}
        />
        {!userCategory?.userCategory?.roles?.includes('Central') && (
          <Route
            path="/admin/imports"
            element={(
              <ProtectedRoute>
                <AdminImports />
              </ProtectedRoute>
            )}
          />
        )}
        <Route
          path="/admin/users"
          element={(
            <ProtectedRoute>
              <AdminUsers />
            </ProtectedRoute>
          )}
        />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}

export default memo(App);
