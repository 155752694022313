import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
  session: {
    isLoggedIn: boolean;
    token: string | null;
  };
}

type Actions = {
  setSession: (isLoggedIn: boolean, token: string | null) => void;
  clearSession: () => void;
}

const sessionStore = create(persist<State & Actions>((set) => ({
  session: {
    isLoggedIn: false,
    token: null,
  },
  setSession: (isLoggedIn, token) => set(() => ({
    session: {
      isLoggedIn,
      token,
    },
  })),
  clearSession: () => set({
    session: {
      isLoggedIn: false,
      token: null,
    },
  }),
}), {
  name: 'userSession',
}));

export const getTokenFromStore = () => {
  const { session: { token } } = sessionStore.getState();
  return token;
};

export default sessionStore;
