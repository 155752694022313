import { IIcon } from './Iicon';

export default function IconObjectives({ color, height, width }: IIcon) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height={height} width={width} viewBox="0 0 32 33">
      <path d="M28.5454 5.73125L27.0601 4.29875V0L22.4403 4.61688L22.1099 8.27875L17.3249 12.8956H16.5003C15.8475 12.8955 15.2094 13.0822 14.6665 13.432C14.1237 13.7819 13.7006 14.2792 13.4507 14.8611C13.2008 15.443 13.1354 16.0833 13.2627 16.7011C13.3901 17.3189 13.7044 17.8863 14.166 18.3317C14.6276 18.7771 15.2157 19.0804 15.856 19.2032C16.4962 19.3261 17.1599 19.263 17.763 19.0219C18.366 18.7808 18.8815 18.3725 19.2441 17.8488C19.6067 17.325 19.8001 16.7092 19.8 16.0794C19.8 15.7612 19.8 15.6019 19.6355 15.2837L24.4205 10.6669L28.215 10.3481L33 5.73125H28.5454Z" fill={color} />
      <path d="M25.4102 12.0994H25.245L22.9351 14.4875C23.1003 14.965 23.1003 15.6019 23.1003 16.0794C23.1003 19.5794 20.1304 22.4475 16.5003 22.4475C12.8703 22.4475 9.89967 19.5819 9.89967 16.0794C9.89967 12.5769 12.8703 9.71125 16.5003 9.71125C17.1597 9.71125 17.6553 9.87062 18.1502 9.87062L20.6252 7.4825V7.32312C19.3051 6.84562 17.985 6.5275 16.5003 6.5275C11.0553 6.5275 6.6 10.8256 6.6 16.0794C6.6 21.3331 11.0553 25.6319 16.5003 25.6319C21.9454 25.6319 26.4 21.3331 26.4 16.0794C26.4 14.6469 26.0703 13.375 25.4102 12.0994Z" fill={color} />
      <path d="M31.5153 9.5525L29.0403 11.7812C29.37 13.055 29.7003 14.4875 29.7003 16.0794C29.7003 23.0844 23.7604 28.8156 16.5003 28.8156C9.24026 28.8156 3.30356 23.0844 3.30356 16.0794C3.30356 9.07437 9.24026 3.34313 16.5003 3.34313C17.985 3.34313 19.4703 3.66188 20.955 4.13938V3.98L23.43 1.59187C21.2834 0.636875 18.9735 0.159375 16.5003 0.159375C7.42524 0.159375 0 7.32312 0 16.0794C0 24.8356 7.42524 32 16.5003 32C25.5754 32 33 24.8356 33 16.0794C33 13.6919 32.5051 11.4625 31.5153 9.5525Z" fill={color} />
    </svg>
  );
}
