import { FormSkeleton, FormSkeletonButton, FormSkeletonItem } from './StyledForm';

type Counter = {
  countFilters: number,
  buttons?: boolean,
}
export default function FilterFormSkeleton({ countFilters, buttons }: Counter) {
  return (
    <>
      <FormSkeleton className="skeleton">
        {[...Array(countFilters)].map(() => <FormSkeletonItem key={Math.random()} />)}
      </FormSkeleton>
      {buttons && (
        <FormSkeletonButton />
      )}
    </>
  );
}

FilterFormSkeleton.defaultProps = {
  buttons: true,
};
