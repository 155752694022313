import Styled from 'styled-components';

const Spinner = Styled.div<{background:string, color:string}>`
  border: 4px solid ${(props) => props.background};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border-left-color: ${(props) => props.color};
  animation: spin 1s ease infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
