import Styled from 'styled-components';
import Theme from '../../../styles/Theme';

export const HeaderMain = Styled.header`
  background-color: ${Theme.colorWhite};
`;

export const HeaderContainer = Styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  height: 73px;
  width: 100%;
`;

export const MenuLogged = Styled.div`
  position: relative;
  width: 100%;
`;

export const HeaderTop = Styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 52px;

  svg {
    height: 24px;
    width: 190px;
  }
`;

export const HeaderNotLogged = Styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  svg {
    height: 24px;
    width: 130px;
    margin-top: 0.25rem;
  }
`;
