export default function useRemoveAccents(text: string) {
  const sustitutions = {
    àáâãäå: 'a',
    ÀÁÂÃÄÅ: 'A',
    èéêë: 'e',
    ÈÉÊË: 'E',
    ìíîï: 'i',
    ÌÍÎÏ: 'I',
    òóôõö: 'o',
    ÒÓÔÕÖ: 'O',
    ùúûü: 'u',
    ÙÚÛÜ: 'U',
    ýÿ: 'y',
    ÝŸ: 'Y',
    ß: 'ss',
    ñ: 'n',
    Ñ: 'N',
  };

  function getLetterReplacement(letter: string, replacements: any) {
    const findKey = Object.keys(replacements)
      .reduce(
        (origin: any, item: any) => (item.includes(letter) ? item : origin),
        false,
      );
    return findKey !== false ? replacements[findKey] : letter;
  }

  return text
    .split('')
    .map((letter) => getLetterReplacement(letter, sustitutions))
    .join('');
}
