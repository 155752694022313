import ILink from './ILink';
import { StyledLink } from './StyledLink';

const LinkTo = function LinkTo({
  to,
  background,
  children,
  target,
  color,
  size,
  padding,
  border,
  underline,
  weight,
  className,
  ...otherProps
}: ILink) {
  return (
    <StyledLink
      to={to}
      target={target}
      background={background}
      padding={padding}
      color={color}
      size={size}
      border={border}
      underline={underline}
      weight={weight}
      className={className}
      {...otherProps}
    >
      {children}
    </StyledLink>
  );
};

export default LinkTo;
