import { gql } from 'graphql-request';

const GET_USER_CATEGORY_GQL = gql`
  query MyQuery($userId: ID, $year: Int) {
    userCategory(userId: $userId, year: $year) {
      id
      scope
      globalPosition
      myTeam
      myTeamDownloads
      name
      dgt
      roles
    }
  }
`;

export default GET_USER_CATEGORY_GQL;
