import { gql } from 'graphql-request';

const GET_PLAN_COUNT_GQL = gql`
query MyQuery($accumulation: String, $accumulationProduction: String, $category: String, $code: String, $description: String, $dgt: String, $dt: String, $endDate: String, $firstName: String, $grouping: String, $subgrouping: String, $lastName: String, $myTeam: Boolean = false, $name: String, $nif: String, $od: String, $planStatus: String, $scope: String, $search: String, $startDate: String, $timeframe: String, $type: String, $year: Int) {
  planCounter(accumulation: $accumulation, accumulationProduction: $accumulationProduction, category: $category, year: $year, type: $type, timeframe: $timeframe, startDate: $startDate, search: $search, scope: $scope, planStatus: $planStatus, od: $od, nif: $nif, name: $name, myTeam: $myTeam, grouping: $grouping, subgrouping: $subgrouping, lastName: $lastName, firstName: $firstName, endDate: $endDate, dt: $dt, dgt: $dgt, code: $code, description: $description)
  {
    counter
  }
}
`;

export default GET_PLAN_COUNT_GQL;
